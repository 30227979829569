import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTravelInsuranceContext } from '../../../context/insurances/TravelInsuranceContextProvider';
import { TravelInsurance } from '../../../models/TravelInsurance';
import { DatesStep } from '../Steps/DatesStep';
import { DestinyStep } from '../Steps/DestinyStep';
import { FinishStep } from '../Steps/FinishStep';
import { InsuranceQuotationStep } from '../Steps/InsuranceQuotationStep';
import { PassengersStep } from '../Steps/PassengersStepAdditionalInfo';
import { PassengersStepInitialData } from '../Steps/PassengersStepInitialData';
import { PaymentStep } from '../Steps/PaymentStep';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    marginRight: 1,
  },
  instructions: {
    marginTop: 1,
    marginBottom: 1,
  },
}));

interface ITravelInsuranceStepperProps {
  currentTravelInsurance?: TravelInsurance;
}

export const TravelInsuranceStepper = ({ currentTravelInsurance }: ITravelInsuranceStepperProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const { updateTravelInsuranceData, setTravelInsuranceData } = useTravelInsuranceContext();

  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleNext = (data?: any) => {
    updateTravelInsuranceData(data);

    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: 'Locais',
      component: <DestinyStep onNext={handleNext} onPrevious={handleBack} currentStep={activeStep == 0} />,
    },
    {
      label: 'Datas',
      component: <DatesStep onNext={handleNext} onPrevious={handleBack} currentStep={activeStep == 1} />,
    },
    {
      label: 'Passageiros',
      component: (
        <PassengersStepInitialData onNext={handleNext} onPrevious={handleBack} currentStep={activeStep == 2} />
      ),
    },
    {
      label: 'Cotação',
      component: <InsuranceQuotationStep onNext={handleNext} onPrevious={handleBack} currentStep={activeStep == 3} />,
    },
    {
      label: 'Passageiros',
      component: <PassengersStep onNext={handleNext} onPrevious={handleBack} currentStep={activeStep == 4} />,
    },
    {
      label: 'Pagamento',
      component: <PaymentStep onNext={handleNext} onPrevious={handleBack} currentStep={activeStep == 5} />,
    },
    {
      label: 'Finalizado',
      component: <FinishStep onNext={handleNext} onPrevious={handleBack} />,
    },
  ];

  //se existe um seguro no contexto, não fazer o fetch do seguro novamente, utilizar os dados existentes.
  //se não existir, fazer o fetch do seguro novamente.

  useEffect(() => {
    if (currentTravelInsurance) {
      setTravelInsuranceData(currentTravelInsurance);
      setLoaded(true);
    }
  }, [currentTravelInsurance]);

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      {activeStep === steps.length ? (
        <Typography className={classes.instructions}>Todos os passos foram finalizados</Typography>
      ) : (
        <>{loaded && steps[activeStep].component}</>
      )}
    </Box>
  );
};
3;
