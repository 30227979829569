import { LoadingButton } from '@mui/lab';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import { ROUTES } from '../../../../routes/routesList';
import {
  ActionsWrapper,
  Container,
  CustomCard,
  CustomCardContent,
  Footer,
  FormContainer,
  HeaderWrapper,
} from '../styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: TravelInsurance) => void;
}

export const FinishStep = ({ onPrevious, onNext }: Props) => {
  const navigate = useNavigate();

  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleNavigateDashboard = () => {
    navigate('/' + ROUTES.menuItems.app);
  };

  return (
    <>
      <CustomCard ismobile={ismobile}>
        <CustomCardContent>
          <Container ismobile={ismobile}>
            <HeaderWrapper>
              <Typography variant='h5' textAlign='center'>
                Finalizado
              </Typography>
              <Typography variant='body2' textAlign='center'>
                Seguro Viagem contratado com sucesso!
              </Typography>
            </HeaderWrapper>

            <FormContainer ismobile={ismobile} sx={{ my: '2rem' }}>
              <Typography variant='h4' textAlign='center'>
                🎉 Parabéns! Você acaba de contratar a melhor opção de seguro viagem do mercado. Viage tranquilo com a
                gente!
              </Typography>
            </FormContainer>
          </Container>
        </CustomCardContent>
      </CustomCard>
      <Footer>
        <ActionsWrapper ismobile={ismobile}>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={handleNavigateDashboard}
            size='large'
            sx={{ textTransform: 'none' }}
          >
            Ir para o Dashboard
          </LoadingButton>
        </ActionsWrapper>
      </Footer>
    </>
  );
};
