import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { Box, Container, Grid } from '@mui/material';
// components
// sections
import { useAppContext } from '../../context/GlobalContextProvider';
import { HomeInsuranceList } from '../../sections/@dashboard/app/HomeInsuranceList';
import { NoneInsuranceFound } from '../../sections/@dashboard/app/NoneInsuranceFound';
import { TravelInsuranceList } from '../../sections/@dashboard/app/TravelInsurance/TravelInsuranceList';
import { CashbackService } from '../../services/Cashback.service';
import { HomeInsuranceService } from '../../services/HomeInsurance.service';
import { TravelInsuranceService } from '../../services/TravelInsurance.service';
import { checkStatus } from '../../utils/api/response';

// ----------------------------------------------------------------------
// Importações...

export const DashboardAppPage: React.FC = () => {
  const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();

  const [travelInsuranceList, setTravelInsuranceList] = useState([]);
  const [homeInsuranceList, setHomeInsuranceList] = useState([]);
  const [cashbackValues, setCashbackValues] = useState<any>();

  const fetchTravelInsurances = async () => {
    try {
      const response = await TravelInsuranceService.getTravelInsurances();
      if (checkStatus(response)) {
        setTravelInsuranceList(response?.data.result);
      }
    } catch (error: any) {
      console.log('Erro ao buscar seguros de viagem', error);
    }
  };

  const fetchHomeInsurances = async () => {
    try {
      const response = await HomeInsuranceService.getHomeInsurances();
      if (checkStatus(response)) {
        setHomeInsuranceList(response?.data.result);
      }
    } catch (error: any) {
      console.log('Erro ao buscar seguros residenciais', error);
    }
  };

  const fetchCashbackValues = async () => {
    try {
      const response = await CashbackService.getCashbackValues();
      if (checkStatus(response)) {
        setCashbackValues(response?.data.result);
      }
    } catch (error: any) {
      console.log('Erro ao buscar valores de cashback', error);
    }
  };

  const fetchData = async () => {
    showGlobalSpinner({
      message: 'Aguarde, buscando suas informações...',
    });

    await fetchTravelInsurances();
    await fetchHomeInsurances();
    await fetchCashbackValues();

    hideGlobalSpinner();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | 91 Seguros</title>
      </Helmet>

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={8} order={{ xs: 2, md: 2 }}>
            {travelInsuranceList.length > 0 && <TravelInsuranceList list={travelInsuranceList} />}
            {homeInsuranceList.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <HomeInsuranceList
                  title='Seguros Residenciais'
                  subheader='Aqui estão listados os seguros residenciais que você contratou contratou ou iniciou a contratação'
                  list={homeInsuranceList}
                />
              </Box>
            )}
            {homeInsuranceList.length === 0 && travelInsuranceList.length === 0 && <NoneInsuranceFound />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
