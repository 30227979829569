import { Box, Typography } from '@mui/material';

export const NoReferrals = () => {
  return (
    <Box sx={{ maxWidth: '100%', padding: '5%', textAlign: 'center' }}>
      <Typography variant='h6'>Ninguém se cadastrou na 91 Seguros com seu link ainda.</Typography>
      <Typography variant='body1'>Indique seus amigos e economize até 91% nos seus seguros.</Typography>
    </Box>
  );
};
