import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useAutocompleteContext } from '../../../context/AutocompleteContextProvider';
import { useHomeInsuranceContext } from '../../../context/insurances/HomeInsuranceContextProvider';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { InsuranceTypeCard } from '../InsuranceProfileCard/InsuranceProfileCard';
import {
  ActionsWrapper,
  Container,
  CustomCard,
  CustomCardContent,
  FormContainer,
  HeaderWrapper,
  Subtitle,
  Title,
} from './styles';

export interface CoverageType {
  value: string;
  key: string;
  icon: React.ReactNode;
  selected?: boolean;
  image?: string;
}

interface Props {
  onPrevious: () => void;
  onNext: (data: HomeInsurance) => void;
}

export const StepTwoCoverageType = ({ onPrevious, onNext }: Props) => {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { autocomplete } = useAutocompleteContext();
  const { homeInsuranceData } = useHomeInsuranceContext();

  const [insuranceCoverageType, setInsuranceCoverageType] = useState<CoverageType[]>([]);

  const onSubmit = () => {
    const data: HomeInsurance = {
      progress_step: 5,
      coverage_type: insuranceCoverageType.find((coverageType: CoverageType) => coverageType.selected)?.key,
    };
    onNext(data);
  };

  const handleSelectedCard = (key: string) => {
    insuranceCoverageType?.map((coverageType: CoverageType) => {
      if (coverageType.key === key) {
        coverageType.selected = true;
      } else {
        coverageType.selected = false;
      }
    });
    setInsuranceCoverageType([...insuranceCoverageType]);
  };

  useEffect(() => {
    if (autocomplete) {
      let options = [...autocomplete['coverage_type']];

      if (homeInsuranceData?.coverage_type) {
        options?.map((option: CoverageType) => {
          if (option.key === homeInsuranceData?.coverage_type) {
            option.selected = true;
          } else {
            option.selected = false;
          }
        });
      }

      setInsuranceCoverageType(options);
    }
  }, [autocomplete, homeInsuranceData]);

  return (
    <CustomCard ismobile={ismobile}>
      <CustomCardContent>
        <Container ismobile={ismobile}>
          <HeaderWrapper>
            <Title>Tipo da Cobertura do Seguro</Title>
            <Subtitle>Selecione a opção que mais se encaixa com o seu perfil.</Subtitle>
          </HeaderWrapper>
          <FormContainer ismobile={ismobile}>
            <Grid container spacing={2}>
              {insuranceCoverageType &&
                insuranceCoverageType?.map((coverageType: CoverageType, index: number) => (
                  <Grid item xs={12} sm={12} md={6} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <InsuranceTypeCard coverageType={coverageType} onSelect={(key) => handleSelectedCard(key)} />
                  </Grid>
                ))}
            </Grid>
          </FormContainer>
          <ActionsWrapper ismobile={ismobile}>
            <Button
              variant='outlined'
              color='primary'
              onClick={onPrevious}
              size='large'
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={onSubmit}
              size='large'
              endIcon={<ArrowForwardIosIcon />}
            >
              Avançar
            </Button>
          </ActionsWrapper>
        </Container>
      </CustomCardContent>
    </CustomCard>
  );
};
