import { Box, Typography } from '@mui/material';

interface Props {
  activeStep: number;
  label: string;
  length: number;
}

export const StepperMobile = ({ activeStep, length, label }: Props) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '1rem' }}>
      <Typography variant="subtitle1" color="primary">
        Passo {activeStep} de {length} - {label}
      </Typography>
    </Box>
  );
};
