import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, Grid, Select, Theme, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTravelInsuranceContext } from '../../../context/insurances/TravelInsuranceContextProvider';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { formatCurrency } from '../../../utils/methods';
import { ActionsWrapper, Container, CustomCard, CustomCardContent, FormContainer, HeaderWrapper } from './styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: HomeInsurance) => void;
}

export const StepSixInstallments = ({ onPrevious, onNext }: Props) => {
  const navigate = useNavigate();
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { travelInsuranceQuotation: insuranceQuotation } = useTravelInsuranceContext();
  const [selectedInstallment, setSelectedInstallment] = useState();

  const onSubmit = async () => {
    if (selectedInstallment) {
      onNext({ installments: selectedInstallment });
    }
  };

  return (
    <CustomCard ismobile={ismobile}>
      <CustomCardContent>
        <Container ismobile={ismobile}>
          <HeaderWrapper>
            <Typography variant='h5' textAlign='center'>
              Pagamento
            </Typography>
            <Typography variant='body2' textAlign='center'>
              Selecione as condições de pagamento
            </Typography>
          </HeaderWrapper>

          <FormContainer ismobile={ismobile} sx={{ my: '2rem' }}>
            <Card sx={{ padding: 2 }}>
              <CardHeader title='Parcelamento' titleTypographyProps={{ textAlign: 'center' }} />
              <CustomCardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Select
                      fullWidth
                      native
                      title='Selecione o número de parcelas'
                      inputProps={{
                        name: 'installments',
                        id: 'installments',
                        'aria-label': 'Selecione o número de parcelas', // Adicione uma descrição acessível aqui
                        'aria-required': true,
                      }}
                      value={selectedInstallment}
                      onChange={(event) => setSelectedInstallment(event.target.value)}
                    >
                      {insuranceQuotation?.credit_card?.map((installment) => (
                        <option key={installment.installments} value={installment.installments}>
                          {installment.installments}x de R$ {formatCurrency(installment.installment_price)}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </CustomCardContent>
            </Card>
          </FormContainer>

          <ActionsWrapper ismobile={ismobile}>
            <LoadingButton
              variant='outlined'
              color='primary'
              onClick={onPrevious}
              size='large'
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={onSubmit}
              size='large'
              endIcon={<ArrowForwardIosIcon />}
            >
              Avançar
            </LoadingButton>
          </ActionsWrapper>
        </Container>
      </CustomCardContent>
    </CustomCard>
  );
};
