import { Button, CardContent, CardHeader, Dialog, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useAppContext } from '../../context/GlobalContextProvider';
import { ApiResultAddress, NewAddress } from '../../models/Address';
import { AddressService } from '../../services/ZipcodeService.service';
import { CustomCard } from '../../theme/GlobalStyledComponents';
import { checkStatus } from '../../utils/api/response';
import { validateZipcode } from '../../utils/methods';
import { TextInput } from '../basics/TextInput/TextInput';

interface Props {
  addressEdition: ApiResultAddress | undefined;
  title: string;
  subtitle: string;
  onSave: () => void;
  onCancel: () => void;
}

export const AddressModal = ({ addressEdition, title, subtitle, onCancel, onSave }: Props) => {
  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage } = useAppContext();

  const [validZipcode, setValidZipcode] = useState<boolean>(false);

  const {
    control,
    reset,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<NewAddress>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      zipcode: addressEdition?.zipcode,
      state: {
        id: addressEdition?.city?.state?.id,
        name: addressEdition?.city?.state?.name,
      },
      city: {
        id: addressEdition?.city?.id,
        name: addressEdition?.city?.name,
      },
      neighborhood: addressEdition?.neighborhood,
      street: addressEdition?.street,
    },
  });

  const zipcodeWatch = useWatch({
    control,
    name: 'zipcode',
  });

  const handleCreateAddress = async (data: NewAddress) => {
    let dataToSubmit: NewAddress = {
      id: addressEdition?.id,
      street: data.street,
      zipcode: data.zipcode,
      neighborhood: data.neighborhood,
      city: data.city?.id,
      state: data.state?.id,
    };

    try {
      let response = null;

      if (addressEdition?.id) {
        response = await AddressService.updateAddress(dataToSubmit);
      } else {
        response = await AddressService.createAddress(dataToSubmit);
      }

      if (response && checkStatus(response)) {
        onSave();
      }
    } catch (error: any) {
      showSnackbarMessage({
        title: 'Erro',
        message: 'Houve um erro ao criar o endereço, por favor verifique os dados informados.',
        severity: 'error',
      });
    }
  };

  const fetchAddressByCep = async (zipcode: string) => {
    showGlobalSpinner({
      message: 'Aguarde um momento...',
    });

    try {
      const response = await AddressService.getAddress(zipcode);

      if (checkStatus(response)) {
        const result = response?.data.result as ApiResultAddress;
        reset({
          zipcode: result.zipcode,
          street: result.street,
          city: {
            id: result.city.id,
            name: result.city.name,
          },
          state: {
            id: result.city.state.id,
            name: result.city.state.name,
          },
          neighborhood: result.neighborhood,
        });
        setValidZipcode(true);
      }
    } catch (error: any) {
      console.log('Erro ao buscar endereço', error);
      setError('zipcode', { message: 'CEP não encontrado' });
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    setValidZipcode(false);
    if (zipcodeWatch?.length === 9) {
      if (validateZipcode(zipcodeWatch)) {
        fetchAddressByCep(zipcodeWatch);
      }
    }
  }, [zipcodeWatch]);

  return (
    <form>
      <Dialog
        open={true}
        onClose={onCancel}
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomCard>
          <CardContent>
            <CardHeader
              title={title}
              subheader={subtitle}
              subheaderTypographyProps={{
                variant: 'body2',
              }}
            />
            <Divider />
            <Grid container spacing={4} sx={{ px: 2, mt: 1 }}>
              <Grid item xs={12} md={6}>
                <TextInput
                  name='zipcode'
                  label='CEP'
                  mask='99999-999'
                  control={control}
                  variant='standard'
                  errorMessage={errors?.zipcode?.message}
                  type='tel'
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  label='Estado'
                  name='state.name'
                  variant='standard'
                  errorMessage={errors?.state?.message}
                  disabled={!validZipcode}
                  type='text'
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  label='Cidade'
                  name='city.name'
                  variant='standard'
                  errorMessage={errors?.city?.message}
                  disabled={!validZipcode}
                  type='text'
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  label='Bairro'
                  name='neighborhood'
                  variant='standard'
                  errorMessage={errors?.neighborhood?.message}
                  disabled={!validZipcode}
                  type='text'
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label='Rua'
                  control={control}
                  name='street'
                  variant='standard'
                  errorMessage={errors?.street?.message}
                  disabled={!validZipcode}
                  type='text'
                  required
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }} gap={2}>
                <Button variant='outlined' onClick={onCancel}>
                  Cancelar
                </Button>
                <Button variant='contained' type='submit' onClick={handleSubmit(handleCreateAddress)}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </CustomCard>
      </Dialog>
    </form>
  );
};
