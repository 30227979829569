import { Box, ButtonBase, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Insurance } from '../../../models/Insurance';

interface Props {
  insurance: Insurance;
  disabled?: boolean;
}

export const InsuranceCard = ({ insurance, disabled }: Props) => {
  const theme = useTheme();
  const navigation = useNavigate();

  const handleNewInsurance = () => {
    navigation('/' + insurance.route);
  };
  return (
    <Grid item xs={6} sm={6} md={4}>
      <ButtonBase
        onClick={handleNewInsurance}
        disabled={disabled || insurance.disabled}
        style={{
          width: '100%',
          borderRadius: '0.7rem',
        }}
      >
        <Card
          sx={{
            flex: 1,
            color: disabled || insurance.disabled ? theme.palette.action.disabled : theme.palette.primary.main,
            display: 'flex',
            height: '125px',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box mb={1}>{insurance.icon}</Box>
            <Typography>
              {insurance.name}
              {insurance.disabled && (
                <Typography variant='span'>
                  <br /> (em breve)
                </Typography>
              )}
            </Typography>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};
