import { Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../../components/basics/PageWrapper/PageWrapper';
import { TextInput } from '../../../components/basics/TextInput/TextInput';
import { useAppContext } from '../../../context/GlobalContextProvider';
import { ROUTES } from '../../../routes/routesList';
import { HelpService } from '../../../services/Help.service';
import { DocumentContainer } from './styles';

export default function HelpPage() {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage } = useAppContext();

  const handleClose = () => {
    navigate('/' + ROUTES.menuItems.options);
  };

  const { control, handleSubmit } = useForm({
    defaultValues: {
      message: '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      showGlobalSpinner({
        message: 'Enviando mensagem...',
      });

      const response = await HelpService.sendHelpMessage(data.message);
      if (response?.status === 400) {
        setErrors(response?.data?.errors);
      }
      if (response?.status === 201) {
        setSuccess(true);
      }
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao enviar mensagem, entre em contato através dos nossos telefones ou e-mail.',
        severity: 'error',
      });
    } finally {
      hideGlobalSpinner();
    }
  };

  return (
    <PageWrapper pageTitle='Ajuda' onClose={handleClose}>
      <Helmet>
        <title> Dashboard | Ajuda </title>
      </Helmet>

      <DocumentContainer ismobile={ismobile}>
        {!success && (
          <form>
            <Typography variant='body1' textAlign='center' sx={{ mt: 2 }}>
              Preencha o campo abaixo para que possamos ajudar você.
            </Typography>
            <Typography variant='body1' textAlign='center' sx={{ mt: 2 }}>
              Nossa equipe vai analisar sua mensagem e responder o mais rápido possível.
            </Typography>
            <Typography variant='body1' textAlign='center' sx={{ mt: 2 }}>
              Verifique seu e-mail para sua resposta.
            </Typography>
            <TextInput
              control={control}
              name='message'
              label='Mensagem'
              placeholder='Digite sua mensagem'
              errorMessage={errors?.message}
              multiline
              minRows={4}
              sx={{ mt: '2rem' }}
              type='text'
              required
            />
            <Button
              variant='contained'
              sx={{ mt: '2rem' }}
              fullWidth
              size='large'
              type='submit'
              onClick={handleSubmit(onSubmit)}
            >
              Enviar
            </Button>
          </form>
        )}
        {success && (
          <>
            <Typography variant='body1' textAlign='center' sx={{ mt: 2 }}>
              Sua mensagem foi enviada com sucesso.
            </Typography>
            <Typography variant='body1' textAlign='center' sx={{ mt: 2 }}>
              Enviaremos a resposta por e-mail.
            </Typography>
          </>
        )}
      </DocumentContainer>
    </PageWrapper>
  );
}
