import { LoadingButton } from '@mui/lab';
import { Theme, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { AddressComponent } from '../../../components/AddressComponent/AddressComponent';
import { useHomeInsuranceContext } from '../../../context/insurances/HomeInsuranceContextProvider';
import { ApiResultAddress, NewAddress } from '../../../models/Address';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { ActionsWrapper, Container, CustomCard, CustomCardContent, HeaderWrapper, Subtitle, Title } from './styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: HomeInsurance) => void;
}

export const StepOneLocation = ({ onPrevious, onNext }: Props) => {
  const addressComponentRef = useRef<any>(null);
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { homeInsuranceData } = useHomeInsuranceContext();

  const handleSubmitAddressCreated = async (data: NewAddress | null) => {
    if (data == null) {
      return;
    }

    onNext({
      id: homeInsuranceData?.id,
      address: data.id,
    });
  };

  const onSubmit = async (data: any) => {
    await addressComponentRef.current?.onSubmit();
  };

  return (
    <CustomCard ismobile={ismobile}>
      <CustomCardContent>
        <Container ismobile={ismobile}>
          <HeaderWrapper>
            <Title>Localização do Imóvel</Title>
            <Subtitle>Informe o endereço para o qual você deseja fazer a cotação do seguro.</Subtitle>
          </HeaderWrapper>
          <AddressComponent
            ref={addressComponentRef}
            onSubmit={handleSubmitAddressCreated}
            addressEdition={homeInsuranceData.address as ApiResultAddress}
          />
          <ActionsWrapper ismobile={ismobile}>
            <LoadingButton variant='contained' color='primary' type='submit' onClick={onSubmit} size='large'>
              Próximo
            </LoadingButton>
          </ActionsWrapper>
        </Container>
      </CustomCardContent>
    </CustomCard>
  );
};
