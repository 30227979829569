import { Box, Card, CardHeader, Grid, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { ROUTES } from '../../../routes/routesList';
import { ItemCard } from './styles';

interface Props {
  title: string;
  subheader?: string;
  list: HomeInsurance[];
}

export const HomeInsuranceList = ({ title, subheader, list, ...other }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <Card {...other} style={{ padding: isMobile ? '1rem' : '2rem' }}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h5',
        }}
        subheader={subheader}
      />
      <Grid container spacing={6} sx={{ p: 2, py: 4 }}>
        {list.map((item: HomeInsurance, index: number) => (
          <Grid item xs={12} md={6} key={index}>
            <HomeInsuranceItem item={item} key={index} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

interface HomeInsuranceItemProps {
  item: HomeInsurance;
}

function HomeInsuranceItem({ item }: HomeInsuranceItemProps) {
  const { status, address } = item;
  const navigate = useNavigate();

  const IMAGE = '/assets/images/insurance/apartment.png';

  const statusNames = {
    started: 'Em preenchimento',
    proposal_created: 'Proposta criada',
    finished: 'Contratado',
  };

  const handleRedirect = () => {
    if (item?.id) {
      navigate(ROUTES.forms.newHomeInsuranceId.replace(':id', item.id));
    }
  };

  return (
    <ItemCard onClick={handleRedirect}>
      <Stack direction='row' alignItems='center' spacing={4}>
        <Box component='img' alt={'Seguro Apartamento'} src={IMAGE} sx={{ width: '30%', height: 'auto' }} />

        <Stack sx={{ minWidth: 240, flexGrow: 1, justifyContent: 'space-evenly' }} spacing={1.5}>
          <Link
            color='inherit'
            variant='subtitle1'
            underline='hover'
            noWrap
            onClick={handleRedirect}
            sx={{ cursor: 'pointer' }}
          >
            <strong>Seguro Apartamento</strong>
          </Link>
          <Typography component='p' variant='body1'>
            {address?.neighborhood} {address?.city?.name && ' - ' + address?.city?.name}
          </Typography>
          {status && (
            <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
              <Label color={(status === 'started' && 'warning') || 'success'}>{statusNames[status]}</Label>
            </Typography>
          )}
        </Stack>
      </Stack>
    </ItemCard>
  );
}
