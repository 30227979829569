import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { Grid, Select, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { TextInput } from '../../../../components/basics/TextInput/TextInput';
import { useAppContext } from '../../../../context/GlobalContextProvider';
import { useGoogleAnalyticsContext } from '../../../../context/GoogleAnalyticsContextProvider';
import { useUserContext } from '../../../../context/UserContextProvider';
import { useTravelInsuranceContext } from '../../../../context/insurances/TravelInsuranceContextProvider';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import { Installment } from '../../../../models/TravelInsuranceQuotation';
import { TravelInsuranceService } from '../../../../services/TravelInsurance.service';
import { checkStatus } from '../../../../utils/api/response';
import { formatCurrency } from '../../../../utils/methods';
import { ActionsWrapper, Container, CustomCard, Footer, HeaderWrapper } from '../styles';
import { TravelInsurancePaymentData } from './components/CreditCardForm';

interface Props {
  onPrevious: () => void;
  onNext: (data: TravelInsurance) => void;
  currentStep: boolean;
}

const schema = yup.object().shape({
  card: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  expirationDate: yup.string().required('Campo obrigatório'),
  cvv: yup.string().required('Campo obrigatório'),
  cpf: yup.string().required('Campo obrigatório'),
});

export const PaymentStep = ({ onPrevious, onNext, currentStep }: Props) => {
  const { travelInsuranceData } = useTravelInsuranceContext();
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();
  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage, loading } = useAppContext();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { chosenQuotation } = useTravelInsuranceContext();
  const [selectedInstallment, setSelectedInstallment] = useState<string>();

  const { user } = useUserContext();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      card: '',
      expirationDate: '',
      cvv: '',
      name: user?.first_name + ' ' + user?.last_name,
      cpf: user?.client.cpf,
    },
  });

  //these watches are used to re-render the card component
  useWatch({
    control,
    name: 'name',
    defaultValue: user?.first_name + ' ' + user?.last_name,
  });

  useWatch({
    control,
    name: 'card',
  });

  useWatch({
    control,
    name: 'expirationDate',
  });

  useWatch({
    control,
    name: 'cvv',
  });

  const onSubmit = async (data: TravelInsurancePaymentData) => {
    if (!selectedInstallment) return;

    showGlobalSpinner({
      message: 'Contratando seguro...',
    });

    newGoogleAnalyticsEvent('add_payment_info', {
      value: chosenQuotation?.total_price,
      currency: 'BRL',
      payment_type: 'credit_card',
      items: [
        {
          item_id: chosenQuotation?.product_code,
          item_name: chosenQuotation?.product_name,
          item_category: 'Seguro Viagem',
          quantity: 1,
          price: chosenQuotation?.total_price,
        },
      ],
    });

    try {
      const response = await TravelInsuranceService.hireInsurance(
        travelInsuranceData.id as string,
        selectedInstallment,
        data.name,
        data.cpf,
        data.card,
        data.expirationDate.split('/')[1],
        data.expirationDate.split('/')[0],
        data.cvv
      );
      if (checkStatus(response)) {
        newGoogleAnalyticsEvent('purchase', {
          value: chosenQuotation?.total_price,
          currency: 'BRL',
          transaction_id: 'Travel Insurance Id: ' + String(travelInsuranceData.id),
          items: [
            {
              item_id: chosenQuotation?.product_code,
              item_name: chosenQuotation?.product_name,
              item_category: 'Seguro Viagem',
              quantity: 1,
              price: chosenQuotation?.total_price,
            },
          ],
        });

        onNext({
          progress_step: 9,
        });
      }
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao contratar seguro viagem',
        severity: 'error',
      });
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    if (chosenQuotation?.credit_card) {
      setSelectedInstallment(chosenQuotation?.credit_card[0].installments);
    }
  }, [chosenQuotation]);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (currentStep) {
      newGoogleAnalyticsEvent('begin_checkout', {
        value: chosenQuotation?.total_price,
        currency: 'BRL',
        items: [
          {
            item_id: chosenQuotation?.product_code,
            item_name: chosenQuotation?.product_name,
            item_category: 'Seguro Viagem',
            quantity: 1,
            price: chosenQuotation?.total_price,
          },
        ],
      });
    }
  }, [currentStep]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard ismobile={isMobile}>
        <Container ismobile={isMobile}>
          {!isMobile && (
            <HeaderWrapper>
              <Typography variant='h5' textAlign='center'>
                Pagamento
              </Typography>
              <Typography variant='body2' textAlign='center'>
                Informe os dados do cartão de crédito
              </Typography>
            </HeaderWrapper>
          )}
          <Grid container spacing={4} sx={{ display: 'flex', flex: 1 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}
              order={{ sm: 0, md: 1 }}
            >
              <Cards
                number={getValues('card')}
                name={getValues('name')}
                expiry={getValues('expirationDate')}
                cvc={getValues('cvv')}
                focused='number'
                locale={{ valid: 'válido até' }}
                preview
              />
              <Stack sx={{ width: isMobile ? '100%' : '80%', display: 'flex', flex: 1, mt: 2 }}>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'} pb={1}>
                  Número de parcelas
                </Typography>
                <Select
                  title='Número de parcelas'
                  fullWidth
                  size='small'
                  native
                  inputProps={{
                    name: 'installments',
                    id: 'installments',
                    'aria-label': 'Número de parcelas',
                    'aria-required': true,
                  }}
                  value={selectedInstallment}
                  onChange={(event) => setSelectedInstallment(event.target.value)}
                >
                  {chosenQuotation?.credit_card?.map((installment: Installment) => (
                    <option key={installment.installments} value={installment.installments}>
                      {installment.installments}x de R$ {formatCurrency(installment.installment_price)}
                    </option>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} order={{ sm: 1, md: 0 }} sx={{ pb: isMobile ? 4 : 0 }}>
              {isMobile && <Typography variant={isMobile ? 'subtitle1' : 'h6'}>Dados do cartão de crédito</Typography>}
              <Stack spacing={isMobile ? 3 : 4} mt={isMobile ? 2 : 0}>
                <TextInput
                  control={control}
                  name='name'
                  label='Nome do titular'
                  placeholder='Nome do titular'
                  onFocus={() => setIsScrolled(true)}
                  errorMessage={errors.name?.message?.toString()}
                  required
                />
                <TextInput
                  control={control}
                  name='cpf'
                  label='CPF'
                  placeholder='000.000.000-00'
                  mask='999.999.999-99'
                  type='tel'
                  errorMessage={errors.cpf?.message?.toString()}
                  required
                />
                <TextInput
                  control={control}
                  name='card'
                  label='Número do cartão'
                  placeholder='0000-0000-0000-0000'
                  mask='9999 9999 9999 9999'
                  type='tel'
                  errorMessage={errors.card?.message?.toString()}
                  required
                />
                <TextInput
                  control={control}
                  name='expirationDate'
                  label='Data de expiração (Mês/Ano)'
                  placeholder='MM/AA'
                  mask='99/99'
                  type='tel'
                  errorMessage={errors.expirationDate?.message?.toString()}
                  required
                />
                <TextInput
                  control={control}
                  name='cvv'
                  label='CVV'
                  placeholder='CVC'
                  mask='999'
                  type='tel'
                  errorMessage={errors.cvv?.message?.toString()}
                  required
                />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </CustomCard>
      <Footer>
        <ActionsWrapper ismobile={isMobile}>
          <LoadingButton
            fullWidth={isMobile}
            variant='outlined'
            color='primary'
            onClick={onPrevious}
            disabled={loading}
            size='large'
            startIcon={<ArrowBackIosIcon />}
          >
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth={isMobile}
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
            size='large'
            startIcon={<CheckIcon />}
          >
            Finalizar
          </LoadingButton>
        </ActionsWrapper>
      </Footer>
    </form>
  );
};
