import { styled } from '@mui/material/styles';
import CryptoJS from 'crypto-js';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../context/GlobalContextProvider';
import { useUserContext } from '../../../context/UserContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/localstorageKeys';
import { User } from '../../../models/User';
import { ROUTES } from '../../../routes/routesList';
import { AuthService } from '../../../services/Auth.service';
import { checkStatus } from '../../../utils/api/response';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

const parseQueryString = (queryString: string) => {
  const params = {};
  if (queryString) {
    queryString
      .substring(1)
      .split('&')
      .forEach((param) => {
        const keyValue = param.split('=');
        const key = decodeURIComponent(keyValue[0]);
        const value = keyValue.length > 1 ? decodeURIComponent(keyValue[1]) : '';
        params[key] = value;
      });
  }
  return params;
};

export default function GoogleCallBack() {
  const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const websiteQuote = searchParams.get('quote');
  const authorizationCode = searchParams.get('code');
  const navigate = useNavigate();
  const invitationCode = parseQueryString(location.search)['convite'];

  const { setUser } = useUserContext();

  const setUserInLocalStorage = (user: User) => {
    try {
      const secretKey = process.env.REACT_APP_CRYPTO_KEY;
      const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(user), secretKey!).toString();
      localStorage.setItem(LOCAL_STORAGE_KEYS.user, encryptedUserData);
    } catch (error: any) {
      localStorage.clear();
      navigate('/' + ROUTES.public.login, { replace: true });
    }
  };

  const makeGoogleAuthRequest = async () => {
    if (authorizationCode) {
      try {
        showGlobalSpinner({ message: 'Autenticando com o Google' });
        const response = await AuthService.doGoogleAuth(authorizationCode, websiteQuote, invitationCode);
        if (checkStatus(response)) {
          setUserInLocalStorage(response?.data.user);
          setUser(response?.data.user);

          setTimeout(() => {
            navigate('/' + ROUTES.menuItems.app, { replace: true });
          }, 500);
        }
      } catch (error: any) {
        alert('Erro no login com o google.');
        navigate(ROUTES.public.login, { replace: true });
      } finally {
        hideGlobalSpinner();
      }
    }
  };

  useEffect(() => {
    if (!authorizationCode) {
      setSearchParams('');
      navigate(ROUTES.public.login, { replace: true });
    } else {
      makeGoogleAuthRequest();
    }
  }, [authorizationCode]);

  return (
    <>
      <Helmet>
        <title> Login | 91 Seguros </title>
      </Helmet>
      <></>
    </>
  );
}
