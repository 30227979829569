import { AxiosResponse } from 'axios';
import createAxiosInstance from '../middleware/axios.interceptors';
import { UserForm } from '../sections/@user/TabsComponent/TabProfile/TabProfile';

const userUrl = '/insurance/client';
const getUserInformation = '/auth/user-information';

export const UserService = {
  userUpdate: async (data: UserForm) => {
    let response: AxiosResponse | undefined;

    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(userUrl, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  updateProfilePicture: async (data: any) => {
    let response: AxiosResponse | undefined;

    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(userUrl, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  getUserInfo: async () => {
    let response: AxiosResponse | undefined;

    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(getUserInformation);
    } catch (err: any) {
      throw err.response;
    }
  },
};
