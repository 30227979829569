import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepperMobile } from '../../../components/StepperMobile/StepperMobile';
import { useHomeInsuranceContext } from '../../../context/insurances/HomeInsuranceContextProvider';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { StepFivePayment } from '../Steps/StepFivePayment';
import { StepFourInsuranceQuotation } from '../Steps/StepFourInsuranceQuotation';
import { StepOneLocation } from '../Steps/StepOneLocation';
import { StepSixInstallments } from '../Steps/StepSixInstallments';
import { StepThreeHouseUse } from '../Steps/StepThreeHouseUse';
import { StepTwoCoverageType } from '../Steps/StepTwoCoverageType';
import { StepperContainer } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    marginRight: 1,
  },
  instructions: {
    marginTop: 1,
    marginBottom: 1,
  },
}));

interface IHomeInsuranceStepperProps {
  currentHomeInsurance?: HomeInsurance;
}

export const HomeInsuranceStepper = ({ currentHomeInsurance }: IHomeInsuranceStepperProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const { updateHomeInsuranceData, setHomeInsuranceData } = useHomeInsuranceContext();

  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleNext = (data: HomeInsurance) => {
    updateHomeInsuranceData(data);

    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: 'Dados do Imóvel',
      component: <StepOneLocation onNext={handleNext} onPrevious={handleBack} />,
    },
    {
      label: 'Tipo da Cobertura do Seguro',
      component: <StepTwoCoverageType onNext={handleNext} onPrevious={handleBack} />,
    },
    {
      label: 'Utilização do imóvel',
      component: <StepThreeHouseUse onNext={handleNext} onPrevious={handleBack} />,
    },
    {
      label: 'Cotação do Seguro',
      component: <StepFourInsuranceQuotation onNext={handleNext} onPrevious={handleBack} />,
    },
    {
      label: 'Pagamento',
      component: <StepFivePayment onNext={handleNext} onPrevious={handleBack} />,
    },
    {
      label: 'Parcelamento',
      component: <StepSixInstallments onNext={handleNext} onPrevious={handleBack} />,
    },
  ];

  useEffect(() => {
    if (currentHomeInsurance) {
      setHomeInsuranceData(currentHomeInsurance);
      setLoaded(true);
    }
  }, [currentHomeInsurance]);

  return (
    <Box className={classes.root}>
      {activeStep === steps.length ? (
        <Typography className={classes.instructions}>Todos os passos foram finalizados</Typography>
      ) : (
        <>{loaded && steps[activeStep].component}</>
      )}
      <StepperContainer ismobile={ismobile}>
        <StepperMobile activeStep={activeStep + 1} length={steps.length} label={steps[activeStep].label} />
      </StepperContainer>
    </Box>
  );
};
