import { AddCircle, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Button, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { TextInput } from '../../../../../components/basics/TextInput/TextInput';
import { useLogServiceHook } from '../../../../../hooks/useLogServiceHook';
import { validatePassengerBirthday } from '../../../../../utils/methods';

interface PassengersComponentProps {
  control: any;
  getValues: any;
  passengerAges: string[];
  setPassengerAges: (ages: string[]) => void;
  setCurrentStep: (step: number) => void;
  handleInsuranceQuoting: () => void;
}

export const PassengersComponent = ({
  control,
  passengerAges,
  setPassengerAges,
  setCurrentStep,
  handleInsuranceQuoting,
}: PassengersComponentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logUser } = useLogServiceHook();

  const handleAddPassenger = () => {
    const newAges = [...passengerAges];
    newAges.push('');
    setPassengerAges(newAges);
  };

  const handleRemovePassenger = () => {
    const newAges = [...passengerAges];
    if (newAges.length > 1) {
      newAges.pop();
      setPassengerAges(newAges);
    }
  };

  const handleChangePasswordBirthday = (value: any, index: number) => {
    const newAges = [...passengerAges];
    newAges[index] = value;
    setPassengerAges(newAges);
  };

  const onSubmit = async () => {
    await logUser({
      url: 'cotacao-seguro-viagem/select_passengers',
      log_data: { passengerAges },
    });

    handleInsuranceQuoting();
  };

  useEffect(() => {}, [passengerAges]);

  return (
    <Grid container flex={1} sx={{ display: 'flex', justifyContent: 'center', p: '1.5rem' }}>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h3' color='text' sx={{ textAlign: 'center', width: '100%' }} gutterBottom>
          Idades dos Passageiros
        </Typography>
        <Typography variant={isMobile ? 'subtitle2' : 'h6'} color='text' gutterBottom textAlign='center' mt={2}>
          Informe a data de nascimento de todos os segurados
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} columnGap={1}>
        <Typography variant='subtitle1'>Quantidade de passageiros</Typography>
        <IconButton onClick={handleRemovePassenger} color='primary'>
          <RemoveCircleIcon fontSize='large' />
        </IconButton>
        <Typography
          variant='subtitle1'
          style={{ border: 'solid 1px #000', padding: '0.5rem 1rem', borderRadius: '8px' }}
        >
          {passengerAges.length || 0}
        </Typography>
        <IconButton onClick={handleAddPassenger} color='primary'>
          <AddCircle fontSize='large' />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' rowGap={2}>
          {passengerAges.map((age, index) => (
            <Fragment key={index}>
              <TextInput
                control={control}
                size='medium'
                name={`age-${index}`}
                label='Data de Nascimento'
                fullWidth
                variant='outlined'
                mask='99/99/9999'
                type='text'
                value={age}
                onChangeCapture={(event: any) => handleChangePasswordBirthday(event.target.value, index)}
                errorMessage={validatePassengerBirthday(age) ? '' : 'Data inválida'}
              />
            </Fragment>
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        mt={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: isMobile ? 'flex-end' : 'flex-start',
        }}
      >
        <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setCurrentStep(1)}
            size={isMobile ? 'medium' : 'large'}
            startIcon={<ArrowBackIos />}
            fullWidth={isMobile}
          >
            Voltar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onSubmit()}
            size={isMobile ? 'medium' : 'large'}
            endIcon={<ArrowForwardIos />}
            disabled={passengerAges.some((age) => !validatePassengerBirthday(age) || age.replace(/\D/g, '').length < 8)}
            fullWidth={isMobile}
          >
            Próximo
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
