import { Helmet } from 'react-helmet-async';
// @mui
import { Container, useTheme } from '@mui/material';
// components
// mock
import { Theme, useMediaQuery } from '@mui/material';
import { TabsComponent } from '../../sections/@user/TabsComponent/TabsComponent';
import { UserCoverComponent } from '../../sections/@user/UserCoverComponent/UserCoverComponent';
import { UserTabsProvider } from '../../sections/@user/context/UserTabsContext';
// ----------------------------------------------------------------------

export default function UserPage() {
  const theme = useTheme();
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <>
      <Helmet>
        <title> Usuário | 91 Seguros </title>
      </Helmet>
      <Container>
        <UserTabsProvider>
          <UserCoverComponent />
          <TabsComponent />
        </UserTabsProvider>
      </Container>
    </>
  );
}
