import { Box, Card, CircularProgress, Modal, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Logo from '../../../components/logo';

interface Props {
  text: string;
  style?: 'spinner' | 'blink-logo';
}

export const GlobalSpinner = ({ text, style = 'spinner' }: Props) => {
  const theme = useTheme();
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: ismobile ? 0 : 2,
          padding: '2rem',
          width: ismobile ? '100vw' : undefined,
          height: ismobile ? '100vh' : undefined,
          border: ismobile ? 'none' : 'solid',
          borderWidth: '2px',
          borderColor: theme.palette.primary.main,
        }}
      >
        <Box
          gap={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {style === 'blink-logo' ? (
            <Logo
              sx={{
                animation: 'flip 3s infinite ease-in-out',
                '@keyframes blink': {
                  '0%': {
                    opacity: 0,
                  },
                  '50%': {
                    opacity: 1,
                  },
                  '100%': {
                    opacity: 0,
                  },
                },
              }}
            />
          ) : (
            <CircularProgress />
          )}
          <Typography variant='h5' sx={{ color: theme.palette.primary.main }} textAlign='center'>
            {text}
          </Typography>
        </Box>
      </Card>
    </Modal>
  );
};
