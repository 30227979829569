import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { AddressComponent } from '../../../components/AddressComponent/AddressComponent';
import { TextInput } from '../../../components/basics/TextInput/TextInput';
import { useUserContext } from '../../../context/UserContextProvider';
import { NewAddress } from '../../../models/Address';
import { Passenger } from '../../../models/Passenger';
import { PassengerInfoSchema } from '../../../utils/forms/auth/formValidationSchemas';
import { formatDateToApi, getFormatedDate, hexToRGBA } from '../../../utils/methods';
import { CustomCardHeader } from './styles';

interface Props {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

interface ModalProps {
  passengerEdition?: Passenger;
  onClose: () => void;
  onConfirm: (passenger: Passenger) => void;
}

interface FormFields {
  name: string;
  birthdate: string;
  email: string;
  phone: string;
  cpf: string;
}

const InfoAccordion = ({ title, subtitle, children }: Props) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleExpand} sx={{ '&.Mui-expanded': { margin: 0 } }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{ backgroundColor: hexToRGBA(theme.palette.primary.light, 0.2) }}
      >
        <Typography variant='h5' sx={{ ml: 2 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export const PassengersModalAdditionalInfo = ({ passengerEdition, onClose, onConfirm }: ModalProps) => {
  const theme = useTheme();
  const addressComponentRef = useRef<any>(null);
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { user } = useUserContext();

  const LOGGED_IN_USER_SAME_AS_PASSENGER =
    `${passengerEdition?.first_name} ${passengerEdition?.last_name}` === `${user?.first_name} ${user?.last_name}`;

  const getDefaultValues = () => {
    // if the passenger first and last name are the same as the loggedin user, we use their data as initial values
    // we do that by joining them on a single string, so we can compare them (useful for people with more than one first or last name)
    if (LOGGED_IN_USER_SAME_AS_PASSENGER) {
      return {
        ...{
          name: passengerEdition?.first_name
            ? passengerEdition.first_name + ' ' + passengerEdition.last_name
            : user?.first_name + ' ' + user?.last_name,
          birthdate: getFormatedDate(passengerEdition?.birthdate),
          email: passengerEdition?.email || user?.email,
          phone: passengerEdition?.phone || user?.client.phone,
          cpf: passengerEdition?.cpf || user?.client.cpf,
        },
      };
    }
    if (passengerEdition?.birthdate) {
      return {
        ...passengerEdition,
        name:
          passengerEdition?.first_name && passengerEdition?.last_name
            ? passengerEdition?.first_name + ' ' + passengerEdition?.last_name
            : '',
        birthdate: getFormatedDate(passengerEdition?.birthdate),
      };
    } else {
      return {
        name: '',
        birthdate: new Date(),
      };
    }
  };

  const getUserAddress = () => {
    if (passengerEdition?.zipcode) {
      return {
        zipcode: passengerEdition?.zipcode,
        street: passengerEdition?.street,
        neighborhood: passengerEdition?.neighborhood,
        city: passengerEdition?.city,
        state: passengerEdition?.state,
      };
    }

    if (LOGGED_IN_USER_SAME_AS_PASSENGER) {
      if (user?.client?.addresses?.length! > 0) {
        const lastAddress = user?.client?.addresses[user?.client?.addresses?.length! - 1];
        return lastAddress;
      }
    }
    return undefined;
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PassengerInfoSchema),
  });

  const formValidations = (data: FormFields) => {
    if (data.name && data.name.split(' ').length < 2) {
      setError('name', { type: 'manual', message: 'Informe nome e sobrenome do passageiro' });
      return false;
    }
    return true;
  };

  const handleSubmitAddressCreated = async (addressData: NewAddress | null) => {
    if (!addressData) return;
    let passengerData = getValues();

    const PASSENGER_NAME = passengerData.name.split(' ');
    let firstName = PASSENGER_NAME?.[0] || '';
    let lastName = PASSENGER_NAME?.slice(1).join(' ') || '';

    //passo os dados do novo passageiro com o endereço criado na API
    onConfirm({
      id: passengerEdition?.id || '',
      birthdate: formatDateToApi(passengerData.birthdate),
      first_name: String(firstName),
      last_name: String(lastName),
      cpf: passengerData.cpf,
      email: passengerData.email,
      phone: passengerData.phone,

      zipcode: addressData.zipcode,
      street: addressData.street,
      neighborhood: addressData.neighborhood,
      state: addressData.state,
      city: addressData.city,
      existsInApi: passengerEdition?.existsInApi || false,
    });
  };

  const onSubmit = async (data: FormFields) => {
    if (!formValidations(data)) {
      return;
    }
    await addressComponentRef.current?.onSubmit();
  };

  useEffect(() => {
    reset(getDefaultValues());
  }, [passengerEdition]);

  return (
    <div>
      <Dialog
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={passengerEdition !== undefined}
        onClose={onClose}
        closeAfterTransition
        fullScreen={ismobile}
      >
        <Box>
          <CustomCardHeader sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack>
              <Typography variant='h4' sx={{ p: '1rem', pl: '2rem' }} color={theme.palette.common.white}>
                Dados do Passageiro
              </Typography>
            </Stack>
            <IconButton sx={{ mr: 2 }} onClick={onClose}>
              <CloseIcon fontSize='large' sx={{ color: theme.palette.common.white }} />
            </IconButton>
          </CustomCardHeader>
          <Stack sx={{ pb: 2 }}>
            <InfoAccordion
              title='Informações Pessoais'
              subtitle='Preencha os campos abaixo com as informações do passageiro.'
            >
              <Grid container spacing={4} p={2}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label='Nome do Passageiro'
                    control={control}
                    name='name'
                    variant='standard'
                    errorMessage={errors?.name?.message}
                    type='text'
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label='Data de Nascimento'
                    control={control}
                    variant='standard'
                    name='birthdate'
                    mask='99/99/9999'
                    type='tel'
                    errorMessage={errors?.birthdate?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name='cpf'
                    label='CPF'
                    mask='999.999.999-99'
                    type='tel'
                    variant='standard'
                    control={control}
                    errorMessage={errors?.cpf?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name='email'
                    type='email'
                    label='E-mail'
                    control={control}
                    variant='standard'
                    errorMessage={errors?.email?.message}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextInput
                    name='phone'
                    label='Celular'
                    variant='standard'
                    mask='(99) 99999-9999'
                    type='tel'
                    control={control}
                    errorMessage={errors?.phone?.message}
                    required
                  />
                </Grid>
              </Grid>
            </InfoAccordion>

            {/** Endereço do Passageiro */}
            <InfoAccordion title='Endereço' subtitle='Informe nos campos abaixo o endereço do passageiro'>
              {/*
               * Aqui eu removo o ID do passageiro, pois o endereço é criado na API, caso enviado o ID
               * o sistema tentaria editar o endereço, mas o ID é do passageiro.
               * */}
              <AddressComponent
                ref={addressComponentRef}
                onSubmit={handleSubmitAddressCreated}
                addressEdition={getUserAddress()}
              />
            </InfoAccordion>

            <Typography variant='caption' sx={{ mb: 1, mt: 4 }} textAlign='center'>
              Após informar dados pessoais e de endereço, clique em "Confirmar".
            </Typography>
            <Stack direction='row' spacing={2} marginX={2}>
              <Button variant='outlined' color='primary' onClick={onClose} size='large' fullWidth>
                Cancelar
              </Button>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
                size='large'
                fullWidth
              >
                Confirmar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};
