import { useEffect, useState } from 'react';
// @mui
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { AuthService } from '../../../services/Auth.service';

interface Notification {
  id: string;
  created_at: string;
  viewed_at: string;
  title: string;
  content: string;
  category: string;
}

const NOTIFICATIONS_INTERVAL = String(process.env.REACT_APP_NOTIFICATIONS_INTERVAL);

export default function NotificationsPopover() {
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([]);
  const [readNotifications, setReadNotifications] = useState<Notification[]>([]);
  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      await AuthService.markAllNotificationsAsRead();
    } catch (error: any) {
      console.error('Error in handleMarkAllAsRead', error);
    }
    fetchNotifications();
  };

  const filterNotifications = (notifications: any) => {
    const unreadNotifications = notifications?.filter((notification: any) => notification.viewed_at === null);
    const readNotifications = notifications?.filter((notification: any) => notification.viewed_at !== null);

    setUnreadNotifications(unreadNotifications);
    setReadNotifications(readNotifications);
  };

  const fetchNotifications = async () => {
    try {
      const notifications = await AuthService.fetchNotifications();
      filterNotifications(notifications?.data?.result);
    } catch (err) {
      console.error('Error in fetchNotifications', err);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const updateInterval = setInterval(fetchNotifications, NOTIFICATIONS_INTERVAL);
    return () => clearInterval(updateInterval);
  }, []);

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={unreadNotifications?.length} color='error'>
          <Iconify icon='eva:bell-fill' />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        {unreadNotifications?.length > 0 && (
          <Tooltip title=' Mark all as read' sx={{ width: '100%' }}>
            <Button color='primary' onClick={handleMarkAllAsRead} startIcon={<Iconify icon='eva:done-all-fill' />}>
              <Typography>Marcar tudo como lido</Typography>
            </Button>
          </Tooltip>
        )}
        <Box sx={{ display: 'block', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle1'>Notificações</Typography>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Você possui {unreadNotifications?.length} notificações não lidas
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {unreadNotifications?.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline', textAlign: 'center' }}>
                  Não lidas
                </ListSubheader>
              }
            >
              {unreadNotifications?.map((notification: Notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>
          )}

          {readNotifications?.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline', textAlign: 'center' }}>
                  Lidas
                </ListSubheader>
              }
            >
              {readNotifications?.map((notification: Notification) => (
                <NotificationItem key={notification?.id} notification={notification} />
              ))}
            </List>
          )}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {false && (
          <Box sx={{ p: 1 }}>
            <Button fullWidth disableRipple>
              Ver todas
            </Button>
          </Box>
        )}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------
interface Props {
  notification: Notification;
}

function NotificationItem({ notification }: Props) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.viewed_at === null && {
          bgcolor: 'grey.200',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant='caption'
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon='eva:clock-outline' sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: Notification) {
  const title = (
    <>
      <Typography variant='subtitle2'>{notification.title}</Typography>
      <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
        {notification.content}
      </Typography>
    </>
  );

  if (notification.category === 'general') {
    return {
      avatar: <img alt={notification.title} src='/assets/icons/ic_notification_package.svg' />,
      title,
    };
  }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
  //     title,
  //   };
  // }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.category} /> : null,
    title,
  };
}
