import { Apple, Google } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';

export const DownloadComponent = () => {
  return (
    <>
      <Typography variant='h6' color='primary' sx={{ textAlign: 'center', width: '100%' }}>
        Baixe nosso App para contratar
      </Typography>
      <Box display='flex' justifyContent='center' mt='30px'>
        <LoadingButton
          variant='contained'
          color='primary'
          onClick={() => (window.location.href = 'https://apps.apple.com/br/app/91-seguros/id6466729422')}
          size='large'
          sx={{ mr: '10px' }}
          startIcon={<Apple />}
        >
          Download Apple
        </LoadingButton>
        <LoadingButton
          variant='contained'
          color='primary'
          onClick={() => (window.location.href = 'https://play.google.com/store/apps/details?id=com.seguros.app91')}
          size='large'
          startIcon={<Google />}
        >
          Download Android
        </LoadingButton>
      </Box>
    </>
  );
};
