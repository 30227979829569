import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { Theme, styled } from '@mui/material/styles';
//
import { useMediaQuery } from '@mui/material';
import { ROUTES } from '../../routes/routesList';
import { BottomNavigator } from './bottomNavigator/BottomNavigator';
import Header from './header/Header';
import Nav from './nav/MenuDrawer';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 100;
const APP_BAR_DESKTOP = 100;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
}));

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  useEffect(() => {
    //if location is / then redirect to /app
    if (window.location.pathname === '/') {
      navigate('/' + ROUTES.menuItems.app, { replace: true });
    }
  }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>

      {ismobile ? <BottomNavigator /> : <></>}
    </StyledRoot>
  );
}
