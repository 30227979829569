import { Helmet } from 'react-helmet-async';
// @mui
import { Alert, Button, Card, CardContent, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks

// components
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../components/logo';
import { FormState } from '../../../models/FormState';
import { ROUTES } from '../../../routes/routesList';
import { AuthService } from '../../../services/Auth.service';
import { checkStatus } from '../../../utils/api/response';
// sections
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState<FormState>({
    severity: 'info',
    message: '',
  });

  const handleNavigateRegistration = () => {
    navigate(ROUTES.public.login);
  };

  const handleValidateToken = async () => {
    setLoading(true);

    try {
      const response = await AuthService.accountConfirmation(String(token));
      if (checkStatus(response)) {
        setFormState({
          severity: 'success',
          message: response?.data.message || 'Sua conta foi validada com sucesso!',
        });
      }
    } catch (error: any) {
      setFormState({
        severity: 'error',
        message: 'Algo inesperado ocorreu ao validar a sua conta, entre em contato com nosso suporte.',
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      handleValidateToken();
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title> Confirmação da conta | 91 Seguros </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth='sm'>
          <StyledContent>
            <Card>
              <CardContent>
                {loading ? (
                  <Stack spacing={3} alignItems='center'>
                    <Typography variant='h6'>Estamos validando a sua conta, por favor, aguarde.</Typography>
                    <CircularProgress />
                  </Stack>
                ) : (
                  <Stack spacing={3}>
                    <Typography variant='h6'>Validação da conta</Typography>
                    <Alert severity={formState.severity}>{formState.message}</Alert>
                    <Button
                      fullWidth
                      size='large'
                      type='submit'
                      variant='contained'
                      onClick={handleNavigateRegistration}
                    >
                      Fazer Login
                    </Button>
                  </Stack>
                )}
              </CardContent>
            </Card>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
