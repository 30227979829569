import { Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../localstorage/localstorageKeys';

// ----------------------------------------------------------------------
interface Props {
  onClick: () => void;
}

export default function AppleLoginButton({ onClick }: Props) {
  const navigate = useNavigate();
  const { setUser } = useUserContext();

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    setUser(null);
  }, []);

  const buttonStyle = {
    backgroundColor: 'black',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center', // Centralizar conteúdo verticalmente
  };

  const appleIconStyle = {
    width: '24px', // Tamanho do ícone da Apple
    marginRight: '10px', // Espaçamento entre o ícone e o texto
  };

  return (
    <>
      <Button onClick={onClick} style={buttonStyle}>
        <img src='/assets/icons/apple-64.png' alt='Apple Icon' style={appleIconStyle} />
        <Typography textTransform='none'>Continuar com Apple</Typography>
      </Button>
    </>
  );
}
