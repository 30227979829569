import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Card, CardHeader, Container, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Label } from '../../../components/basics/Label/Label';
import { useTravelInsuranceContext } from '../../../context/insurances/TravelInsuranceContextProvider';
import { TravelInsurance } from '../../../models/TravelInsurance';
import { getCurrentDate, getFormatedDate, isDateAfter, isDateEqualOrAfter, parseDate } from '../../../utils/methods';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    marginRight: 1,
  },
  instructions: {
    marginTop: 1,
    marginBottom: 1,
  },
}));

interface IActiveTravelInsuranceDetailsProps {
  currentTravelInsurance?: TravelInsurance;
}

export const ActiveTravelInsuranceDetails = ({ currentTravelInsurance }: IActiveTravelInsuranceDetailsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const { updateTravelInsuranceData, setTravelInsuranceData } = useTravelInsuranceContext();

  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const isInternational = (currentTravelInsurance?.destiny as string) !== 'within_my_country';
  console.log('isInternational', isInternational);

  console.log('currentTravelInsurance', currentTravelInsurance);

  useEffect(() => {
    if (currentTravelInsurance) {
      setTravelInsuranceData(currentTravelInsurance);
      setLoaded(true);
    }
  }, [currentTravelInsurance]);

  const downloadVoucher = (voucher_file_url: string) => {
    window.open(voucher_file_url, '_blank');
  };

  const getStatusName = () => {
    switch (currentTravelInsurance?.status) {
      case 'started':
        return 'Em preenchimento';
      case 'active':
        if (isDateAfter(parseDate(currentTravelInsurance?.end_date!), getCurrentDate())) return 'Finalizado';
        if (isDateEqualOrAfter(parseDate(currentTravelInsurance?.start_date!), getCurrentDate())) return 'Ativo';
        return 'Contratado';
      default:
        return '';
    }
  };

  return (
    <Box className={classes.root}>
      <Helmet>
        <title> Seguro Viagem </title>
      </Helmet>

      <Container maxWidth='xl'>
        {loaded && (
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12} order={{ xs: 1, md: 1 }}>
              <Card sx={{ p: 3, mt: 3 }}>
                <Box
                  component='img'
                  alt={'Seguro viagem'}
                  src={'/assets/images/insurance/travel-ticket.svg'}
                  sx={{ width: 96, height: 96, borderRadius: 1.5, flexShrink: 0, margin: 'auto' }}
                />
                <Typography component={'h1'} variant={'h4'} sx={{ textAlign: 'center' }}>
                  Seguro Viagem
                </Typography>
                <Typography component={'h6'} variant={'body2'} sx={{ textAlign: 'center' }}>
                  {currentTravelInsurance?.countries?.map((country, index) => (
                    <Box key={index}>
                      {index === 0 && `${country.name}`}
                      {index > 0 && index < currentTravelInsurance?.countries?.length - 1 && `, ${country.name}`}
                      {index > 0 && index === currentTravelInsurance?.countries?.length - 1 && ` e ${country.name}`}
                    </Box>
                  ))}
                </Typography>
                <Box sx={{ textAlign: 'center', m: 1 }}>
                  <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
                    <Label color={(currentTravelInsurance.status === 'started' && 'warning') || 'success'}>
                      {getStatusName()}
                    </Label>
                  </Typography>
                </Box>
                <Typography component={'h6'} variant={'p'} sx={{ textAlign: 'center' }}>
                  {getFormatedDate(currentTravelInsurance?.start_date)} à{' '}
                  {getFormatedDate(currentTravelInsurance?.end_date)}
                </Typography>
                <Typography component={'h6'} variant={'p'} sx={{ textAlign: 'center' }}>
                  Contratado em: {getFormatedDate(currentTravelInsurance?.hired)}
                </Typography>
              </Card>

              <Card sx={{ mt: 3 }}>
                <CardHeader title='Passageiros' />
                <Box sx={{ p: 3 }}>
                  {currentTravelInsurance?.passengers?.map((passenger, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      md={currentTravelInsurance?.passengers?.length > 1 ? 6 : 12}
                      md={
                        currentTravelInsurance?.passengers?.length > 2
                          ? 4
                          : currentTravelInsurance?.passengers?.length > 1
                          ? 6
                          : 12
                      }
                      order={{ xs: 1, md: 1 }}
                    >
                      <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center' }}>
                        {`${passenger.first_name} ${passenger.last_name}`}
                      </Typography>
                      <Typography component={'h6'} variant={'body2'} sx={{ textAlign: 'center' }}>
                        {`${passenger.cpf}`}
                      </Typography>
                      <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Button
                          variant='contained'
                          color='primary'
                          href={passenger.voucher_file_url as string}
                          sx={{ width: '100%', maxWidth: '180px' }}
                          startIcon={<DownloadIcon />}
                        >
                          Baixar voucher
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Box>
              </Card>
              <Card sx={{ mt: 3 }}>
                <Box sx={{ p: 3 }}>
                  <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center' }}>
                    Seguro emitido por:
                  </Typography>
                  <Box
                    component='img'
                    alt={'Seguro viagem'}
                    src={'/assets/insurances/assist-card-logo.png'}
                    sx={{ width: 180, flexShrink: 0, margin: 'auto' }}
                  />
                  <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center', mt: 2 }}>
                    Telefones AssistCard:
                  </Typography>
                  <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center', mt: 2 }}>
                    Dentro do Brasil
                  </Typography>
                  <Typography variant={'body2'} sx={{ textAlign: 'center' }}>
                    Se você está no Brasil, este é o número para ligar grátis
                  </Typography>
                  <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center', mt: '10px' }}>
                    0800 170 6133
                  </Typography>
                  <Box sx={{ textAlign: 'center', mt: '10px' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      href='tel:08001706133'
                      sx={{ width: '100%', maxWidth: '180px' }}
                      startIcon={<AddIcCallIcon />}
                    >
                      Ligar Agora
                    </Button>
                  </Box>
                  {isInternational && (
                    <Grid item xs={12} sm={6}>
                      <Card sx={{ p: 3 }}>
                        <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center', mt: 2 }}>
                          Fora do Brasil (em português)
                        </Typography>
                        <Typography variant={'body2'} sx={{ textAlign: 'center' }}>
                          Se você está fora do Brasil, este é o número internacional para ligar com atendimento em
                          português.
                        </Typography>
                        <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center', mt: '10px' }}>
                          +55 (11) 3191-8700
                        </Typography>
                        <Box sx={{ textAlign: 'center', mt: '10px' }}>
                          <Button
                            href='tel:+551131918700'
                            variant='contained'
                            color='primary'
                            sx={{ width: '100%', maxWidth: '180px' }}
                            startIcon={<AddIcCallIcon />}
                          >
                            Ligar Agora
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  )}
                  {isInternational && (
                    <Grid item xs={12}>
                      <Card sx={{ p: 3 }}>
                        <Typography component={'h6'} variant={'h6'} sx={{ textAlign: 'center', mt: 2 }}>
                          Outros Países e Idiomas
                        </Typography>
                        <Typography variant={'body2'} sx={{ textAlign: 'center' }}>
                          Se você está fora do Brasil e deseja saber o telefone do país onde você está, clique no botão
                          abaixo e acesse a página completa de telefones.
                        </Typography>
                        <Box sx={{ textAlign: 'center', mt: '10px' }}>
                          <Button
                            variant='contained'
                            href='https://www.assistcard.com/listadetelefones'
                            color='primary'
                            sx={{ width: '100%', maxWidth: '180px' }}
                            startIcon={<OpenInNewIcon />}
                          >
                            Acessar
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};
