import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, Theme, useMediaQuery } from '@mui/material';
import { ROUTES } from '../../routes/routesList';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const logo = (
    <Box
      component='img'
      src='/assets/91-app-icons/icon.png'
      sx={{ width: ismobile ? 40 : 50, height: ismobile ? 40 : 50, cursor: 'pointer', ...sx, borderRadius: 1 }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to={ROUTES.menuItems.app} component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
