import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CardContent, CardHeader, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { TextInput } from '../../../../components/basics/TextInput/TextInput';
import { useAppContext } from '../../../../context/GlobalContextProvider';
import { useUserContext } from '../../../../context/UserContextProvider';
import { AuthService } from '../../../../services/Auth.service';
import { UserService } from '../../../../services/User.service';
import { CustomCard } from '../../../../theme/GlobalStyledComponents';
import { checkStatus } from '../../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../../utils/forms/form-errors';
import { formatDateToApi, getFormatedDate, isValidDate, validateCpf } from '../../../../utils/methods';

export const UserSchema = Yup.object().shape({
  first_name: Yup.string().required('Nome é obrigatório'),
  last_name: Yup.string().required('Sobrenome é obrigatório'),
  cpf: Yup.string()
    .required('CPF é obrigatório')
    .test('valid-cpf', 'CPF inválido', (value) => validateCpf(value)),
  phone: Yup.string().required('Telefone é obrigatório'),
  birthdate: Yup.string()
    .required('Data de nascimento é obrigatória')
    .test('isValidDate', 'Data de nascimento inválida', (value) => isValidDate(value)),
});

export interface UserForm {
  first_name?: string;
  last_name?: string;
  email?: string;
  cpf?: string;
  phone?: string;
  birthdate?: string;
  push_notification_token?: string;
  profile_picture?: any;
}

interface PasswordForm {
  password: string;
  new_password: string;
  password_confirmation: string;
}

export const TabProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, refreshUserLocalStorage } = useUserContext();

  const { showSnackbarMessage } = useAppContext();

  const {
    control: controlUserForm,
    handleSubmit: handleSubmitUser,
    setError: setUserFormSetError,
    reset,
    formState: { errors: userFormErrors },
  } = useForm<UserForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      cpf: user?.client?.cpf,
      phone: user?.client?.phone,
      birthdate: user?.client.birthdate ? getFormatedDate(user.client.birthdate) : '',
    },
    resolver: yupResolver(UserSchema),
  });

  const {
    control: controlPasswordForm,
    handleSubmit: handleSubmitPassword,
    setError: passwordFormSetError,
    formState: { errors: passwordFormErrors },
  } = useForm<PasswordForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      new_password: '',
      password_confirmation: '',
    },
  });

  const onSubmitUserUpdate = async (data: UserForm) => {
    const submitData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      cpf: data.cpf,
      phone: data.phone,
      birthdate: formatDateToApi(data.birthdate as string),
    };

    try {
      const response = await UserService.userUpdate(submitData);
      if (checkStatus(response)) {
        refreshUserLocalStorage();
        showSnackbarMessage({
          message: 'Dados pessoais atualizados com sucesso!',
          severity: 'success',
        });
      }
    } catch (error: any) {
      let formError = setInputErrorsFromApi(setUserFormSetError, error?.data.errors);
      if (formError) {
        showSnackbarMessage({
          message: formError,
          severity: 'error',
        });
      }
    }
  };

  const onSubmitChangePassword = async (data: any) => {
    try {
      const response = await AuthService.changePasswordLoggedInUser(data);
      if (checkStatus(response)) {
        showSnackbarMessage({
          message: 'Senha alterada com sucesso!',
          severity: 'success',
        });
      }
    } catch (error: any) {
      let formError = setInputErrorsFromApi(passwordFormSetError, error?.data.errors);
      if (formError) {
        showSnackbarMessage({
          message: formError,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (user?.id) {
      reset({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        cpf: user?.client?.cpf,
        phone: user?.client?.phone,
        birthdate: user?.client.birthdate ? getFormatedDate(user.client.birthdate) : '',
      });
    }
  }, [user]);

  return (
    <>
      <CustomCard sx={{ mt: 4 }}>
        <CardContent>
          <CardHeader
            title='Dados Pessoais'
            subheader='Utilize os campos abaixo para modificar seus dados pessoais e de contato.'
          />
          <form id='userForm' onSubmit={handleSubmitUser(onSubmitUserUpdate)}>
            <Grid container spacing={4} sx={{ px: 2, mt: 1 }}>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlUserForm}
                  name='first_name'
                  label='Nome'
                  placeholder='Nome'
                  type='text'
                  variant='standard'
                  required
                  errorMessage={userFormErrors.first_name?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlUserForm}
                  name='last_name'
                  label='Sobrenome'
                  placeholder='Sobrenome'
                  type='text'
                  variant='standard'
                  required
                  errorMessage={userFormErrors.last_name?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlUserForm}
                  name='email'
                  label='Email'
                  placeholder='Email'
                  type='email'
                  variant='standard'
                  errorMessage={userFormErrors.email?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlUserForm}
                  name='phone'
                  label='Telefone'
                  placeholder='Telefone'
                  variant='standard'
                  mask='(99) 99999-9999'
                  type='tel'
                  required
                  errorMessage={userFormErrors.phone?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlUserForm}
                  name='cpf'
                  label='CPF'
                  placeholder='CPF'
                  variant='standard'
                  mask='999.999.999-99'
                  type='tel'
                  required
                  errorMessage={userFormErrors.cpf?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlUserForm}
                  name='birthdate'
                  label='Data de Nascimento'
                  placeholder='Data de Nascimento'
                  variant='standard'
                  mask='99/99/9999'
                  type='tel'
                  required
                  errorMessage={userFormErrors.birthdate?.message}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='contained' type='submit' fullWidth={isMobile}>
                  Alterar Dados
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </CustomCard>
      <form id='form-password' onSubmit={handleSubmitPassword(onSubmitChangePassword)}>
        <CustomCard sx={{ mt: 4 }}>
          <CardContent sx={{ p: 2 }}>
            <CardHeader title='Alteração de Senha' subheader='Para alterar sua senha, preencha os campos abaixo' />
            <Grid container spacing={3} sx={{ px: 2 }}>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlPasswordForm}
                  name='password'
                  label='Senha Atual'
                  placeholder='Senha Atual'
                  type='password'
                  variant='standard'
                  autoComplete='new-password'
                  errorMessage={passwordFormErrors.password?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlPasswordForm}
                  name='new_password'
                  label='Nova Senha'
                  placeholder='Senha'
                  type='password'
                  variant='standard'
                  autoComplete='new-password'
                  errorMessage={passwordFormErrors.new_password?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={controlPasswordForm}
                  name='password_confirmation'
                  label='Confirmação de Senha'
                  placeholder='Confirmação de Senha'
                  type='password'
                  variant='standard'
                  autoComplete='new-password'
                  errorMessage={passwordFormErrors.password_confirmation?.message}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='contained' type='submit' fullWidth={isMobile}>
                  <span>Alterar Senha</span>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </CustomCard>
      </form>
    </>
  );
};
