import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Card, CardContent, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../../context/GlobalContextProvider';
import { useUserContext } from '../../../../context/UserContextProvider';
import { useTravelInsuranceContext } from '../../../../context/insurances/TravelInsuranceContextProvider';
import { Passenger } from '../../../../models/Passenger';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import { TravelInsuranceService } from '../../../../services/TravelInsurance.service';
import { AddressService } from '../../../../services/ZipcodeService.service';
import { checkStatus } from '../../../../utils/api/response';
import { getFormatedDate } from '../../../../utils/methods';
import { PassengersModalAdditionalInfo } from '../../PassengersModalAdditionalInfo/PassengersModalAdditionalInfo';
import {
  ActionsWrapper,
  Container,
  CustomCard,
  CustomCardContent,
  Footer,
  HeaderWrapper,
  Subtitle,
  Title,
} from '../styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: TravelInsurance) => void;
  currentStep: boolean;
}

const getDefaultValues = (existingPassengers: Passenger[] = []) => {
  if (existingPassengers?.length > 0) {
    return existingPassengers?.map((passenger) => ({
      ...passenger,
      id: passenger.id,
      first_name: passenger.first_name || '',
      last_name: passenger.last_name || '',
      birthdate: passenger.birthdate,
      existsInApi: true,
    }));
  } else {
    return [
      {
        id: uniqueId(),
        first_name: '',
        last_name: '',
        birthdate: '',
        existsInApi: false,
      },
    ];
  }
};

const validatePassenger = (passenger: any) => {
  return passenger.cpf && passenger.zipcode && passenger.email;
};

export const PassengersStep = ({ onPrevious, onNext, currentStep }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { user, refreshUserLocalStorage } = useUserContext();
  const { travelInsuranceData } = useTravelInsuranceContext();

  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage, loading } = useAppContext();

  const [passengerEdition, setPassengerEdition] = useState<Passenger>();
  const [passengers, setPassengers] = useState<Passenger[]>(getDefaultValues(travelInsuranceData.passengers));

  const passengersOrderByInvalidFirst = passengers?.sort((a, b) => {
    if (!validatePassenger(a)) {
      return -1;
    }
    return 1;
  });

  const createPassenger = async (passenger: Passenger) => {
    try {
      const response = await TravelInsuranceService.addPassenger(String(travelInsuranceData.id), {
        ...passenger,
        birthdate: passenger.birthdate,
      });
      return response;
    } catch (error: any) {
      console.log('Erro ao adicionar passageiro', error);
      return error;
    }
  };

  const updatePassenger = async (passenger: Passenger) => {
    try {
      const response = await TravelInsuranceService.updatePassenger(
        String(travelInsuranceData.id),
        String(passenger.id),
        {
          ...passenger,
          birthdate: passenger.birthdate,
        }
      );
      return response;
    } catch (error: any) {
      console.log('Erro ao atualizar passageiro', error);
      return error;
    }
  };

  const validatePassengers = () => {
    let isValid = true;
    passengers.forEach((passenger) => {
      if (!validatePassenger(passenger)) {
        isValid = false;
      }
    });
    return isValid;
  };

  const isUserPassenger = (passenger: Passenger) => {
    if (`${passenger.first_name} ${passenger.last_name}` === `${user?.first_name} ${user?.last_name}`) {
      return true;
    }
  };

  const updateUserAddresses = async (passenger: Passenger) => {
    let dataToSubmit = {
      id: '',
      street: passenger.street,
      zipcode: passenger.zipcode,
      neighborhood: passenger.neighborhood,
      city: passenger.city,
      state: passenger.state,
    };

    const existingAddress = user?.client.addresses.find((address) => address.zipcode == passenger.zipcode);
    if (existingAddress?.id) {
      dataToSubmit = { ...dataToSubmit, id: existingAddress?.id };
    }

    if (existingAddress?.id) {
      try {
        await AddressService.updateAddress(dataToSubmit);
      } catch (error: any) {
        console.log('Erro ao atualizar endereço', error);
      }
    } else {
      try {
        await AddressService.createAddress(dataToSubmit);
      } catch (error: any) {
        console.log('Erro ao criar endereço', error);
      }
    }
    refreshUserLocalStorage();
  };

  const onSubmit = async () => {
    setPassengerEdition(undefined);

    showGlobalSpinner({
      message: 'Aguarde um momento...',
    });

    if (passengers?.length > 1 && !validatePassengers()) {
      hideGlobalSpinner();
      showSnackbarMessage({
        message: 'Preencha todos os dados dos passageiros.',
        severity: 'error',
      });
      return;
    }

    const response = await Promise.all(
      passengers.map(async (passenger) => {
        if (!passenger.existsInApi) {
          return await createPassenger(passenger);
        } else {
          if (isUserPassenger(passenger)) {
            await updateUserAddresses(passenger);
          }
          return await updatePassenger(passenger);
        }
      })
    );

    hideGlobalSpinner();

    if (response.find((r) => !checkStatus(r))) {
      showSnackbarMessage({
        message: 'Erro ao salvar dados dos passageiros.',
        severity: 'error',
      });
    } else {
      const data: TravelInsurance = {
        progress_step: 9,
      };
      onNext(data);
    }
  };

  const handleSubmitFormPassenger = async (passenger: Passenger) => {
    if (passenger.id) {
      const newPassengers = [...passengers];
      const index = newPassengers.findIndex((p) => p.id === passenger.id);
      newPassengers[index] = passenger;
      setPassengers(newPassengers);
    } else {
      setPassengers([...passengers, { ...passenger, id: uniqueId() }]);
    }

    setPassengerEdition(undefined);

    //se houver apenas um passageiro, ao completar os dados, avançar para a próxima etapa
    if (passengers.length == 1 && currentStep) {
      await onSubmit();
    }
  };

  const handleShowEditPassengerModal = (index: number) => {
    setPassengerEdition(passengers[index]);
  };

  useEffect(() => {
    if (passengers.length == 1 && currentStep) {
      setPassengerEdition(passengers[0]);
    }
  }, [currentStep, passengers]);

  return (
    <>
      <CustomCard ismobile={isMobile}>
        <CustomCardContent>
          <Container ismobile={isMobile}>
            <HeaderWrapper>
              <Title>Passageiros</Title>
              <Subtitle>Complete a inclusão dos dados dos passageiros.</Subtitle>
            </HeaderWrapper>
            <Grid
              container
              spacing={2}
              justifyContent={passengers?.length == 1 ? 'center' : 'flex-start'}
              sx={{ display: 'flex', flex: 1 }}
            >
              {passengersOrderByInvalidFirst?.map((passenger, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    sx={{
                      border: 'solid 1px',
                      borderColor: validatePassenger(passenger) ? 'success.main' : 'primary.main',
                    }}
                    onClick={() => handleShowEditPassengerModal(index)}
                  >
                    <CardContent>
                      <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                        <Avatar
                          sx={{ width: 56, height: 56, backgroundColor: 'primary.main', marginRight: 2 }}
                          alt={`${passenger.first_name} ${passenger.last_name}`}
                        >
                          {`${passenger.first_name?.charAt(0)}${passenger.last_name?.charAt(0)}`}
                        </Avatar>
                        <Stack spacing={1} sx={{ display: 'flex', flex: 1 }}>
                          <Stack direction='row' spacing={1}>
                            <span>{`${passenger.first_name} ${passenger.last_name}`}</span>
                          </Stack>
                          {passenger.birthdate && (
                            <Stack direction='row' spacing={1}>
                              <span>{getFormatedDate(passenger?.birthdate.toString())}</span>
                            </Stack>
                          )}
                        </Stack>
                        {validatePassenger(passenger) ? (
                          <Alert severity='success' sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            Dados completos
                          </Alert>
                        ) : (
                          <Stack direction='column' rowGap={1} sx={{ alignItems: 'center' }}>
                            <Alert severity='warning' sx={{ justifyContent: 'center', alignItems: 'center' }}>
                              Dados incompletos
                            </Alert>
                            <Typography variant='caption' color='primary'>
                              Clique para completar
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </CustomCardContent>
      </CustomCard>
      <Footer>
        <ActionsWrapper ismobile={isMobile}>
          <LoadingButton
            fullWidth={isMobile}
            variant='outlined'
            color='primary'
            onClick={onPrevious}
            disabled={loading}
            size='large'
            startIcon={<ArrowBackIosIcon />}
          >
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth={isMobile}
            variant='contained'
            color='primary'
            onClick={onSubmit}
            disabled={loading || !validatePassengers()}
            loading={loading}
            size='large'
            endIcon={<ArrowForwardIosIcon />}
          >
            Avançar
          </LoadingButton>
        </ActionsWrapper>
      </Footer>
      <PassengersModalAdditionalInfo
        passengerEdition={passengerEdition}
        onClose={() => setPassengerEdition(undefined)}
        onConfirm={handleSubmitFormPassenger}
      />
    </>
  );
};
