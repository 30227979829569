import { Box, Typography } from '@mui/material';
import { HelpDialogComponent } from '../../../components/basics/HelpDialogComponent/HelpDialogComponent';

interface Props {
  cashbackValues?: any;
  handleClickCashback: () => void;
  cashbackHelpDialog: boolean;
}

export const HelpDialog = ({ cashbackValues, handleClickCashback, cashbackHelpDialog }: Props) => {
  return (
    <>
      <HelpDialogComponent
        open={cashbackHelpDialog}
        cancelText='Fechar'
        onCancel={handleClickCashback}
        onDismiss={handleClickCashback}
        title='Valores disponíveis no programa de Cashback'
      >
        <Typography variant='h5' color='primary'>
          Extrato de Cashback
        </Typography>

        <Typography variant='body1' gutterBottom>
          Nesta tela, você pode verificar o estado do seu cashback em diferentes níveis do nosso programa de indicações:
        </Typography>

        <Typography variant='h6' mt={3}>
          Nível 1: Amigos Diretos
        </Typography>
        <Box ml={2}>
          <Typography color='primary'>Cashback Bloqueado:</Typography>
          <Typography variant='body1' gutterBottom>
            Seus indicados contrataram seguros, mas a confirmação está pendente.
          </Typography>
          <Typography color='primary'>Cashback Disponível:</Typography>
          <Typography variant='body1'>Quando a confirmação é obtida, seu cashback é liberado.</Typography>
        </Box>
        <Typography variant='h6' mt={3}>
          Níveis 2 a 5
        </Typography>
        <Box ml={2}>
          <Typography variant='body1' gutterBottom>
            Para os outros níveis, o processo é o mesmo. O cashback é bloqueado até que a confirmação seja obtida.
          </Typography>
        </Box>

        <Typography variant='h6' mt={3}>
          Como funciona?
        </Typography>
        <Box ml={2}>
          <Typography variant='body1'>
            É simples, sempre que alguém da sua rede de indicações até o quinto nível adquire um seguro, você é
            recompensado.
          </Typography>
          <Typography variant='body1'>
            Quando seus indicados contratam seguros, a confirmação pode levar alguns dias. Alguns seguros podem ser
            cancelados ou não pagos, e precisamos garantir que o seguro esteja realmente ativo antes de liberar o
            cashback. Assim que o seguro estiver ativo, liberaremos o cashback para você.
          </Typography>
        </Box>
        <Typography mt={3} variant='body1' color='primary'>
          Obrigado por compartilhar nosso aplicativo e aproveitar as recompensas!
        </Typography>
      </HelpDialogComponent>
    </>
  );
};
