import { Helmet } from 'react-helmet-async';
// @mui
import { Alert, Button, Card, CardContent, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks

// components
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { PasswordStrengthComponent } from '../../../components/basics/PasswordStrengthComponent/PasswordStrengthComponent';
import { TextInput } from '../../../components/basics/TextInput/TextInput';
import Logo from '../../../components/logo';
import { FormState } from '../../../models/FormState';
import { ROUTES } from '../../../routes/routesList';
import { PasswordStrength } from '../../../sections/@auth/RegisterForm';
import { AuthService } from '../../../services/Auth.service';
import { checkStatus } from '../../../utils/api/response';
import { ChangePasswordSchema } from '../../../utils/forms/auth/formValidationSchemas';
import { strengthColor, strengthIndicator } from '../../../utils/forms/auth/password-strength';
// sections
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

interface FormFields {
  password: string;
  password_confirmation: string;
}

export default function ChangePassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [level, setLevel] = useState<PasswordStrength | null>(null);

  const [validToken, setValidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState<FormState>({
    severity: 'info',
    message: '',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(ChangePasswordSchema),
  });

  const passwordWatch = useWatch({
    control,
    name: 'password',
  });

  const handleChangePassword = async (data: FormFields) => {
    if (!token) {
      setFormState({
        severity: 'error',
        message: 'Token de alteração da senha inválido.',
      });
      return;
    }

    try {
      const response = await AuthService.changePassword(String(token), data);
      if (checkStatus(response)) {
        setFormState({
          severity: 'success',
          message: response?.data.message,
        });

        setTimeout(() => {
          navigate(ROUTES.public.login, { replace: true });
        }, 3000);
      }
    } catch (error: any) {
      setFormState({
        severity: 'error',
        message:
          error?.data.errors ||
          'Algo inesperado aconteceu ao alterar a sua senha, por favor, tente novamente mais tarde.',
      });
    }
  };

  const handleValidateToken = async () => {
    setLoading(true);

    try {
      const response = await AuthService.changePasswordValidateToken(String(token));
      if (checkStatus(response)) {
        setValidToken(true);
      }
    } catch (error: any) {
      setFormState({
        severity: 'error',
        message:
          error?.data.errors ||
          'Ocorreu um erro ao validar o código de alteração da senha, por favor, realize uma nova solicitação de troca de senha.',
      });
    }

    setLoading(false);
  };

  const changePassword = (value: any) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    if (passwordWatch) {
      changePassword(passwordWatch);
    }
  }, [passwordWatch]);

  useEffect(() => {
    if (token) {
      handleValidateToken();
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title> Nova Senha | 91 Seguros </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
        <Container maxWidth='sm'>
          <StyledContent>
            <Card>
              <CardContent>
                {formState?.message ? (
                  <Stack spacing={3}>
                    <Typography variant='h6'>Alteração de senha</Typography>
                    <Alert severity={formState.severity} variant='outlined'>
                      {formState.message}
                    </Alert>
                  </Stack>
                ) : loading ? (
                  <Stack spacing={3} alignItems='center'>
                    <Typography variant='h6'>Estamos validando a sua conta, por favor, aguarde.</Typography>
                    <CircularProgress />
                  </Stack>
                ) : (
                  validToken && (
                    <form onSubmit={handleSubmit(handleChangePassword)}>
                      <Stack spacing={3}>
                        <Stack>
                          <Typography variant='h6'>Alteração de senha</Typography>
                          <Typography sx={{ color: 'text.secondary' }}>
                            É importante que você crie uma senha forte, utilize letras maiúsculas e minúsculas, números
                            e caracteres especiais.
                          </Typography>
                        </Stack>
                        <Stack>
                          <TextInput
                            control={control}
                            name='new_password'
                            label='Nova Senha'
                            type='password'
                            autoComplete='off'
                            errorMessage={errors.password?.message}
                            onBlur={() => setLevel(null)}
                            required
                          />
                        </Stack>
                        {level && <PasswordStrengthComponent passwordStreght={level} />}
                        <Stack>
                          <TextInput
                            control={control}
                            name='password_confirmation'
                            label='Confirmação da senha'
                            type='password'
                            autoComplete='off'
                            errorMessage={errors.password_confirmation?.message}
                            required
                          />
                        </Stack>
                        <Stack sx={{ flex: 1, alignItems: 'end', justifyContent: 'flex-end' }}>
                          <Button
                            fullWidth
                            size='large'
                            type='submit'
                            variant='contained'
                            onClick={handleSubmit(handleChangePassword)}
                          >
                            Alterar Senha
                          </Button>
                        </Stack>
                      </Stack>
                    </form>
                  )
                )}
              </CardContent>
            </Card>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
