import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import { Box, Button, CardContent, Dialog, Grid, Stack, Typography, useTheme } from '@mui/material';
import 'dayjs/locale/pt-br';
import { useForm } from 'react-hook-form';
import { TextInput } from '../../../../../../components/basics/TextInput/TextInput';
import { Passenger } from '../../../../../../models/Passenger';

import { yupResolver } from '@hookform/resolvers/yup';
import { Theme, useMediaQuery } from '@mui/material';
import { PassengerSchema } from '../../../../../../utils/forms/auth/formValidationSchemas';
import { formatDateToApi, getFormatedDate } from '../../../../../../utils/methods';
import { BoardingCard, CardFooter, CustomCardHeader, Label, Line, LineWrapper, PassengerInfo } from './styles';

interface Props {
  passenger?: Passenger;
  onClose: () => void;
  onConfirm: (passenger: Passenger) => void;
}

interface FormFields {
  name: string;
  birthdate: string;
}

export const PassengersModal = ({ passenger, onClose, onConfirm }: Props) => {
  const theme = useTheme();
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const getDefaultValues = () => {
    return {
      name: passenger?.first_name && passenger?.last_name ? passenger?.first_name + ' ' + passenger?.last_name : '',
      birthdate: passenger?.birthdate ? getFormatedDate(passenger?.birthdate) : '',
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(),
    resolver: yupResolver(PassengerSchema),
  });

  const formValidations = (data: FormFields) => {
    if (data.name && data.name.trim().split(' ').length < 2) {
      setError('name', { type: 'manual', message: 'Informe nome e sobrenome do passageiro' });
      return false;
    }

    return true;
  };

  const onSubmit = (data: FormFields) => {
    if (!formValidations(data)) {
      return;
    }

    let name = data.name.split(' ');

    let firstName = name?.[0] || '';
    let lastName = name?.slice(1).join(' ') || '';
    onConfirm({
      id: passenger?.id || '',
      first_name: String(firstName),
      last_name: String(lastName),
      birthdate: formatDateToApi(data.birthdate),
      existsInApi: passenger?.existsInApi || false,
    });
  };

  return (
    <div>
      <Dialog
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={true}
        onClose={onClose}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <form>
          <BoardingCard ismobile={ismobile}>
            <CustomCardHeader>
              <Typography variant='h4' sx={{ p: '1rem', pl: '2rem' }} color={theme.palette.common.white}>
                Dados do Passageiro
              </Typography>
            </CustomCardHeader>
            <CardContent sx={{ display: 'flex', flex: 1 }}>
              <PassengerInfo>
                <Grid container spacing={4} sx={{ display: 'flex', flex: 1 }}>
                  <Grid item xs={12} md={6}>
                    <Label>Nome do passageiro</Label>
                    <TextInput
                      control={control}
                      name='name'
                      variant='standard'
                      errorMessage={errors.name?.message}
                      required
                    />
                    <Typography variant='caption'>Digite o nome</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Label>Data de nascimento</Label>
                      <TextInput
                        control={control}
                        name='birthdate'
                        variant='standard'
                        mask='99/99/9999'
                        type='tel'
                        errorMessage={errors.birthdate?.message}
                        required
                      />
                      <Typography variant='caption'>Digite a data de nascimento</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                      <Button variant='outlined' onClick={onClose}>
                        Cancelar
                      </Button>
                      <Button variant='contained' type='submit' onClick={handleSubmit(onSubmit)}>
                        Confirmar
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </PassengerInfo>
            </CardContent>
            <Stack sx={{ flexDirection: 'row' }}>
              <CardFooter>
                <LineWrapper>
                  <Line width={98} />
                  <Line />
                  <Line width={98} />
                </LineWrapper>
                <LocalAirportIcon
                  fontSize='large'
                  sx={{ color: theme.palette.common.white, transform: 'rotate(90deg)' }}
                />
              </CardFooter>
            </Stack>
          </BoardingCard>
        </form>
      </Dialog>
    </div>
  );
};
