import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab, Theme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import { PageTitle } from '../../../../theme/GlobalStyledComponents';
import { TravelInsuranceCard } from './TravelInsuranceCard';

interface Props {
  subheader?: string;
  list: TravelInsurance[];
}

export const TravelInsuranceList = ({ subheader, list, ...other }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const [value, setValue] = useState<string>('1');

  let startedList = list.filter((item) => item.status === 'started');
  let othersList = list.filter((item) => item.status !== 'started');
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <PageTitle isMobile={isMobile}>Seguros Viagem</PageTitle>

      <TabContext value={value}>
        <Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider', justifyContent: 'center', mt: 1 }}>
          <TabList onChange={handleChangeTab}>
            <Tab label='Cotando' value='1' />
            {othersList.length > 0 && <Tab label='Contratados' value='2' />}
          </TabList>
        </Box>

        <TabPanel value='1' sx={{ px: 0 }}>
          <Grid container rowSpacing={2}>
            {startedList.map((item: TravelInsurance, index: number) => (
              <Grid item key={index}>
                <TravelInsuranceCard item={item} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value='2' sx={{ px: 0 }}>
          <Grid container rowSpacing={2}>
            {othersList.map((item: TravelInsurance, index: number) => (
              <Grid item key={index}>
                <TravelInsuranceCard item={item} />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  );
};
