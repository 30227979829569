import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, ButtonBase, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { SelectOption } from '../../../../../../components/basics/ControlledAutocomplete/ControlledAutocomplete';
import { CardContainer } from './styles';

interface Props {
  destiny: SelectOption;
  onSelected: (key: string) => void;
  selected: boolean;
}

const ICONS = {
  europe: '/assets/icons/europe-logo.png',
  asia: '/assets/icons/asia-logo.png',
  africa: '/assets/icons/africa-logo.png',
  oceania: '/assets/icons/oceania-logo.png',
  north_america: '/assets/icons/north-america-logo.png',
  south_america: '/assets/icons/south-america-logo.png',
  caribbean_mexico: '/assets/icons/mexico-caribean-logo.png',
  middle_east: '/assets/icons/middle-east-logo.png',
};

export const DestinyCard = ({ destiny, selected, onSelected }: Props) => {
  const theme = useTheme();

  const handleNewInsurance = () => {
    if (destiny.key) {
      onSelected(destiny.key);
    }
  };

  return (
    <Grid item xs={6} sm={4} md={3}>
      <CardContainer selected={selected} className={`destiny-card ${destiny.key}`}>
        {selected && (
          <Box
            sx={{
              position: 'absolute',
              top: '12%',
              right: '10%',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
            </Box>
          </Box>
        )}
        <ButtonBase
          onClick={handleNewInsurance}
          style={{
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <Box mb={1}>
              <img
                src={ICONS[destiny.key]}
                alt={`${destiny.value} icon`}
                style={{
                  maxHeight: '50px',
                  minHeight: '50px',
                  margin: 'auto',
                  display: 'block',
                }}
              />
            </Box>
            <Typography variant={selected ? 'body1' : 'body2'} color='common.black'>
              {destiny.value}
            </Typography>
          </CardContent>
        </ButtonBase>
      </CardContainer>
    </Grid>
  );
};
