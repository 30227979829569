import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';
import { User } from '../models/User';
import { ROUTES } from '../routes/routesList';
import { LOCAL_STORAGE_KEYS } from './../localstorage/localstorageKeys';

const decryptUserData = () => {
  try {
    let userStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.user);
    if (userStorage) {
      const secretKey = process.env.REACT_APP_CRYPTO_KEY;
      return JSON.parse(CryptoJS.AES.decrypt(userStorage, secretKey!).toString(CryptoJS.enc.Utf8));
    }
  } catch (error: any) {
    throw new Error('Erro ao descriptografar dados do usuário');
  }
};

const createAxiosInstance = () => {
  const endpoint = process.env.REACT_APP_API_URL;

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: endpoint || '',
    paramsSerializer: { indexes: null },
  });

  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      let user: User | undefined;
      try {
        user = decryptUserData();
        if (user) {
          config.headers.Authorization = 'apiToken ' + user.id + '###' + user.session_token;
        }
      } catch (e) {
        console.log('Sem autorização', e);
      }

      return config;
    },
    (error: any) => {
      console.log('error', error);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.status === 401 && window.location.pathname !== ROUTES.public.login) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
        window.location.href = ROUTES.public.login;
        return Promise.reject(response);
      } else if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        return Promise.reject(response);
      }
    },
    (error: any) => {
      console.log('error', error);
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;
