import * as Yup from 'yup';
import { isValidDate, validateCpf } from '../../methods';

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  password: Yup.string().required('Senha é obrigatória'),
});

export const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
});

export const RegistrationSchema = Yup.object().shape({
  first_name: Yup.string().required('Nome é obrigatório'),
  last_name: Yup.string().required('Sobrenome é obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  password: Yup.string().required('Senha é obrigatória'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem')
    .required('Confirmação de senha é obrigatória'),
});

export const ChangePasswordSchema = Yup.object().shape({
  new_password: Yup.string().required('Senha é obrigatória'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Senhas não conferem')
    .required('Confirmação de senha é obrigatória'),
});

export const TravelStepOneSchema = Yup.object().shape({
  destiny: Yup.object().required('Destino é obrigatório'),
});

export const PassengerInfoSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  birthdate: Yup.string()
    .required('Data de nascimento é obrigatória')
    .test('isValidDate', 'Data de nascimento inválida', (value) => isValidDate(value)),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  phone: Yup.string().required('Telefone é obrigatório'),
  cpf: Yup.string()
    .required('CPF é obrigatório')
    .test('valid-cpf', 'CPF inválido', (value) => validateCpf(value)),
});

export const TravelDatesSchema = Yup.object().shape({
  start_date: Yup.string()
    .required('Data de início é obrigatória')
    .test('isValidDate', 'Data de início inválida', (value) => isValidDate(value, true)),
  end_date: Yup.string()
    .required('Data de término é obrigatória')
    .test('isValidDate', 'Data de término inválida', (value) => isValidDate(value, true)),
});

export const PassengerSchema = Yup.object().shape({
  name: Yup.string().required('Nome completo é obrigatório'),
  birthdate: Yup.string()
    .required('Data de nascimento é obrigatória')
    .test('isValidDate', 'Data de nascimento inválida', (value) => isValidDate(value)),
});

export const addressSchema = Yup.object().shape({
  zipcode: Yup.string()
    .required('CEP é obrigatório')
    .test('valid-zipcode', 'CEP inválido', function (value) {
      if (value && value.length === 9) {
        // Verificar o formato do CEP apenas se ele tiver todos os dígitos
        return /^\d{5}-\d{3}$/.test(value);
      }
      return true; // Se não tiver todos os dígitos, a validação é ignorada
    }),
  state: Yup.string().required('Estado é obrigatório'),
  city: Yup.string().required('Cidade é obrigatória'),
  neighborhood: Yup.string().required('Bairro é obrigatório'),
  street: Yup.string().required('Rua é obrigatória'),
});

export const withdrawScheme = Yup.object().shape({
  pixKey: Yup.string().required('Chave PIX é obrigatória'),
});
