import createAxiosInstance from '../middleware/axios.interceptors';

const systemDatTypesUrl = '/insurance/system-data-types-options';

export const AutocompleteService = {
  getAutocompleteOptions: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(systemDatTypesUrl);
    } catch (err: any) {
      throw err.response;
    }
  },
};
