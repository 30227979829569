import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AddressModal } from '../../../../components/AddressModalComponent/AddressModal';
import { DialogComponent } from '../../../../components/basics/DialogComponent/DialogComponent';
import { ApiResultAddress } from '../../../../models/Address';
import { UserService } from '../../../../services/User.service';
import { AddressService } from '../../../../services/ZipcodeService.service';
import { checkStatus } from '../../../../utils/api/response';

export const TabAddress = () => {
  const theme = useTheme();
  const [addresses, setAddresses] = useState<ApiResultAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<ApiResultAddress | undefined>();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [loadingAddresses, setLoadingAddresses] = useState<boolean>(false);
  const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const fetchAddresses = async () => {
    setLoadingAddresses(true);

    try {
      const response = await UserService.getUserInfo();
      if (checkStatus(response)) {
        setAddresses(response?.data.result.client.addresses);
      }
    } catch (error: any) {
      console.log('Erro ao buscar endereços', error);
    } finally {
      setLoadingAddresses(false);
    }
  };

  const handleOpenAddressModal = () => {
    setOpenAddressModal(true);
  };

  const handleCloseAddressModal = () => {
    setOpenAddressModal(false);
  };

  const handleNewAddress = async () => {
    await fetchAddresses();
    handleCloseAddressModal();
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>, address: ApiResultAddress) => {
    setAnchorEl(event.currentTarget);
    setSelectedAddress(address);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedAddress(undefined);
  };

  const handleAddressEdition = () => {
    setAnchorEl(null);
    setOpenAddressModal(true);
  };

  const handleAddressDeletionModal = () => {
    setAnchorEl(null);
    setOpenDeleteDialog(true);
  };

  const handleDeleteAddress = async () => {
    if (selectedAddress) {
      try {
        const response = await AddressService.deleteAddress(selectedAddress?.id);
        if (checkStatus(response)) {
          await fetchAddresses();
          setOpenDeleteDialog(false);
        }
      } catch (error: any) {
        console.log('Erro ao excluir endereço', error);
      }
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {addresses?.map((address, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent sx={{ display: 'flex', position: 'relative' }}>
                <Box sx={{ display: 'flex', flex: 0.1, justifyContent: 'center', alignItems: 'center' }}>
                  <HomeIcon sx={{ marginRight: 2, color: theme.palette.primary.main }} />
                </Box>
                <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant='subtitle1' color='textSecondary' component='p'>
                    {address?.city?.name}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {address?.neighborhood}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {address?.zipcode}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {address?.city?.state?.name}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {address?.city?.state?.country?.name}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {address?.complement}
                  </Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 10, right: 5 }}>
                  <IconButton onClick={(event) => handleOpenPopover(event, address)}>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button variant='contained' color='primary' onClick={handleOpenAddressModal}>
            Adicionar endereço
          </Button>
        </Grid>
      </Grid>
      <CardOptionsPopover
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        onEdit={handleAddressEdition}
        onDelete={handleAddressDeletionModal}
      />
      {openAddressModal && (
        <AddressModal
          addressEdition={selectedAddress}
          title={selectedAddress ? 'Editar endereço' : 'Adicionar endereço'}
          subtitle={`Preencha os campos abaixo para ${selectedAddress ? 'atualizar o' : 'criar um novo '} endereço. `}
          onSave={handleNewAddress}
          onCancel={handleCloseAddressModal}
        />
      )}
      <DialogComponent
        open={openDeleteDialog}
        title='Excluir Endereço'
        description='Tem certeza que deseja excluir este endereço?'
        confirmText='Excluir'
        cancelText='Cancelar'
        onConfirm={handleDeleteAddress}
        onCancel={() => setOpenDeleteDialog(false)}
        onDismiss={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

interface CardOptionsPopoverProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const CardOptionsPopover = ({ anchorEl, onClose, onEdit, onDelete }: CardOptionsPopoverProps) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={onEdit} sx={{ p: 1 }}>
        <EditIcon sx={{ mr: 1 }} fontSize='small' />
        <Typography variant='body2' color='textSecondary'>
          Editar
        </Typography>
      </MenuItem>
      <MenuItem onClick={onDelete} sx={{ p: 1 }}>
        <DeleteOutlineIcon sx={{ mr: 1 }} fontSize='small' />
        <Typography variant='body2' color='textSecondary'>
          Excluir
        </Typography>
      </MenuItem>
    </Popover>
  );
};
