import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link, Stack } from '@mui/material';
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TextInput } from '../../components/basics/TextInput/TextInput';
import { useAutocompleteContext } from '../../context/AutocompleteContextProvider';
import { useAppContext } from '../../context/GlobalContextProvider';
import { useGoogleAnalyticsContext } from '../../context/GoogleAnalyticsContextProvider';
import { useUserContext } from '../../context/UserContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../localstorage/localstorageKeys';
import { FormState } from '../../models/FormState';
import { User } from '../../models/User';
import { ROUTES } from '../../routes/routesList';
import { AuthService } from '../../services/Auth.service';
import { checkStatus } from '../../utils/api/response';
import { LoginSchema } from '../../utils/forms/auth/formValidationSchemas';
import { setInputErrorsFromApi } from '../../utils/forms/form-errors';
import { RecoverPasswordModal } from './RecoverPasswordModal/RecoverPasswordModal';

// ----------------------------------------------------------------------
export interface LoginForm {
  email: string;
  password: string;
  website_quote: string | null;
}

export default function LoginForm() {
  const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();

  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormState>({
    severity: 'info',
    message: '',
  });
  const [showRecoverPasswordModal, setShowRecoverPasswordModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const websiteQuote = searchParams.get('quote');

  const { setUser } = useUserContext();
  const { fetchAutocompleteOptions } = useAutocompleteContext();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<LoginForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(LoginSchema),
  });

  const setUserInLocalStorage = (user: User) => {
    try {
      const secretKey = process.env.REACT_APP_CRYPTO_KEY;
      const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(user), secretKey!).toString();
      localStorage.setItem(LOCAL_STORAGE_KEYS.user, encryptedUserData);
    } catch (error: any) {
      localStorage.clear();
      navigate('/' + ROUTES.public.login, { replace: true });
    }
  };

  const onSubmit = async (data: LoginForm) => {
    showGlobalSpinner({
      title: 'Realizando login',
      message: 'Aguarde um momento...',
    });
    data.website_quote = websiteQuote;

    try {
      const response = await AuthService.doLogin(data);
      if (checkStatus(response)) {
        setFormState({
          severity: 'success',
          message: response?.data.message || 'Login realizado com sucesso!',
        });

        newGoogleAnalyticsEvent('login', { method: 'email' });

        setUserInLocalStorage(response?.data.user);
        setUser(response?.data.user);
        fetchAutocompleteOptions();

        navigate('/', { replace: true });
      }
    } catch (error: any) {
      const errors = setInputErrorsFromApi(setError, error?.data.errors);
      if (errors) {
        setFormState({
          severity: 'error',
          message: errors || 'Não foi possível realizar o login, verifique seus dados e tente novamente.',
        });
      }
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    setUser(null);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {formState.message && (
            <Alert severity={formState.severity} variant='outlined'>
              {formState.message}
            </Alert>
          )}
          <TextInput
            control={control}
            name='email'
            type='email'
            label='E-mail'
            required
            fullWidth
            size='medium'
            autoComplete='email'
            errorMessage={errors.email?.message}
          />
          <TextInput
            control={control}
            type='password'
            name='password'
            label='Senha'
            size='medium'
            required
            fullWidth
            autoComplete='current-password'
          />
          <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2 }}>
            <Box />
            <Link
              variant='subtitle2'
              underline='hover'
              onClick={() => setShowRecoverPasswordModal(true)}
              sx={{ cursor: 'pointer' }}
            >
              Esqueceu sua senha?
            </Link>
          </Stack>

          <LoadingButton fullWidth size='large' type='submit' variant='contained'>
            Login
          </LoadingButton>
        </Stack>
      </form>
      {showRecoverPasswordModal && (
        <RecoverPasswordModal email={getValues('email')} onClose={() => setShowRecoverPasswordModal(false)} />
      )}
    </>
  );
}
