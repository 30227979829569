import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { useState } from 'react';
import { useUserContext } from '../../../context/UserContextProvider';
import { UserTabs } from '../UserTabPanel/UserTabPanel';
import { CardTabs, CoverCard, UserAvatar, UserAvatarWrapper, UserAvatarWrapperHover } from './styles';

export const UserCoverComponent = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { user, setUser, updateUserImage } = useUserContext();

  const handleEditAvatar = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      setLoading(true);
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setUser({ ...user, profile_picture: reader.result });
        updateUserImage({
          profile_picture: reader.result as string,
        });
      };
      setLoading(false);
    };
    input.click();
  };

  return (
    <CoverCard>
      <UserAvatarWrapper>
        <UserAvatarWrapperHover onClick={handleEditAvatar}>
          <EditIcon className='edit-icon' />
          <UserAvatar src={user?.profile_picture} alt='Usuario' className='user-image'>
            {loading && <CircularProgress size={34} sx={{ color: theme.palette.common.white }} />}
          </UserAvatar>
          <input type='file' style={{ display: 'none' }} />
        </UserAvatarWrapperHover>
        <Typography variant='h4' mt={1} color={theme.palette.common.white}>
          {user?.first_name} {user?.last_name}
        </Typography>
      </UserAvatarWrapper>
      <CardTabs>
        <UserTabs />
      </CardTabs>
    </CoverCard>
  );
};
