import { Box, Card, CardContent, Chip, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routesList';
import { getFormatedDate } from '../../../../utils/methods';

interface ItemProps {
  item: any;
}

export const TravelInsuranceCard = ({ item }: ItemProps) => {
  const { destiny_name, status, start_date, end_date, chosen_quotation, status_display } = item;
  const navigate = useNavigate();
  const image = '/assets/insurances/assist-card-logo.png';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleRedirect = () => {
    if (item?.id) {
      navigate(ROUTES.forms.newTravelInsuranceId.replace(':id', item.id));
    }
  };

  return (
    <Card onClick={handleRedirect}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h6'>{destiny_name}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 0 }}>
            <Box
              component='img'
              alt={'Seguro viagem'}
              src={image}
              sx={{ width: isMobile ? '80%' : '50%', height: 'auto' }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography variant='body1'>{chosen_quotation?.product_name}</Typography>
            <Typography variant='body2'>
              <strong>Início: </strong> {getFormatedDate(start_date)}
            </Typography>
            <Typography variant='body2'>
              <strong>Fim: </strong> {getFormatedDate(end_date)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: isMobile ? 'row' : 'column',
              justifyContent: isMobile ? 'space-between' : 'center',
            }}
          >
            <Typography variant='h6' sx={{ textAlign: 'right' }} noWrap>
              {chosen_quotation?.total_price && <> R$ {chosen_quotation?.total_price}</>}
            </Typography>
            <Chip
              label={status_display}
              color={status === 'started' ? 'info' : status === 'active' ? 'success' : 'default'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
