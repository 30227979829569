import AddCircleIcon from '@mui/icons-material/AddCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import SecurityIcon from '@mui/icons-material/Security';
import { ROUTES } from '../../../routes/routesList';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Início',
    path: ROUTES.menuItems.app,
    icon: <HomeIcon />,
  },
  {
    title: 'Novo Seguro',
    path: ROUTES.menuItems.newInsurance,
    icon: <AddCircleIcon />,
  },
  {
    title: 'Extrato',
    path: ROUTES.menuItems.statement,
    icon: <AttachMoneyIcon />,
  },
  {
    title: 'Indicações',
    path: ROUTES.menuItems.referrals,
    icon: <FavoriteIcon />,
  },
  {
    title: 'Mais',
    path: ROUTES.menuItems.options,
    icon: <MenuIcon />,
  },
  // {
  //   title: 'login',
  //   path: `${ROUTES.auth.login}`,
  // },
  // {
  //   title: 'register',
  //   path: `${ROUTES.auth.registration}`,
  // },
  // {
  //   title: 'Not found',
  //   path: `${ROUTES.notFound}`,
  // },
];

export default navConfig;
