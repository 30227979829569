import { faCcAmex, faCcDiscover, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseTextFieldProps, TextField, Tooltip } from '@mui/material';
import creditCardType from 'credit-card-type';
import { ChangeEvent, useState } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface Props extends BaseTextFieldProps {
  name: string;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  errorMessage?: string | FieldError | undefined;
  type?: string;
  required?: boolean;
  minRows?: number;
  variant?: 'outlined' | 'standard' | 'filled';
  mask?: string | RegExp | undefined;
}

export const CreditCardTextInput = ({
  name,
  control,
  label,
  placeholder,
  autoFocus,
  defaultValue,
  errorMessage,
  type = 'text',
  minRows,
  variant = 'outlined',
  mask,
  ...rest
}: Props) => {
  const [cardType, setCardType] = useState(''); // estado para armazenar a bandeira do cartão identificada

  const getCardIcon = () => {
    switch (cardType) {
      case 'American Express':
        return faCcAmex;
      case 'Discover':
        return faCcDiscover;
      case 'Mastercard':
        return faCcMastercard;
      case 'Visa':
        return faCcVisa;
      default:
        return null;
    }
  };

  const _renderInput = ({ field }: any) => {
    const inputProps = {
      ...field,
      ...rest,
      fullWidth: true,
      type,
      label,
      autoFocus,
      placeholder,
      multiline: !!minRows,
      minRows: minRows || 1,
      error: !!errorMessage,
      helperText: errorMessage,
      variant,
    };

    // Se creditCard for verdadeiro, adiciona um evento onChange que irá identificar a bandeira do cartão
    inputProps.onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const cardNumber = event.target.value.replace(/\s/g, ''); // Remove os espaços em branco
      const cardInfo = creditCardType(cardNumber);
      if (cardInfo.length > 0) {
        setCardType(cardInfo[0].niceType); // Define o estado com o nome da bandeira identificada
      } else {
        setCardType(''); // Limpa o estado se não for possível identificar a bandeira
      }
      field.onChange(event); // Chama o evento onChange original para atualizar o valor no formulário
    };
    inputProps.InputProps = {
      endAdornment: (
        <span>
          {cardType && (
            <Tooltip title={cardType}>
              <FontAwesomeIcon icon={getCardIcon()} />
            </Tooltip>
          )}
        </span>
      ), // Adiciona o ícone dinamicamente de acordo com a bandeira identificada
    };

    return mask ? (
      <InputMask mask={mask} {...inputProps}>
        {(inputProps: any) => <TextField {...inputProps} />}
      </InputMask>
    ) : (
      <TextField {...inputProps} />
    );
  };

  return <Controller name={name} control={control} render={_renderInput} />;
};
