import createAxiosInstance from '../middleware/axios.interceptors';

const logUrl = '/public/website-logs';

export interface LogData {
  user_identifier: string;
  url: string;
  log_data: {
    [key: string]: any;
  };
}

export const LogService = {
  log: async (data: LogData) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(logUrl, data);
    } catch (err: any) {
      console.log('Error in LogService.log', err);
    }
  },
};
