import { ReactNode, createContext, useContext, useState } from 'react';
import { GlobalSpinner } from '../components/basics/GlobalSpinner/GlobalSpinner';
import { SnackbarComponent } from '../components/basics/Snackbar/SnackbarComponent';

interface AppContextType {
  loading: boolean;
  showGlobalSpinner: (data: GlobalSpinner) => void;
  hideGlobalSpinner: () => void;
  showSnackbarMessage: (data: SnackbarMessage) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

interface GlobalSpinner {
  message: string;
  visible?: boolean;
  style?: 'spinner' | 'blink-logo';
}

interface SnackbarMessage {
  title?: string;
  message: string;
  visible?: boolean;
  severity?: 'error' | 'warning' | 'info' | 'success';
  autoHideDuration?: number;
}

export const AppProvider = ({ children }: Props) => {
  const [globalSpinnerData, setGlobalSpinnerData] = useState<GlobalSpinner | undefined>(undefined);
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | undefined>(undefined);

  const LOADING = globalSpinnerData?.visible || false;

  const showGlobalSpinner = (data: GlobalSpinner) => {
    setGlobalSpinnerData({
      message: data.message,
      visible: true,
      style: data.style,
    });
  };

  const hideGlobalSpinner = () => {
    setGlobalSpinnerData(undefined);
  };

  const showSnackbarMessage = (data: SnackbarMessage) => {
    setSnackbarMessage({
      title: data.title || '',
      message: data.message,
      visible: true,
      severity: data.severity || 'info',
      autoHideDuration: data.autoHideDuration || 6000,
    });
  };

  return (
    <AppContext.Provider
      value={{
        loading: LOADING,
        showGlobalSpinner,
        hideGlobalSpinner,
        showSnackbarMessage,
      }}
    >
      {children}
      {snackbarMessage?.visible && (
        <SnackbarComponent
          message={snackbarMessage.message}
          severity={snackbarMessage.severity!}
          autoHideDuration={snackbarMessage.autoHideDuration}
          onClose={() => setSnackbarMessage(undefined)}
        />
      )}
      {globalSpinnerData?.visible && <GlobalSpinner text={globalSpinnerData.message} style={globalSpinnerData.style} />}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
