import { Alert, Portal, Snackbar, SnackbarProps, Theme, useMediaQuery } from '@mui/material';

interface Props extends SnackbarProps {
  severity: 'error' | 'warning' | 'info' | 'success';
  onClose: () => void;
  message: string;
  autoHideDuration?: number;
}

export const SnackbarComponent = ({ severity, onClose, message, autoHideDuration }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <Portal>
      <Snackbar
        open={true}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: isMobile ? 'center' : 'right' }}
      >
        <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }} variant='filled'>
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};
