import { CircularProgress, Stack, Typography } from '@mui/material';

interface Props {
  title?: string;
}

export const LoadingIndicator = ({ title }: Props) => {
  return (
    <Stack direction="row" alignItems="center">
      <CircularProgress size={18} color="inherit" />
      <Typography variant="body2" sx={{ ml: 1 }}>
        {title ? title : 'Carregando...'}
      </Typography>
    </Stack>
  );
};
