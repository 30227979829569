import { Theme, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../../components/basics/PageWrapper/PageWrapper';
import { ROUTES } from '../../../routes/routesList';
import { DocumentContainer, Paragraph, ParagraphTitle } from './styles';

export default function TermsOfUsePage() {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/' + ROUTES.menuItems.options);
  };

  return (
    <PageWrapper pageTitle='Termos de Uso' onClose={handleClose}>
      <Helmet>
        <title> Dashboard | Termos de Uso </title>
      </Helmet>

      <DocumentContainer ismobile={ismobile}>
        <Paragraph ismobile={ismobile}>
          A 91 Corretora Digital de Seguros LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o
          nº 31.613.374/0001-06, com sede à Rua Domingos Oliva dos Santos, nº 90 - Segundo andar, Bairro Sanvitto, na
          Cidade de Caxias do Sul/RS, CEP 95012-320 oferece aos seus USUÁRIOS uma ferramenta que possibilita a
          contratação de seguro, via aplicativo. Nela os USUÁRIOS poderão contratar o seguro de forma direta com as
          seguradoras cadastradas ou poderão contar com o auxílio de um corretor.
        </Paragraph>

        <Paragraph ismobile={ismobile}>
          A seguir apresentamos os “TERMOS E CONDIÇÕES DE USO”, documento que relaciona as principais regras que devem
          ser observadas por todos que acessam o APLICATIVO 91 e/ou utilizam suas funcionalidades. Como condição para
          acesso e uso das funcionalidades exclusivas do APLICATIVO, sobretudo a contratação dos serviços oferecidos,
          o USUÁRIO declara que fez a leitura completa e atenta das regras deste documento e de sua Política de
          Privacidade  estando plenamente ciente e conferindo, assim, sua livre e expressa concordância com os termos
          aqui estipulados. Caso não esteja de acordo com estas Diretivas, o USUÁRIO poderá descontinuar o seu acesso. 
        </Paragraph>

        <Paragraph ismobile={ismobile}>
          Por favor, certifique-se de ter lido e entendido nossos “TERMOS E CONDIÇÕES DE USO, O “Aceite” dos termos
          descritos a seguir é indispensável para qualquer forma de utilização do APLICATIVO 91. O cadastro
          como USUÁRIO não é permitido para pessoa juridicamente incapaz, bem como para aqueles que não concordem
          plenamente com os “TERMOS E CONDIÇÕES DE USO”. O presente documento encontra-se vigente nesta data, vinculando
          todos os USUÁRIOS do APLICATIVO 91, aos termos e condições aqui descritos.
        </Paragraph>

        <ParagraphTitle>TERMOS DE USO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Para os fins deste regulamento, as palavras grafadas em maiúsculo e negrito terão os seguintes significados:
          USUÁRIO: Aquele que utiliza e acessa o APLICATIVO 91. APLICATIVO 91 (APP 91): Plataforma onde se realiza a
          interação entre o USUÁRIO e a 91 Corretora de Seguros. A venda do SEGURO, será realizada exclusivamente
          através do APLICATIVO. SEGURADO: Pessoa física ou jurídica que contrata o seguro em benefício pessoal ou de
          terceiros. SEGURADORA: Empresa parceira da 91, que possui a autorização SUSEP (Superintendência de Seguros
          Privados) para assumir os riscos que o segurado possa estar exposto. É a empresa responsável e que tem
          autorização para indenizar o SEGURADO, caso ele venha a ter algum prejuízo ou o cause. SEGURO:  Operação que
          toma a forma jurídica de um contrato, em que uma das partes (SEGURADORA) se obriga para com a
          outra (SEGURADO) a indenizá-la em caso de sinistro prejuízo (prejuízo), resultante de um evento futuro,
          possível e incerto, indicado nas condições gerais e nas cláusulas específicas ratificadas na apólice, mediante
          o recebimento de uma importância (prêmio). CONTRATO: Documento gerado após emissão da apólice pela Seguradora
          parceira. CORRETOR: Profissional autônomo, pessoa física ou jurídica, legalmente autorizado a representar
          o SEGURADO e a intermediar a celebração de contratos de seguro entre a SEGURADORA e as pessoas físicas ou
          entre a SEGURADORA e as pessoas jurídicas, de direito público ou privado. Na forma do Decreto-Lei nº 73/66, o
          corretor é o responsável por orientar o segurado acerca das coberturas, obrigações e exclusões do contrato de
          seguro. A situação cadastral do corretor poderá ser consultada no site www.susep.gov.br, com o número do
          registro na SUSEP, nome completo, CNPJ ou CPF. BONIFICAÇÃO: Após fechado o CONTRATO de SEGURO, o USUÁRIO terá
          a possibilidade de indicar novos USUÁRIOS através de um link personalizado. Destas indicações, as que
          resultarem em novos CONTRATOS serão convertidas em BONIFICAÇÃO para o indicante. Esta remuneração pode chegar
          até 91% do valor pago em seu SEGURO.
        </Paragraph>

        <ParagraphTitle>DAS OBRIGAÇÕES DO USUÁRIO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Ao acessar o APP 91, e/ou utilizar quaisquer dos serviços disponibilizados, o USUÁRIO declara sua plena,
          integral, e irrestrita concordância com as condições previstas neste regulamento. Constituem obrigações
          do USUÁRIO: Fornecer informações verdadeiras, precisas, atualizadas, e completas, no ato do seu eventual
          cadastramento junto ao APP 91; Fornecer informações verdadeiras e precisas para efeitos de cobrança, no caso
          de contratação de qualquer serviço oferecido através do APP 91; Utilizar o APP 91 unicamente com intuito
          lícito, sendo vedada qualquer utilização para fins estranhos à finalidade original, tais como para fazer
          publicidade ou vendas de produtos e/ou serviços; transmitir correntes; obter dados; e outros, a estes não se
          limitando; Não violar quaisquer direitos de propriedade industrial e intelectual de terceiros na utilização
          do APP 91; Não utilizar o APP 91, para armazenar, distribuir, transmitir, reproduzir, ou de qualquer forma
          colocar à disposição de terceiros arquivos, mensagens, desenhos, gráficos, sons, imagens, fotografias,
          programas de computador, e quaisquer outros materiais que: (i) violem direitos de terceiros, de qualquer tipo;
          (ii) contenham conteúdo delituoso, difamatório, infame, violento, pornográfico, ou contrário à lei, à moral e
          aos bons costumes; (iii) contenham conteúdo discriminatório em razão de sexo, raça, religião, condição social,
          idade, crença, e qualquer outro; (iv) contenham conteúdo que possa induzir a um estado de ansiedade ou temor;
          (v) induzam ou incitem os usuários a se envolver em práticas perigosas, de risco, ou nocivas à integridade
          física e psíquica; (vi) que contenham informações falsas, inexatas, exageradas, ou que de qualquer forma
          induzam a erro quanto às reais intenções do USUÁRIO; (vii) sejam contrários à honra, reputação, intimidade e
          privacidade de qualquer pessoa; (viii) constituam publicidade ilícita ou enganosa, e/ou concorrência desleal;
          (ix) causem dificuldades ao normal funcionamento do APP 91; entre outras que possam ser nocivos a terceiros e
          à 91, a estes não se limitando; Não infringir quaisquer direitos de terceiros com seus atos na utilização
          do APP 91; Não utilizar o APP 91 para cometer e/ou tentar cometer atos que tenham como objetivo: (a) obter
          acesso não autorizado a outro computador, servidor, ou rede; (b) interromper serviço, servidores, ou rede de
          computadores através de qualquer método ilícito; (c) burlar qualquer sistema de autenticação ou de segurança;
          (d) vigiar secretamente terceiros; (e) acessar informações confidenciais, de qualquer natureza, tais como nome
          de usuários ou senhas de acesso de outro usuário da Internet que esteja vulnerável; (f) acessar informações
          financeiras e de risco, ou que possam causar prejuízos a qualquer pessoa, como, por exemplo, perfil de risco,
          números de cartões de crédito, contas bancárias, entre outros atos, a estes não se limitando; Ser civilmente
          capaz, nos moldes da legislação civil vigente no Brasil, sendo que, caso seja constatado qualquer dano
          cometido por agentes total ou relativamente incapazes, com ou sem permissão de seus pais, tutores ou
          representantes legais, estes serão solidariamente responsáveis por todos os atos praticados pelos menores;
          Respeitar toda e qualquer legislação brasileira vigente e aplicável à utilização do APP 91 e aos atos do
          usuário dentro dele, bem como qualquer norma ou lei aplicável no país de onde se origina o acesso do USUÁRIO;
          Respeitar todas as condições estabelecidas no presente instrumento.
        </Paragraph>

        <ParagraphTitle>DO OBJETO E DA VINCULAÇÃO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O presente Regulamento de Uso tem por objeto regular o acesso e utilização do APP 91, pelo USUÁRIO,
          estabelecendo os direitos e obrigações relacionados à utilização do aplicativo. A este regulamento poderão se
          acrescer outras disposições específicas aplicáveis ao USUÁRIO, tratadas em contratos que regulem operações
          específicas dentro do aplicativo, como, a título exemplificativo, as operações de cotação e contratação de
          seguros, a estas não se limitando. O APP 91 é uma ferramenta que possibilita ao USUÁRIO a cotação e
          contratação de seguro. Nele, os USUÁRIOS poderão contratar o seguro de forma direta com
          as SEGURADORAS parceiras, ou ainda poderão optar pela contratação mediante a intermediação de um CORRETOR.
        </Paragraph>

        <ParagraphTitle>DO FORNECIMENTO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O acesso ao APP 91 será disponibilizado 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana, da forma
          como está, e sem qualquer garantia adicional, implícita ou explícita, da 91. Não obstante o disposto no item
          antecedente, o acesso ao aplicativo poderá estar temporariamente indisponível, a qualquer momento, em caso de
          interrupções necessárias em razão de serviços de manutenção de qualquer natureza, ou falhas na operação dos
          servidores, das empresas fornecedoras de energia elétrica, empresas prestadoras de serviços de
          telecomunicações, casos fortuitos, força maior, ou ações de terceiros que não sejam do controle da 91.{' '}
        </Paragraph>

        <ParagraphTitle>DO CADASTRO E DAS FORMAS DE UTILIZAÇÃO DO APP 91</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Ao USUÁRIO, é possível realizar a cotação de um contrato de seguro, preenchendo no APP 91, os seus dados
          pessoais, bem como dos demais dados necessários para a contratação da modalidade de seguro pretendida. No
          presente regulamento, fica determinado que só terão acesso ao APP 91, aqueles que forem indicados por
          um USUÁRIO do aplicativo, ou ainda aqueles que baixarem o APP via loja virtual e solicitarem um convite
          através de um pré-cadastro em tela/lista de espera; Caso o interessado opte por aguardar em tela/lista de
          espera, o seu pré- cadastro ficará armazenado no banco de dados da 91, para recebimento futuro do link de
          indicação e posterior ativação do seu cadastro. No momento da realização do cadastro, o USUÁRIO deverá
          preencher um formulário elaborado pela 91, onde algumas perguntas básicas que não influenciam diretamente no
          preço e que estão relacionadas à cobertura do seguro, serão preenchidas automaticamente pelo APP 91, sempre em
          condições mais benéficas ao a ele. Dessa forma, ao aceitar os “TERMOS E CONDIÇÕES DE USO”, o USUÁRIO fica
          ciente de que concorda com a política da 91, bem como renuncia ao direito de realizar qualquer interferência
          quando houverem divergências entre as respostas automáticas do APP no formulário e a sua realidade.
        </Paragraph>

        <ParagraphTitle>DA CONTRATAÇÃO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Ao USUÁRIO será apresentado a cotação de cobertura do seguro de menor valor, oferecido pelas seguradoras
          parceiras. O USUÁRIO, quando no interesse de efetivar a contratação, deverá indicar se quer realiza-la
          diretamente com a seguradora parceira, ou opta pela intermediação de um CORRETOR. O USUÁRIO fica cientificado
          através do presente regulamento que a escolha do CORRETOR que intermediará a contratação do seguro, será feita
          com base na sua localização e mediante critérios pré-estabelecidos pela 91. Através deste regulamento fica
          determinado que a vigência/renovação do CONTRATO de seguro depende única e exclusivamente da aprovação
          da SEGURADORA parceira contratada. Dessa forma, a 91 DECLARA não possuir qualquer responsabilidade pela
          aprovação do CONTRATO de seguro, bem como pela sua renovação.
        </Paragraph>

        <ParagraphTitle>DAS INDICAÇÕES E DA BONIFICAÇÃO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O USUÁRIO tem a possibilidade de, após a contratação do seguro, realizar a indicação do APP 91 à outras
          pessoas, o que se dará através do envio de um link personalizado. Das indicações realizadas pelo USUÁRIO,
          àquelas que originarem a efetivação de um novo CONTRATO, serão convertidas em BÔNUS para o indicante.
          A BONIFICAÇÃO do USUÁRIO indicante se dará em 5 níveis e cada nível de indicação possui um porcentual
          diferente, o qual será convertido em BÔNUS. A árvore de bonificação (nível de indicações) do USUÁRIO só será
          disponibilizada quando solicitado pelo mesmo. Contudo, o USUÁRIO fica ciente que qualquer informação capaz de
          gerar a identificação de outros USUÁRIOS, possui caráter sigiloso e não será revelada pela 91. Fica
          estabelecido que o valor das BONIFICAÇÕES, oriundas das indicações realizadas pelo USUÁRIO, não ultrapassará o
          limite de 91% (noventa e um por cento) do valor pago pelo seguro contratado, descontado o valor IOF  e
          eventuais outras taxas vinculadas à transferência bancária. OS USUÁRIOS ficam cientes que ao optarem pela
          intermediação de um CORRETOR na contratação do seguro, o percentual de BONIFICAÇÃO sofrerá redução de 50%
          (cinquenta por cento) na cadeia de indicações a ele vinculada.
        </Paragraph>

        <ParagraphTitle>DO PAGAMENTO DA BONIFICAÇÃO E DO SAQUE</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O valor da BONIFICAÇÃO, disponível para saque não poderá ser maior que o valor da parcela vinculada
          ao CONTRATO de seguro vigente. Fica determinado que o pagamento da BONIFICAÇÃO está condicionado à plena
          vigência do CONTRATO de SEGURO. O valor recebido à título de BONIFICAÇÃO, sofrerá desconto da taxa
          da TED, quando da transferência para a conta bancária informada pelo USUÁRIO. O saque da importância relativa
          à BONIFICAÇÃO, deverá ser solicitado através do APP 91, com antecedência mínima de 15 (quinze) dias, estando
          o USUÁRIO ciente desde já que o valor a receber só estará disponível em sua conta, na data fixada pela 91,
          vinculada a todos os USUÁRIOS. A valor pago à título de BONIFICAÇÃO, à qual o USUÁRIO tenha direito, sempre
          será rateada em 12 (doze meses); O USUÁRIO fica ciente que a 91, não armazena em seus servidores dados
          bancários a ele vinculados.
        </Paragraph>

        <ParagraphTitle>DA RENOVAÇÃO DO SEGURO CONTRATADO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          A 91 informará ao USUÁRIO, com a antecedência mínima 15 dias do vencimento do seguro, sobre a sua renovação. A
          renovação do CONTRATO será efetivada pela SEGURADORA parceira, com a proposta de atualização previamente
          enviada, cabendo à ela exclusivamente aprovar ou não a sua renovação. As formas pagamento
          do SEGURO contratado, seguirão as regras estabelecidas pela SEGURADORA parceira. O USUÁRIO fica ciente que a
          definição e alteração dos termos contidos na apólice de SEGURO contratado, são de responsabilidade exclusiva
          da SEGURADORA parceira.
        </Paragraph>

        <ParagraphTitle>DISPOSIÇÕES GERAIS</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Todas as eventuais senhas de acesso fornecidas ao USUÁRIO, ou por ele implantadas, são pessoais e
          intransferíveis, de uso exclusivo do mesmo, que assume integral responsabilidade pela sua guarda e sigilo, bem
          como pelo uso indevido por terceiros. O USUÁRIO não poderá utilizar nomes de usuário ou senhas de caráter
          ofensivo ou ilegal, que induzam terceiros a erro, que confundam o USUÁRIO com a 91, ou que, de qualquer forma,
          sejam considerados inadequados, ou que já estejam em uso por outro usuário, a exclusivo critério exclusivo
          da 91; Para maior segurança, a 91 recomenda a periódica alteração da senha, pelo USUÁRIO, a fim de dificultar
          qualquer utilização indevida de seu nome de usuário. Ainda para a segurança do USUÁRIO, a 91 recomenda a NÃO
          UTILIZAÇÃO de senhas de fácil acerto, tais como datas de aniversários, placas de veículos, números de
          telefone, nomes próprios ou de familiares, número de documentos, endereços, entre outros. Visando maior
          segurança do USUÁRIO, a 91 recomenda a leitura da “Cartilha de Segurança para Internet”, desenvolvida pelo
          NIC-BR Security Office, atualmente disponível no endereço http://www.nbso.nic.br/docs/cartilha/, sendo
          recomendável a adoção das medidas recomendadas em tal material. Caso o USUÁRIO identifique qualquer material
          que suponha ser ofensivo, ilegal, ou atentatório à moral e aos bons costumes, disponibilizado no APP 91,
          deverá, imediatamente, comunicar a 91, através do envio de e-mail ao endereço atendimento: contato@91.digital,
          para que possa apurar a denúncia, ficando a responsabilidade da 91 limitada à remoção deste material, se
          considerada procedente a suposição do USUÁRIO. Reconhecendo o alcance mundial da Internet, o USUÁRIO concorda
          em cumprir a legislação do local onde está situado, bem como as leis vigentes na sede da 91, no Brasil. Ao
          utilizar o APP 91, contratar seguros, ou efetuar seu cadastramento para utilizar serviços de acesso restrito,
          o USUÁRIO estará expressamente aderindo à Política de Privacidade da 91, que poderá ser consultado no endereço
          https://91.digital, a ele se vinculando.
        </Paragraph>

        <ParagraphTitle>DAS TENTATIVAS DE ACESSO NÃO AUTORIZADAS</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O acesso a áreas restritas dentro do APP 91 somente é permitido a usuários devidamente cadastrados, utilizando
          seu nome de usuário e senha, sendo tais áreas consideradas fechadas. Neste sentido, o USUÁRIO fica que ciente
          que se alguma falha no sistema for encontrada que permita seu acesso a qualquer área restrita do APP, ainda
          que por mera tentativa e erro de acerto de senha, ainda assim incidirá o USUÁRIO nas sanções civis e criminais
          decorrentes de sua conduta.
        </Paragraph>

        <ParagraphTitle>DO FORNECIMENTO DE DADOS CADASTRAIS</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O USUÁRIO fica ciente que, sempre que fundado em indício de ato ilícito, e solicitadas, por qualquer
          interessado, informações sobre a propriedade de um registro do usuário no APP 91, será lícito à 91 fornecer os
          dados cadastrais do USUÁRIO, a seu exclusivo critério, sem que tal ato constitua qualquer violação de sigilo.
          O USUÁRIO concorda e declara, expressamente, que pela natureza dos dados que fornece à 91, estes não
          constituem correspondência, comunicação telegráfica, dados, ou comunicações telefônicas.
        </Paragraph>

        <ParagraphTitle> DA EXCLUSÃO DE RESPONSABILIDADE</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O USUÁRIO está ciente de que o acesso ao APP 91 dependerá da manutenção, pelo USUÁRIO, de uma conta de acesso
          à Internet, através de qualquer provedor de acesso, ou contratação, pelo USUÁRIO, não sendo de
          responsabilidade da 91 a conexão do USUÁRIO com a internet, cabendo exclusivamente ao USUÁRIO a aquisição e/ou
          aluguel de equipamentos, bem como sua manutenção e integração com a rede de comunicações, assim como dos
          programas necessários à utilização do APP. O USUÁRIO declara estar ciente que o acesso ao APP 91 depende da
          funcionalidade simultânea de diversos fatores, alguns alheios ao controle da 91, tais como a interação de
          servidores e serviços de telecomunicações de terceiros, a adequação dos equipamentos do USUÁRIO, e outros, a
          estes não se limitando. Assim, a 91 fica isenta de qualquer responsabilidade por falhas decorrentes de
          qualquer impossibilidade de acesso ao aplicativo, em virtude de motivos que fogem ao seu controle e
          diligência. O acesso ao APP 91, dependerá, ainda, da manutenção dos requisitos mínimos de acesso pelo USUÁRIO,
          quais sejam um: aparelho celular, com sistema:  A 91 não garante, sob nenhuma hipótese, que os sistemas de
          conexão (via telefônica ou qualquer outro porventura disponível) com os endereços na Internet onde estará
          disponibilizado o aplicativo estejam livres de possíveis falhas, interrupções, vírus, problemas, e outros
          fatores, não se responsabilizando pela qualidade e segurança da rede utilizada para acesso ao APP 91, vez que
          esta é mantida por terceiros, que não a 91, e, portanto, foge do seu controle, diligência e responsabilidade.
          A 91 não será, em hipótese alguma, responsável por quaisquer danos decorrentes da interrupção do acesso ao APP
          91, sempre que tal indisponibilidade ocorrer por culpa de terceiros, o que foge ao controle e diligência
          da 91. O USUÁRIO fica ciente que a 91 recomenda, para acesso ao APP 91, a utilização de plataformas de acesso
          e de tecnologia (hardware e/ou software) devidamente seguras e atualizadas. Assim, qualquer utilização,
          pelo USUÁRIO, de plataformas e tecnologia desatualizadas, não consideradas seguras, dar-se-á por conta e risco
          do USUÁRIO, ficando a 91 isenta de qualquer responsabilidade por danos, de qualquer natureza, suportados
          pelo USUÁRIO, nesta hipótese, sendo lícito à 91, ainda, não permitir, através de processos técnicos, o acesso
          do USUÁRIO ao aplicativo, nessas circunstâncias, a exclusivo critério da 91, se for o caso. A 91 não se
          responsabiliza por perdas e danos causados pela utilização irregular dos serviços e do conteúdo
          disponibilizado através do APP 91, ou pela utilização inadequada dos equipamentos pelo USUÁRIO, excluindo da
          responsabilidade da 91, ainda, a ocorrência de efeitos de casos fortuitos e força maior. A 91 poderá, a
          qualquer tempo, alterar a forma do APP 91, por razões de ordem técnica e operacional, sempre visando a melhor
          interação do USUÁRIO com o app, bem como a facilitação da utilização do mesmo. Embora a 91 empregue seus
          melhores esforços, considerando as características dos sistemas informáticos, a mesma não se responsabiliza
          por atos de terceiros que logrem êxito em coletar ou utilizar, por quaisquer meios, dados cadastrais e
          informações disponibilizadas pelo USUÁRIO. A 91 não possui qualquer controle, vínculo ou responsabilidade com
          relação aos endereços ou informações de terceiros veiculadas no APP 91. Em vista disso, o USUÁRIO declara
          estar ciente que a 91 não declara nem garante a exatidão ou confiabilidade de quaisquer informações, conteúdos
          ou propagandas de terceiros, que eventualmente vierem a ser disponibilizadas no aplicativo, servindo a 91 de
          mera intermediária, sem que tal fato lhe acarrete qualquer responsabilidade. A 91 não garante, sob nenhuma
          hipótese, nos serviços prestados por terceiros, disponíveis através do APP 91, a ausência de vírus ou outros
          elementos nocivos que possam produzir alterações no sistema informático (software e hardware) do USUÁRIO, pois
          a 91 não exerce nenhuma verificação prévia sobre estes, servindo como mero intermediário. Da mesma forma,
          a 91 não garante a ausência de qualquer elemento nocivo nos documentos eletrônicos e arquivos que venham a ser
          instalados em seu sistema informático a partir desses sites de terceiros. Assim, neste ato, o USUÁRIO isenta
          a 91 de qualquer responsabilidade pelos danos e prejuízos que possam ser causados nas hipóteses acima, devendo
          toda e qualquer responsabilidade recair sobre os terceiros que causarem os eventuais danos ao USUÁRIO, que
          fica ciente que a 91 é mera intermediária, a quem compete, unicamente, fornecer informações que colaborem com
          a identificação do responsável pelo eventual ilícito, tais como dados que a mesma eventualmente possua
          armazenados. Dentro do APP 91, é possível que o USUÁRIO encontre links a sites de terceiros. O USUÁRIO está
          ciente que a 91 não tem controle sobre esses sites, sem qualquer ligação com a mesma, que não tem conhecimento
          das políticas e regulamentos destes sites, que podem ser constantemente alterados, inviabilizando sua
          verificação pela 91. Assim, constitui obrigação do USUÁRIO a verificação dos regulamentos e políticas de tais
          sites de terceiros, para os quais haja link disponível no APP 91, ficando a 91 isenta de qualquer
          responsabilidade por danos, de qualquer natureza, eventualmente causados ao USUÁRIO durante o acesso a tais
          sites da Internet.
        </Paragraph>

        <ParagraphTitle>DA PROPRIEDADE INTELECTUAL E INDUSTRIAL</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Os conteúdo das telas do APP 91, assim como os programas, bancos e bases de dados, redes, arquivos, e
          quaisquer outras criações autorais e intelectuais dos prepostos da 91, são de propriedade exclusiva da mesma,
          e estão protegidos pelas leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer outro
          tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes, nos termos
          das Leis 9.610/98, 9609/98 e 9.279/96. Todos os textos, ícones, sistemas, programas, algoritmos, conteúdo,
          organização, mensagens, desenhos ou qualquer outra identificação que sejam de autoria/titularidade de
          terceiros e publicados pela 91, com a autorização de seus respectivos autores/titulares, ou permissivo legal,
          igualmente são protegidos pelas Leis 9.610/98, 9.609/98 e 9.279/96. Todas as marcas, nomes comerciais ou
          sinais distintivos de qualquer espécie que são publicados no APP 91 são de propriedade da 91 ou terceiros, sem
          que o uso ou acesso ao aplicativo possa ser entendido como atribuição de direitos para que o USUÁRIO possa
          citar as tais marcas, nomes comerciais e sinais distintivos.
        </Paragraph>

        <ParagraphTitle>DA VIGÊNCIA E RESCISÃO</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O presente contrato terá vigência por prazo indeterminado, enquanto o USUÁRIO fizer uso do APP 91.
        </Paragraph>

        <ParagraphTitle>DAS PENALIDADES</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          A violação de quaisquer das disposições constantes neste instrumento poderá acarretar ao USUÁRIO o imediato
          cancelamento de seu acesso ao APP 91, pela 91, independentemente de qualquer aviso ou notificação, sem
          prejuízo do USUÁRIO responder pelos eventuais danos causados, além de sanções criminais, de acordo com sua
          conduta, nos termos da legislação vigente no Brasil.
        </Paragraph>

        <ParagraphTitle> DAS ALTERAÇÕES, REVISÕES E ATUALIZAÇÕES</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          A 91 reserva-se o direito de, a seu exclusivo critério, a qualquer tempo e sem aviso prévio, modificar ou
          alterar este contrato, razão pela qual recomenda-se ao USUÁRIO, quando for utilizar os serviços do APP 91, que
          proceda à leitura das condições vigentes à época de seu acesso, vez que, a cada acesso, um novo contrato é
          celebrado pelo usuário. A utilização dos serviços do APP 91 pelo USUÁRIO implicará na irrestrita aceitação das
          condições vigentes, incluindo as alterações que porventura venham a ser realizadas.
        </Paragraph>

        <ParagraphTitle>DISPOSIÇÕES FINAIS</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O USUÁRIO declara e garante possuir plena capacidade jurídica para a celebração do presente contrato.
          O USUÁRIO declara que todos os dados eventualmente fornecidos são verdadeiros, sob as penas da lei. Os
          direitos e obrigações ora pactuados, bem como a eventual senha de acesso ao APP 91, no caso de usuários
          cadastrados, não poderão ser transferidos pelo USUÁRIO a terceiros, sob qualquer condição, sendo que sua
          utilização indevida isenta a 91 de qualquer responsabilidade, e obriga o USUÁRIO. A tolerância de uma parte
          para com a outra, relativamente a descumprimento de qualquer das obrigações ora assumidas, não será
          considerada novação ou renúncia a qualquer direito, constituindo mera liberalidade, que não impedirá a parte
          tolerante de exigir da outra o fiel cumprimento deste contrato, a qualquer tempo. As atividades desenvolvidas
          pela 91, através do APP 91, não são consideradas de risco, sendo inaplicável a responsabilidade objetiva
          disposta no artigo 927, parágrafo único, do Código Civil, o que o USUÁRIO declara concordar, neste ato.
          O USUÁRIO compreende e autoriza que a 91 utilize artifícios técnicos conhecidos como “cookies”, ou
          semelhantes, para acessar informações sobre a conta do usuário a fim de oferecer um serviço melhor e mais
          personalizado. O USUÁRIO declara estar ciente que existe a possibilidade de configurar seu navegador para ser
          avisado, na tela do computador/celular, sobre a recepção dos “cookies”, e para impedir a sua instalação no
          disco rígido. As informações pertinentes a esta configuração estão disponíveis em instruções e manuais do
          próprio navegador. Para utilizar os serviços da 91 não é necessário que o USUÁRIO permita a recepção de
          “cookies” enviados pela mesma, sem prejuízo de que, em tal caso, será necessário que o USUÁRIO se registre a
          cada vez que acessar uma área que requeira identificação prévia. Sempre que a opção que impeça a instalação
          dos “cookies” não tenha sido ativada, o USUÁRIO poderá pesquisar o disco rígido de seu computador, conforme as
          instruções do próprio navegador, e remover manualmente quaisquer “cookies”. O USUÁRIO concorda na obrigação de
          indenizar, em ação regressiva, qualquer prejuízo causado à 91 em decorrência de ações que envolvam seus atos,
          incluindo os efeitos do art. 125, II, do Código de Processo Civil. Fica eleito o Foro da Comarca de Caxias do
          Sul/RS, com renúncia expressa de qualquer outro, por mais privilegiado que seja, para dirimir qualquer
          controvérsia decorrente do presente contrato, que será regido pelas leis vigentes na República Federativa do
          Brasil.
        </Paragraph>
      </DocumentContainer>
    </PageWrapper>
  );
}
