import { Box, Card, Typography } from '@mui/material';
import styled from 'styled-components';

interface PassengerCardProps {
  ismobile: boolean;
}

export const BoardingCard = styled(Card)<PassengerCardProps>`
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  width: ${({ ismobile }) => (ismobile ? '90%' : '70%')};
`;

export const PassengerInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const CustomCardHeader = styled(Box)`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const Label = styled(Typography)`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const Value = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const CardFooter = styled(Box)`
  display: flex;
  width: 80%;
  height: 3rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 0 40px 0px 40px;
  align-items: center;
`;

export const LineWrapper = styled(Box).attrs({
  gap: 1,
})`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 40%;
`;

interface LineProps {
  width?: number;
}

export const Line = styled(Box)<LineProps>`
  width: ${({ width }) => width || 95}%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.common.white};
`;
