// layouts
import AccountConfirmation from '../pages/Auth/AccountConfirmation/AccountConfirmation';
//
import InvitationAcceptPage from '../pages/Auth/InvitationAccept/InvitationAcceptPage';
import GoogleCallback from '../pages/Auth/Login/GoogleCallback';
import LoginPage from '../pages/Auth/Login/LoginPage';
import ChangePassword from '../pages/Auth/PasswordChange/PasswordChange';
import RegisterPage from '../pages/Auth/Register/RegisterPage';
import { ROUTES } from './routesList';

// ----------------------------------------------------------------------

export const AuthRoutes = [
  {
    path: ROUTES.public.login,
    element: <LoginPage />,
  },
  {
    path: ROUTES.public.googleCallback,
    element: <GoogleCallback />,
  },
  {
    path: ROUTES.public.registration,
    element: <RegisterPage />,
  },
  {
    path: ROUTES.public.invitationAccept,
    element: <InvitationAcceptPage />,
  },
  {
    path: ROUTES.public.accountConfirmEmail,
    element: <AccountConfirmation />,
  },
  {
    path: ROUTES.public.accountChangePassword,
    element: <ChangePassword />,
  },
];
