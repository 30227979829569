import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PageTitle } from '../../theme/GlobalStyledComponents';

// ----------------------------------------------------------------------

export default function InsurancesPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const theme = useTheme();

  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Seguros </title>
      </Helmet>

      <Container>
        <PageTitle isMobile={ismobile}>Seguros</PageTitle>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>Você não possui nenhum seguro cadastrado</Typography>
        </Box>
      </Container>
    </>
  );
}
