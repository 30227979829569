import { Card, CardContent, CardHeader, Divider, Grid, Typography, useTheme } from '@mui/material';
import { formatCurrency } from '../../../../../utils/methods';

interface Props {
  level: string;
  blocked: number;
  available: number;
}

export const CashbackCard = ({ level, blocked, available }: Props) => {
  const theme = useTheme();

  const moneyAvailable = available > 0;

  return (
    <Card
      sx={{
        flex: 1,
        p: 1,
        borderColor: moneyAvailable ? theme.palette.success.light : theme.palette.background.default,
      }}
      elevation={0}
    >
      <CardHeader
        title={`Nível ${level}`}
        titleTypographyProps={{
          variant: 'body1',
          color: 'primary',
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant='body2' color='textSecondary'>
              Bloqueado
            </Typography>
            <Typography variant='body1'>{formatCurrency(blocked)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' color='textSecondary'>
              Disponível
            </Typography>
            <Typography variant='body1'>{formatCurrency(available)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
