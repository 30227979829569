import { Box } from '@mui/material';
import styled from 'styled-components';

interface IContainerProps {
  ismobile: boolean;
}

export const PageTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: 0 0 2rem 2rem;
`;

export const StepperContainer = styled(Box)<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ ismobile }) => (ismobile ? '1rem' : '3rem')};
  align-items: center;
`;
