import { Card } from '@mui/material';
import styled from 'styled-components';

interface ICustomCardProps {
  selected: boolean;
}

export const CustomCard = styled(Card)<ICustomCardProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme, selected }) => (selected ? theme.palette.success.light : theme.palette.grey[100])};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme, selected }) => !selected && theme.palette.action.hover};
    transform: translateY(-5px);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  }
`;
