import createAxiosInstance from '../middleware/axios.interceptors';

const referralsUrl = '/insurance/referrals/';

export const ReferralsService = {
  getReferrals: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(referralsUrl);
    } catch (err: any) {
      throw err.response;
    }
  },
};
