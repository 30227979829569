import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context/UserContextProvider';

import { Alert } from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { LOCAL_STORAGE_KEYS } from '../../localstorage/localstorageKeys';

// ----------------------------------------------------------------------
interface Props {
  handleGoogleLogin: (authorizationToken: string) => void;
  invitationCode?: string;
  websiteQuote?: string | null;
}

export default function GoogleLoginButton({ handleGoogleLogin, invitationCode, websiteQuote }: Props) {
  const navigate = useNavigate();
  const { setUser } = useUserContext();

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    setUser(null);
  }, []);

  const generateUrlParams = () => {
    const params = {};
    if (invitationCode) {
      params['convite'] = invitationCode;
    }
    if (websiteQuote) {
      params['quote'] = websiteQuote;
    }

    if (Object.keys(params).length === 0) {
      return '';
    }
    return window.btoa(JSON.stringify(params));
  };

  return (
    <>
      {googleClientId ? (
        <GoogleOAuthProvider clientId={googleClientId}>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleGoogleLogin(credentialResponse.credential!);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            type='standard'
            size='large'
            text='signin_with'
            logo_alignment='center'
            ux_mode='redirect'
            login_uri={`https://app.91.digital/google-auth-callback/${generateUrlParams()}`}
          />
        </GoogleOAuthProvider>
      ) : (
        <Alert severity='error' variant='outlined' sx={{ width: '100%' }}>
          Google Client ID não configurado.
        </Alert>
      )}
    </>
  );
}
