import createAxiosInstance from '../middleware/axios.interceptors';
import { NewAddress } from '../models/Address';

const zipcodeSearch = 'insurance/zipcode-search';
const addressEndpoint = 'insurance/client/addresses';

export const AddressService = {
  getAddresses: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(addressEndpoint);
    } catch (err: any) {
      throw err.response;
    }
  },

  getAddress: async (zipcode: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(zipcodeSearch, { zipcode });
    } catch (err: any) {
      throw err.response;
    }
  },

  createAddress: async (address: NewAddress) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(addressEndpoint, address);
    } catch (err: any) {
      throw err.response;
    }
  },

  updateAddress: async (address: NewAddress) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(`${addressEndpoint}/${address.id}`, address);
    } catch (err: any) {
      throw err.response;
    }
  },

  deleteAddress: async (addressId: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.delete(`${addressEndpoint}/${addressId}`);
    } catch (err: any) {
      throw err.response;
    }
  },
};
