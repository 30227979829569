import { Box, Theme, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../../components/basics/PageWrapper/PageWrapper';
import { ROUTES } from '../../../routes/routesList';
import { DocumentContainer, Paragraph, ParagraphTitle } from './styles';

export default function PrivacyPolicyPage() {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/' + ROUTES.menuItems.options);
  };

  return (
    <PageWrapper pageTitle='Política de Privacidade' onClose={handleClose}>
      <Helmet>
        <title> Dashboard | Política de Privacidade </title>
      </Helmet>

      <DocumentContainer ismobile={ismobile}>
        <Paragraph>
          A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção
          dos dados pessoais dos usuários e visitantes do <strong>APLICATIVO 91</strong>, com a finalidade de demonstrar
          absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são
          coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações
          pessoais.
        </Paragraph>

        <Paragraph>
          Esta Política de Privacidade aplica-se a todos os usuários e visitantes do <strong>APLICATIVO 91</strong> e
          integra os Termos e Condições Gerais de Uso da <strong>91 Corretora Digital de Seguros LDTA</strong>,
          devidamente inscrita no CNPJ sob o nº <strong>31.613.374/0001-06</strong>, situado em{' '}
          <strong>Rua Pinheiro Machado, nº 1417 Sala 08</strong>, Bairro <strong>Nossa Senhora de Lourdes</strong>, na
          Cidade de Caxias do Sul/RS, CEP <strong>95020-170</strong>, doravante nominada <strong>APLICATIVO 91</strong>.
        </Paragraph>

        <Paragraph>
          O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei
          13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento
          poderá ser atualizado em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a
          consultar periodicamente esta seção.
        </Paragraph>

        <ParagraphTitle>SEÇÃO 02 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE?</ParagraphTitle>
        <Paragraph>
          Os dados pessoais do usuário e visitante são recolhidos pela plataforma da seguinte forma:
          <Box sx={{ ml: ismobile ? 2 : 6 }}>
            <ul>
              <li>
                Quando o usuário cria uma conta/perfil na plataforma <strong>APLICATIVO 91</strong> esses dados são os
                dados de identificação básicos, como e-mail, nome completo, idade, cidade de residência e profissão. A
                partir deles, podemos identificar o usuário e o visitante, além de garantir uma maior segurança e
                bem-estar às suas necessidades.
              </li>
              <li>
                Quando um usuário e visitante acessa o <strong>APLICATIVO 91</strong> as informações sobre interação e
                acesso são coletadas pela empresa para garantir uma melhor experiência ao usuário e visitante. Estes
                dados podem tratar sobre as palavras-chaves utilizadas em uma busca, o compartilhamento de um documento
                específico, comentários, visualizações de páginas, perfis, a URL de onde o usuário e visitante provêm, o
                navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser armazenadas e retidas.
              </li>
              <li>
                Por intermédio de terceiro: o <strong>APLICATIVO 91</strong> recebe dados de terceiros, como por
                exemplo, Facebook e Google, quando um usuário faz login com o seu perfil de um desses sites. A
                utilização desses dados é autorizada previamente pelos usuários junto ao terceiro em questão.
              </li>
            </ul>
          </Box>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 03 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE?</ParagraphTitle>
        <Paragraph>
          Os dados pessoais do usuário e visitante recolhidos são os seguintes:
          <Box sx={{ ml: ismobile ? 2 : 6 }}>
            <ul>
              <li>
                Dados para a criação da conta/perfil no <strong>APLICATIVO 91</strong>: e-mail, nome completo, idade,
                cidade de residência e profissão.
              </li>
              <li>
                Dados para otimização da navegação: acesso a páginas, palavras-chave utilizadas na busca, recomendações,
                comentários, endereço de IP.
              </li>
              <li>
                Dados para concretizar transações: dados referentes ao pagamento e transações, tais como, número do
                cartão de crédito e outras informações sobre o cartão, além dos pagamentos efetuados.
              </li>
              <li>
                Dados relacionados a contratos: diante da formalização do contrato de compra e venda ou de prestação de
                serviços entre a plataforma e o usuário e visitante poderão ser coletados e armazenados dados relativos
                a execução contratual, inclusive as comunicações realizada entre a empresa e o usuário.
              </li>
            </ul>
          </Box>
        </Paragraph>

        <ParagraphTitle>
          SEÇÃO 03 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E VISITANTE?
        </ParagraphTitle>
        <Paragraph>
          Os dados pessoais do usuário e do visitante coletados e armazenados pelo <strong>APLICATIVO 91</strong> tem
          por finalidade:
          <Box sx={{ ml: ismobile ? 2 : 6 }}>
            <ul>
              <li>
                Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço oferecido, facilitar, agilizar e
                cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a experiência dos usuários e
                fornecer funcionalidades específicas a depender das características básicas do usuário.
              </li>
              <li>
                Melhorias da plataforma: compreender como o usuário utiliza os serviços da plataforma, para ajudar no
                desenvolvimento de negócios e técnicas.
              </li>
              <li>Anúncios: apresentar anúncios personalizados para o usuário com base nos dados fornecidos.</li>
              <li>
                Comercial: os dados são usados para personalizar o conteúdo oferecido e gerar subsídio à plataforma para
                a melhora da qualidade no funcionamento dos serviços.
              </li>
              <li>
                Previsão do perfil do usuário: tratamento automatizados de dados pessoais para avaliar o uso na
                plataforma.
              </li>
              <li>
                Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos da plataforma, exclusivo
                para usuários cadastrados.
              </li>
              <li>Dados de contrato: conferir às partes segurança jurídica e facilitar a conclusão do negócio.</li>
            </ul>
          </Box>
          O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá
          mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem
          aplicáveis.
        </Paragraph>

        <ParagraphTitle>SEÇÃO 04 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?</ParagraphTitle>
        <Paragraph>
          <p>
            Os dados pessoais do usuário e visitante são armazenados pela plataforma durante o período necessário para a
            prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto
            no inciso I do artigo 15 da Lei 13.709/18.
          </p>
          <p>
            Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer
            outro tratamento.
          </p>
          <p>
            Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas
            seguintes hipóteses previstas no artigo 16 da referida lei:
          </p>
          <p>I - Cumprimento de obrigação legal ou regulatória pelo controlador;</p>
          <p>II - Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
          <p>
            III - Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta
            Lei;
          </p>
          <p>IV - Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 05 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</ParagraphTitle>
        <Paragraph>
          <p>
            A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais
            de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais
            dados.
          </p>
          <p>
            Os dados relativos a cartões de crédito são criptografados usando a tecnologia "secure socket layer" (SSL)
            que garante a transmissão de dados de forma segura e confidencial, de modo que a transmissão dos dados entre
            o servidor e o usuário ocorre de maneira cifrada e encriptada.
          </p>
          <p>
            A plataforma não se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de
            hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a
            terceiros. O site se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados
            pessoais.
          </p>
          <p>
            Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto,
            podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar
            nossos Termos de Serviço.
          </p>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 06 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?</ParagraphTitle>
        <Paragraph>
          <p>
            Os dados pessoais só podem ser compartilhados com fornecedores de serviços terceirizados como processadores
            de transação de pagamento.
          </p>
          <p>
            Com relação aos fornecedores de serviços terceirizados como processadores de transação de pagamento,
            informamos que cada qual tem sua própria política de privacidade. Desse modo, recomendamos a leitura das
            suas políticas de privacidade para compreensão de quais informações pessoais serão usadas por esses
            fornecedores.
          </p>
          <p>
            Os fornecedores podem ser localizados ou possuir instalações localizadas em países diferentes. Nessas
            condições, os dados pessoais transferidos podem se sujeitar às leis de jurisdições nas quais o fornecedor de
            serviço ou suas instalações estão localizados.
          </p>
          <p>
            Ao acessar nossos serviços e prover suas informações, você está consentindo o processamento, transferência e
            armazenamento desta informação em outros países.
          </p>
          <p>
            Ao ser redirecionado para um aplicativo ou site de terceiros, você não será mais regido por essa Política de
            Privacidade ou pelos Termos de Serviço da nossa plataforma. Não somos responsáveis pelas práticas de
            privacidade de outros sites e lhe incentivamos a ler as declarações de privacidade deles.
          </p>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 07 – COOKIES OU DADOS DE NAVEGAÇÃO</ParagraphTitle>
        <Paragraph>
          <p>
            Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e visitante e
            que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são
            relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário
            e visitante para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços
            da plataforma.
          </p>
          <p>
            O usuário e o visitante do <strong>APLICATIVO 91</strong> manifesta conhecer e aceitar que pode ser
            utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.
          </p>
          <p>
            O cookie persistente permanece no disco rígido do usuário e visitante depois que o navegador é fechado e
            será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos
            seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o
            navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns
            recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver
            desabilitada.
          </p>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 08 – CONSENTIMENTO</ParagraphTitle>
        <Paragraph>
          <p>
            Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentindo com a
            presente Política de Privacidade.
          </p>
          <p>
            O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro,
            acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.
          </p>
          <p>
            O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato
            através do e-mail contato@91.digital ou por correio enviado ao seguinte endereço:{' '}
            <strong>Rua Pinheiro Machado</strong>, nº
            <strong>1417 Sala 08</strong>, Bairro <strong>Nossa Senhora de Lourdes</strong>, na Cidade de Caxias do
            Sul/RS, CEP <strong>95020-170</strong>.
          </p>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 09 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</ParagraphTitle>
        <Paragraph>
          <p>
            Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que
            o usuário e visitante revise-a com frequência.
          </p>
          <p>
            As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma. Quando
            realizadas alterações os usuários serão notificados. Ao utilizar o serviço ou fornecer informações pessoais
            após eventuais modificações, o usuário e visitante demonstra sua concordância com as novas normas.
          </p>
          <p>
            Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser transferidos para os
            novos proprietários para que a permanência dos serviços oferecidos.
          </p>
        </Paragraph>

        <ParagraphTitle>SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</ParagraphTitle>
        <Paragraph>
          <p>
            Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito
            brasileiro.
          </p>
          <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa.</p>
        </Paragraph>
      </DocumentContainer>
    </PageWrapper>
  );
}
