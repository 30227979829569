import ContactSupport from '@mui/icons-material/ContactSupport';
import GavelIcon from '@mui/icons-material/Gavel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ROUTES } from '../routes/routesList';

export const ADDITIONAL_OPTIONS_MENU_ITEMS = [
  {
    id: '1',
    label: 'Política de Privacidade',
    icon: <VisibilityIcon />,
    link: ROUTES.options.privacyPolicy,
  },
  {
    id: '2',
    label: 'Termos de Uso',
    icon: <GavelIcon />,
    link: ROUTES.options.termsOfUse,
  },
  {
    id: '3',
    label: 'Dúvidas Frequentes',
    icon: <HelpOutlineIcon />,
    link: ROUTES.options.frequentlyAskedQuestions,
  },
  {
    id: '4',
    label: 'Ajuda',
    icon: <ContactSupport />,
    link: ROUTES.options.help,
  },
  {
    id: '5',
    label: 'Sobre a 91',
    icon: <InfoIcon />,
    link: ROUTES.options.about91,
  },
];
