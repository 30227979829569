import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Button, Grid, Typography } from '@mui/material';
// hooks
// components
import Logo from '../../../components/logo';
// sections
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../routes/routesList';
import { AuthService } from '../../../services/Auth.service';

export default function InvitationAcceptPage() {
  const { invitationCode } = useParams();
  const navigate = useNavigate();
  const [invitationName, setInvitationName] = useState(null);
  const [fetchError, setFetchError] = useState(false);

  const handleSignUpNavigation = () => {
    navigate(`${ROUTES.public.registration}?convite=${invitationCode}`, { replace: true });
  };

  const getInvitation = async () => {
    try {
      const response = await AuthService.invitationCode(invitationCode);
      setInvitationName(response?.data.result);
    } catch (error: any) {
      setFetchError(true);
    }
  };

  useEffect(() => {
    getInvitation();
  });

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
      }}
    >
      <Helmet>
        <title> Convite | 91 Seguros </title>
      </Helmet>

      <Logo
        disabledLink={true}
        sx={{
          mx: 'auto',
          marginTop: 5,
          width: '100px',
          height: '100px',
          mb: 5,
        }}
      />

      <Grid container sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          {invitationName && (
            <Box>
              <Typography variant='h4'>
                {invitationName} está te convidando para a <br /> 91 Seguros
              </Typography>
              <Typography variant='h6' sx={{ pt: 3 }}>
                O que é a 91 Seguros?
              </Typography>
              <Typography variant='p' sx={{ maxWidth: '80%' }}>
                Somos uma corretora de seguros 100% digital, onde além de encontrar os melhores preços, você ainda pode
                indicar seus amigos e receber até <b>91%</b> do valor do seus seguros de volta.
              </Typography>

              <Grid item xs={12} sx={{ py: 3 }}>
                <Button onClick={handleSignUpNavigation} variant='outlined' sx={{ width: '100%', height: '66px' }}>
                  Criar minha conta
                </Button>
              </Grid>
            </Box>
          )}

          {fetchError && (
            <Box>
              <Typography variant='h4'>Convite não encontrado.</Typography>

              <Typography component='p'>Verifique o link do convite que você recebeu e tente novamente.</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
