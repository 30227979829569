import DashboardLayout from '../layouts/dashboard/DashboardLayout';
import { DashboardAppPage } from '../pages/DashboardApp/DashboardAppPage';
import InsurancesPage from '../pages/Insurances/InsurancesPage';
import { NewHomeInsurancePage } from '../pages/NewHomeInsurance/NewHomeInsurancePage';
import NewInsurancesPage from '../pages/NewInsurance/NewInsurancePage';
import NewInternationalTravelInsurancePageWrapper from '../pages/NewTravelInsurance/NewInternationalTravelInsurancePageWrapper';
import NewNationalTravelInsurancePageWrapper from '../pages/NewTravelInsurance/NewNationalTravelInsurancePageWrapper';
import { NewTravelInsurancePage } from '../pages/NewTravelInsurance/NewTravelInsurancePage';
import OptionsPage from '../pages/Options/OptionsPage';
import AboutPage from '../pages/Options/OptionsPages/AboutPage';
import FrequenltyAskedPage from '../pages/Options/OptionsPages/FrequenltyAskedPage';
import HelpPage from '../pages/Options/OptionsPages/HelpPage';
import PrivacyPolicyPage from '../pages/Options/OptionsPages/PrivacyPolicyPage';
import TermsOfUsePage from '../pages/Options/OptionsPages/TermsOfUsePage';
import ReferralsPage from '../pages/Referrals/ReferralsPage';
import StatementPage from '../pages/Statement/StatementPage';
import UserPage from '../pages/User/UserPage';
import { ROUTES } from './routesList';

export const MainRoutes = [
  {
    path: ROUTES.dashboard,
    element: <DashboardLayout />,
    children: [
      {
        path: ROUTES.menuItems.app,
        element: <DashboardAppPage />,
        index: true,
      },
      {
        path: ROUTES.accountMenu.profile,
        element: <UserPage />,
      },
      {
        path: ROUTES.menuItems.insurances,
        element: <InsurancesPage />,
      },
      {
        path: ROUTES.menuItems.statement,
        element: <StatementPage />,
      },
      {
        path: ROUTES.menuItems.newInsurance,
        element: <NewInsurancesPage />,
      },
      {
        path: ROUTES.menuItems.referrals,
        element: <ReferralsPage />,
      },
      {
        path: ROUTES.menuItems.options,
        element: <OptionsPage />,
      },
      {
        path: ROUTES.options.frequentlyAskedQuestions,
        element: <FrequenltyAskedPage />,
      },
      {
        path: ROUTES.options.termsOfUse,
        element: <TermsOfUsePage />,
      },
      {
        path: ROUTES.options.privacyPolicy,
        element: <PrivacyPolicyPage />,
      },
      {
        path: ROUTES.options.help,
        element: <HelpPage />,
      },
      {
        path: ROUTES.options.about91,
        element: <AboutPage />,
      },
      {
        path: ROUTES.forms.newTravelInsurance,
        element: <NewInternationalTravelInsurancePageWrapper />,
      },
      {
        path: ROUTES.forms.newTravelInsuranceId,
        element: <NewTravelInsurancePage />,
      },
      {
        path: ROUTES.forms.newBrazilTravelInsurance,
        element: <NewNationalTravelInsurancePageWrapper />,
      },
      {
        path: ROUTES.forms.newHomeInsurance,
        element: <NewHomeInsurancePage />,
      },
      {
        path: ROUTES.forms.newHomeInsuranceId,
        element: <NewHomeInsurancePage />,
      },
    ],
  },
];
