import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserDocsFormFields, useUserContext } from '../../context/UserContextProvider';
import { FormState } from '../../models/FormState';
import { setInputErrorsFromApi } from '../../utils/forms/form-errors';
import { validateCpf } from '../../utils/methods';
import { TextInput } from '../basics/TextInput/TextInput';

interface Props {
  open: boolean;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  requireCpf?: boolean;
  requireRg?: boolean;
}

export const DocumentsDialogComponent = ({
  open,
  title = 'Antes de continuar',
  description = 'Precisamos que você informe alguns dados',
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  onCancel,
  onConfirm,
  requireCpf = true,
  requireRg = false,
}: Props) => {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { user, updateUserDocuments } = useUserContext();

  useEffect(() => {
    reset({
      first_name: user?.first_name,
      last_name: user?.last_name,
      cpf: user?.client?.cpf,
      rg: '',
      dataExpedicao: '',
      orgaoExpedidor: '',
    });
  }, [user]);

  const [formState, setFormState] = useState<FormState>({
    severity: 'info',
    message: '',
  });

  const {
    control,
    setError,
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm<UserDocsFormFields>({
    mode: 'onChange',
    defaultValues: {
      first_name: user?.last_name,
      last_name: user?.last_name,
      cpf: '',
      rg: '',
      dataExpedicao: '',
      orgaoExpedidor: '',
    },
  });

  const formValidations = (data: UserDocsFormFields) => {
    if (!data.first_name) {
      setError('first_name', { message: 'Nome é obrigatório' });
      return false;
    }
    if (!data.last_name) {
      setError('last_name', { message: 'Sobrenome é obrigatório' });
      return false;
    }
    if (!data.cpf) {
      setError('cpf', { message: 'CPF é obrigatório' });
      return false;
    }
    // validate cpf data
    if (!validateCpf(data.cpf)) {
      setError('cpf', { message: 'CPF inválido' });
      return false;
    }
    return true;
  };

  const onSubmit = (data: UserDocsFormFields) => {
    if (!formValidations(data)) return;
    try {
      updateUserDocuments(data);
    } catch (error: any) {
      let formError = setInputErrorsFromApi(error, setError);
      setFormState({
        severity: 'error',
        message: formError,
      });
    }

    onConfirm();
  };

  return (
    <form>
      <Dialog open={open} fullScreen={ismobile}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: 0.5 }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {formState.message && (
                <Alert severity={formState.severity} sx={{ mb: 3 }}>
                  {formState.message}
                </Alert>
              )}
              {description && <Typography variant='body1'>{description}</Typography>}
              <Stack spacing={3} mt={3} pb={3}>
                <TextInput
                  control={control}
                  label='Nome'
                  placeholder='Nome'
                  fullWidth
                  required
                  type='text'
                  name='first_name'
                  variant='standard'
                  errorMessage={errors.first_name?.message?.toString()}
                  sx={{ display: user?.first_name ? 'none' : 'block' }}
                />

                <TextInput
                  control={control}
                  label='Sobrenome'
                  placeholder='Sobrenome'
                  fullWidth
                  required
                  type='text'
                  name='last_name'
                  variant='standard'
                  errorMessage={errors.last_name?.message?.toString()}
                  sx={{ display: user?.last_name ? 'none' : 'block' }}
                />
                {requireCpf && (
                  <TextInput
                    control={control}
                    label='CPF'
                    placeholder='000.000.000-00'
                    mask='999.999.999-99'
                    fullWidth
                    required
                    type='tel'
                    name='cpf'
                    variant='standard'
                    errorMessage={errors.cpf?.message?.toString()}
                  />
                )}
              </Stack>
              <Stack direction='row' spacing={2} justifyContent='flex-end' mt={3}>
                {onCancel && (
                  <Button
                    fullWidth={!!onConfirm || ismobile}
                    variant='outlined'
                    onClick={onCancel}
                    sx={{ textTransform: 'none' }}
                  >
                    {cancelText}
                  </Button>
                )}
                <Button
                  type='submit'
                  fullWidth={!!onCancel || ismobile}
                  variant='contained'
                  onClick={handleSubmit(onSubmit)}
                  sx={{ textTransform: 'none' }}
                >
                  {confirmText}
                </Button>
              </Stack>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
};
