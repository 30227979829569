import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogComponent } from '../../components/basics/DialogComponent/DialogComponent';
import { PageWrapper } from '../../components/basics/PageWrapper/PageWrapper';
import { useAppContext } from '../../context/GlobalContextProvider';
import { useUserContext } from '../../context/UserContextProvider';
import { TravelInsuranceContextProvider } from '../../context/insurances/TravelInsuranceContextProvider';
import { TravelInsurance } from '../../models/TravelInsurance';
import { ROUTES } from '../../routes/routesList';
import { ActiveTravelInsuranceDetails } from '../../sections/@travelInsurance/ActiveTravelInsuranceDetails/ActiveTravelInsuranceDetails';
import { TravelInsuranceStepper } from '../../sections/@travelInsurance/Stepper/TravelInsuranceStepper';
import { TravelInsuranceService } from '../../services/TravelInsurance.service';
import { checkStatus } from '../../utils/api/response';

type NewTravelInsurancePageProps = {
  type?: 'national' | 'international';
};

export const NewTravelInsurancePage = ({ type }: NewTravelInsurancePageProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const { showGlobalSpinner, hideGlobalSpinner, loading, showSnackbarMessage } = useAppContext();

  const { user } = useUserContext();
  const QUOTATION_PLATFORM = user?.platform == 'web' ? 'web' : 'app';

  const [showTravelInsuranceModal, setShowTravelInsuranceModal] = useState<boolean>(false);
  const [currentTravelInsurance, setCurrentTravelInsurance] = useState<TravelInsurance>();

  // The insuranceType is the destiny of the currentTravelInsurance or the type prop
  const insuranceType =
    params.id && currentTravelInsurance?.destiny
      ? currentTravelInsurance?.destiny === 'within_my_country'
        ? 'national'
        : 'international'
      : type;

  const handleClose = () => {
    navigate('/' + ROUTES.menuItems.newInsurance);
  };

  const handleNewTravelInsurance = async () => {
    //Criar um novo seguro de viagem
    setShowTravelInsuranceModal(false);

    try {
      showGlobalSpinner({
        message: 'Criando seguro de viagem...',
      });

      const response = await TravelInsuranceService.createTravelInsurance(
        insuranceType === 'national' ? 'within_my_country' : null,
        QUOTATION_PLATFORM
      );

      if (checkStatus(response)) {
        setCurrentTravelInsurance(response?.data?.result);

        //setar na URL o id do seguro de viagem
        window.history.replaceState(null, '', `${ROUTES.forms.newTravelInsurance}/${response?.data?.result.id}`);
      }
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao criar seguro de viagem',
        severity: 'error',
      });
    }

    hideGlobalSpinner();
  };

  const handleContinueTravelInsurance = () => {
    setShowTravelInsuranceModal(false);
    if (currentTravelInsurance) {
      setCurrentTravelInsurance(currentTravelInsurance);
      //setar na URL o id do seguro de viagem
      window.history.replaceState(null, '', `${ROUTES.forms.newTravelInsurance}/${currentTravelInsurance.id}`);
    }
  };

  const fetchTravelInsuranceById = async () => {
    // ENDPOINT PENDENTE DE DESENVOLVIMENTO
    //TravelInsuranceService.getTravelInsurancesById();

    //Alternativamente farei um filtro pelo ID do seguro de viagem
    showGlobalSpinner({
      message: 'Aguarde um momento...',
    });

    try {
      const response = await TravelInsuranceService.getTravelInsurances();

      if (response?.data?.result?.length > 0) {
        setCurrentTravelInsurance(
          response?.data?.result?.find((travelInsurance: any) => travelInsurance.id === params?.id)
        );
      }
    } catch (error: any) {
      console.log('Erro ao buscar seguro de viagem por ID', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  const fetchTravelInsurances = async () => {
    try {
      showGlobalSpinner({
        message: 'Aguarde um momento...',
      });

      const response = await TravelInsuranceService.getTravelInsurances();
      let notFinishedTravelInsurances = response?.data.result.filter(
        (item: TravelInsurance) => item.status === 'started'
      );

      // Filter by type locally
      if (insuranceType == 'national') {
        notFinishedTravelInsurances = notFinishedTravelInsurances.filter(
          (item: TravelInsurance) => item.destiny === 'within_my_country'
        );
      } else {
        notFinishedTravelInsurances = notFinishedTravelInsurances.filter(
          (item: TravelInsurance) => item.destiny !== 'within_my_country'
        );
      }

      if (notFinishedTravelInsurances.length > 0) {
        setShowTravelInsuranceModal(true);
        setCurrentTravelInsurance(notFinishedTravelInsurances[notFinishedTravelInsurances.length - 1]);
        //setar na URL o id do seguro de viagem
      } else {
        await handleNewTravelInsurance();
      }
    } catch (error: any) {
      console.log('Erro ao buscar seguros de viagem', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    //se vier um id fazer o fetch do seguro viagem pelo ID
    if (params?.id) {
      fetchTravelInsuranceById();
    } else {
      //se não vier um id, buscar todos os seguros viagem do usuário e verificar se existe algum em andamento.
      fetchTravelInsurances();
    }
  }, [params]);

  return (
    <TravelInsuranceContextProvider>
      {!showTravelInsuranceModal && currentTravelInsurance?.id && (
        <PageWrapper
          pageTitle={insuranceType === 'national' ? 'Seguro Viagem Nacional' : 'Seguro Viagem Internacional'}
          onClose={handleClose}
        >
          {currentTravelInsurance.status == 'started' ? (
            <TravelInsuranceStepper currentTravelInsurance={currentTravelInsurance} />
          ) : (
            <ActiveTravelInsuranceDetails currentTravelInsurance={currentTravelInsurance} />
          )}
        </PageWrapper>
      )}
      <DialogComponent
        open={showTravelInsuranceModal}
        title={insuranceType === 'national' ? 'Seguro Viagem Nacional' : 'Seguro Viagem Internacional'}
        description='Você possui um seguro de viagem em andamento. Deseja continuar ou criar um novo?'
        confirmText='Continuar'
        cancelText='Novo'
        confirmIcon={<CheckIcon />}
        cancelIcon={<AddIcon />}
        onDismiss={() => {}}
        onCancel={handleNewTravelInsurance}
        onConfirm={handleContinueTravelInsurance}
      />
    </TravelInsuranceContextProvider>
  );
};
