import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { Box, Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/routesList';

export const NoneInsuranceFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Card sx={{ p: 0 }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* <img
            src='/path-to-your-image/insurance_image.png'
            alt='Insurance Illustration'
            width='150' // Ajuste o tamanho da imagem conforme necessário
            height='150' // Ajuste o tamanho da imagem conforme necessário
          /> */}
          <Typography variant='h6' sx={{ color: 'text.secondary', textAlign: 'center', mt: 2 }}>
            Você ainda não possui nenhum seguro.
          </Typography>
          <Typography variant='body1' sx={{ color: 'text.secondary', textAlign: 'center', mt: 2 }}>
            Comece a proteger o que é importante para você hoje mesmo.
          </Typography>
          <Button
            variant='contained'
            onClick={() => navigate(ROUTES.menuItems.newInsurance)}
            sx={{
              textTransform: 'none',
              mt: 3,
              padding: '12px 24px',
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            }}
            size='large'
            startIcon={<AddModeratorIcon />} // Adicione um ícone, substituindo 'InsuranceIcon' pelo ícone que deseja usar
          >
            Fazer cotação sem compromisso
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
