import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, Theme, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';

interface Props {
  open: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  confirmIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  onDismiss: () => void;
  children?: React.ReactNode;
}

export const DialogComponent = ({
  open,
  title,
  description,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  confirmIcon = <CheckIcon />,
  cancelIcon = <CloseIcon />,
  onDismiss,
  onCancel,
  onConfirm,
  children,
}: Props) => {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <form>
      <Dialog open={open} onClose={onDismiss} fullScreen={ismobile}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: 0.5 }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {description && <Typography variant='body1'>{description}</Typography>}
              <Box mt={1}>{children}</Box>
              <Stack direction='row' spacing={2} justifyContent='flex-end' mt={3}>
                {onCancel && (
                  <Button
                    startIcon={cancelIcon}
                    fullWidth={!!onConfirm || ismobile}
                    variant='outlined'
                    onClick={onCancel}
                    sx={{ textTransform: 'none' }}
                  >
                    {cancelText}
                  </Button>
                )}
                {onConfirm && (
                  <Button
                    type='submit'
                    startIcon={confirmIcon}
                    fullWidth={!!onCancel || ismobile}
                    variant='contained'
                    onClick={onConfirm}
                    sx={{ textTransform: 'none' }}
                  >
                    {confirmText}
                  </Button>
                )}
              </Stack>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
};
