import { Grid, Typography } from '@mui/material';
import { PasswordStrength } from '../../../sections/@auth/RegisterForm';

interface Props {
  passwordStreght: PasswordStrength;
}

export const PasswordStrengthComponent = ({ passwordStreght }: Props) => {
  let passwordLevel = passwordStreght.level + 1;
  const levelCount = [1, 2, 3, 4, 5, 6].slice(0, passwordLevel);

  const _returnGridItem = (index: number) => (
    <Grid
      key={index}
      item
      xs={2}
      sx={[
        { bgcolor: passwordStreght?.color, height: 8 },
        index == 0 ? { borderTopLeftRadius: 8, borderBottomLeftRadius: 8 } : null,
        index == 5 ? { borderTopRightRadius: 8, borderBottomRightRadius: 8 } : null,
      ]}
    />
  );

  return (
    <>
      <Grid container sx={{ alignItems: 'center' }} px={0.5}>
        {levelCount.map((item: any, index) => _returnGridItem(index))}
      </Grid>
      <Typography variant="subtitle2" textAlign="center">
        {passwordStreght?.label}
      </Typography>
    </>
  );
};
