import { Container, List, ListItemButton, ListItemIcon, ListItemText, Theme, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { ADDITIONAL_OPTIONS_MENU_ITEMS } from '../../constants/AdditionalOptionsMenuItems';
import { PageTitle } from '../../theme/GlobalStyledComponents';

export default function OptionsPage() {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const navigate = useNavigate();
  const menuItems = ADDITIONAL_OPTIONS_MENU_ITEMS;

  const handleClick = (route: string) => {
    navigate('/' + route);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Mais Opções </title>
      </Helmet>

      <Container>
        <PageTitle isMobile={ismobile}>Mais Opções</PageTitle>
        <List
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          component='nav'
        >
          {menuItems.map((item, index: number) => (
            <ListItemButton onClick={() => handleClick(item.link)} key={index}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
      </Container>
    </>
  );
}
