import { yupResolver } from '@hookform/resolvers/yup';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/GlobalContextProvider';
import { useLogServiceHook } from '../../../../hooks/useLogServiceHook';
import { TravelInsuranceQuotation } from '../../../../models/TravelInsuranceQuotation';
import { TravelInsuranceService } from '../../../../services/TravelInsurance.service';
import { checkStatus } from '../../../../utils/api/response';
import { TravelDatesSchema } from '../../../../utils/forms/auth/formValidationSchemas';
import { formatDateToApi, getFormatedDate } from '../../../../utils/methods';
import { DatesComponent } from './DatesComponent';
import { DestinyComponent } from './DestinyComponent';
import { DownloadComponent } from './DownloadComponent';
import { PassengersComponent } from './PassengersComponent';
import { QuotationComponent } from './QuotationComponent';

const selectOptions = [
  {
    key: 'europe',
    value: 'Europa',
  },
  {
    key: 'north_america',
    value: 'América do Norte',
  },
  {
    key: 'caribbean_mexico',
    value: 'México e Caribe',
  },
  {
    key: 'south_america',
    value: 'América do Sul',
  },
  {
    key: 'africa',
    value: 'África',
  },
  {
    key: 'asia',
    value: 'Ásia',
  },
  {
    key: 'oceania',
    value: 'Oceania',
  },
  {
    key: 'middle_east',
    value: 'Oriente Médio',
  },
];

export const TravelInsuranceQuotationIframe = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { logUser } = useLogServiceHook();

  const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();

  const [destinySelected, setDestinySelected] = useState('');
  const [passengerAges, setPassengerAges] = useState([''] as any);
  const [currentStep, setCurrentStep] = useState(0);
  const [activeTab, setActiveTab] = useState('0');
  const [travelInsuranceQuotation, setTravelInsuranceQuotation] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      destiny: '',
      start_date: '',
      end_date: '',
      passengers: [] as any,
    },
    resolver: yupResolver(TravelDatesSchema),
  });

  const handleInsuranceQuoting = async () => {
    const quotationParams = {
      destiny: destinySelected,
      start_date: formatDateToApi(getValues().start_date),
      end_date: formatDateToApi(getValues().end_date),
      passengers: passengerAges.map((birthDate: string) => {
        return formatDateToApi(birthDate);
      }),
    };
    await doQuote(quotationParams);
  };

  const logInsuranceQuotation = async (quotations: TravelInsuranceQuotation[]) => {
    await logUser({
      url: 'cotacao-seguro-viagem/insurance_quotation',
      log_data: { prices: quotations.map((quotation) => quotation.total_price) },
    });
  };

  const doQuote = async (quotationParams: any) => {
    showGlobalSpinner({
      message: 'Aguarde um momento...',
    });

    // save the quotationParams in the url hashed so we can un-hash it later
    const hash = window.btoa(JSON.stringify(quotationParams));
    // change the query string hash param using react router with replace so we don't create a new history entry
    setSearchParams(
      (prev) => {
        prev.set('quote', hash);
        return prev;
      },
      { replace: true }
    );
    try {
      for (let i = 0; i < 5; i++) {
        const quotesResponse = await TravelInsuranceService.getPublicInsuranceQuote(quotationParams);
        if (quotesResponse && checkStatus(quotesResponse)) {
          logInsuranceQuotation(quotesResponse.data?.result);
          setTravelInsuranceQuotation(quotesResponse.data?.result);
          setCurrentStep(3);
          break;
        }
      }
    } catch (error: any) {
      console.log('Erro ao cotar seguro de viagem', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    // if the url has a hash param, it means the user is coming from the main website with a quotation already selected
    // so we need to get the quotation params from the hash and set the values in the form

    const hash = searchParams.get('quote');
    if (hash) {
      const searchStringParams = JSON.parse(window.atob(hash));
      const quotationParams = {
        destiny: searchStringParams.destiny,
        start_date: searchStringParams.start_date,
        end_date: searchStringParams.end_date,
        passengers: searchStringParams.passengers,
      };
      setDestinySelected(quotationParams.destiny);
      setValue('destiny', quotationParams.destiny);
      setValue('start_date', getFormatedDate(quotationParams.start_date));
      setValue('end_date', getFormatedDate(quotationParams.end_date));
      setPassengerAges(quotationParams.passengers);

      doQuote(quotationParams);
    }
  }, []);

  return (
    <Box id='main-quotation-tag' sx={{ display: 'flex', flex: 1 }} minHeight='80px'>
      {currentStep === 0 && (
        <DestinyComponent
          selectOptions={selectOptions}
          destinySelected={destinySelected}
          setDestinySelected={setDestinySelected}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 1 && (
        <DatesComponent errors={errors} setCurrentStep={setCurrentStep} getValues={getValues} setValue={setValue} />
      )}
      {currentStep === 2 && (
        <PassengersComponent
          control={control}
          getValues={getValues}
          setCurrentStep={setCurrentStep}
          passengerAges={passengerAges}
          setPassengerAges={setPassengerAges}
          handleInsuranceQuoting={handleInsuranceQuoting}
        />
      )}
      {currentStep === 3 && (
        <QuotationComponent
          travelInsuranceQuotations={travelInsuranceQuotation}
          setCurrentStep={setCurrentStep}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      {currentStep === 4 && <DownloadComponent />}
    </Box>
  );
};
