import { createContext, useContext, useState } from 'react';

interface UserTabsContextProps {
  tab: string;
  setTab: (value: string) => void;
}

const UserTabsContext = createContext<UserTabsContextProps>({} as UserTabsContextProps);

interface Props {
  children: React.ReactNode;
}

export const UserTabsProvider = ({ children }: Props) => {
  const [tab, setTab] = useState('1');

  return (
    <UserTabsContext.Provider
      value={{
        tab,
        setTab,
      }}
    >
      {children}
    </UserTabsContext.Provider>
  );
};

export const useTabContext = () => useContext(UserTabsContext);
