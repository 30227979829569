import createAxiosInstance from '../middleware/axios.interceptors';
import { HomeInsurance } from '../models/HomeInsurance';

const baseUrl = '/insurance/home-insurance';

export const HomeInsuranceService = {
  getHomeInsurances: async (page: number = 0) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${baseUrl}/`);
    } catch (err: any) {
      throw err.response;
    }
  },

  createHomeInsurance: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/`);
    } catch (err: any) {
      throw err.response;
    }
  },

  updateHomeInsurance: async (homeInsuranceId: string, data: HomeInsurance) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(`${baseUrl}/${homeInsuranceId}`, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  getInsuranceQuote: async (homeInsuranceId: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/${homeInsuranceId}/get-quotes`);
    } catch (err: any) {
      throw err.response;
    }
  },

  insuranceHire: async (homeInsuranceId: string, paymentData: any) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/${homeInsuranceId}/hire`, paymentData);
    } catch (err: any) {
      throw err.response;
    }
  },
};
