import { Avatar, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { fToNow } from '../../../utils/formatTime';

export const ReferralItem = ({ row }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <Box sx={{ display: 'flex', pb: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <Avatar alt={row.client.first_name} src={row.client.profile_picture || '/non-existent'} />
        <Box sx={{ ml: isMobile ? 2 : 2 }}>
          <Typography component='p' sx={{ fontWeight: 'bold' }}>
            {`${row.client.first_name} ${row.client.last_name}`}
          </Typography>
          <Typography variant='body2'>{fToNow(row.client.account_activated_at)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
