export const ROUTES = {
  public: {
    login: '/entrar',
    googleCallback: '/google-callback',
    registration: '/cadastro',
    invitationAccept: '/convite/:invitationCode',
    accountConfirmEmail: '/confirmacao-conta/:token',
    accountChangePassword: '/nova-senha/:token',
    iframeTravelInsuranceQuotation: '/cotacao-seguro-viagem',
    marketingCampaign: '/campanha-de-marketing',
  },
  dashboard: '/',
  accountMenu: {
    profile: 'perfil',
    changePassword: 'alterar-senha',
    settings: 'configuracoes',
    logout: 'sair',
  },
  menuItems: {
    app: 'app',
    user: 'usuario',
    products: 'produtos',
    blog: 'blog',
    insurances: 'seguros',
    statement: 'extrato',
    newInsurance: 'novo-seguro',
    referrals: 'indicacoes',
    options: 'opcoes',
  },
  forms: {
    newBrazilTravelInsurance: 'novo-seguro-viagem-brasil',
    newTravelInsurance: 'novo-seguro-viagem',
    newTravelInsuranceId: 'novo-seguro-viagem/:id',
    newHomeInsurance: 'novo-seguro-residencia',
    newHomeInsuranceId: 'novo-seguro-residencia/:id',
    newPetInsurance: 'novo-seguro-pet',
    newPetInsuranceId: 'novo-seguro-pet/:id',
    newCarInsurance: 'novo-seguro-auto',
    newCarInsuranceId: 'novo-seguro-auto/:id',
    newLifeInsurance: 'novo-seguro-vida',
    newLifeInsuranceId: 'novo-seguro-vida/:id',
    newBikeInsurance: 'novo-seguro-moto',
    newBikeInsuranceId: 'novo-seguro-moto/:id',
    newMobileInsurance: 'novo-seguro-dispositivos',
    newMobileInsuranceId: 'novo-seguro-dispositivos/:id',
  },
  options: {
    termsOfUse: 'termos-de-uso',
    privacyPolicy: 'politica-de-privacidade',
    about91: 'sobre-a-91',
    frequentlyAskedQuestions: 'perguntas-frequentes',
    help: 'ajuda',
  },
  notFound: '/404',
};
