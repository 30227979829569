// layouts
//
import { TravelInsuranceMarketingCampaign } from '../pages/Public/TravelInsuranceMarketingCampaign/TravelInsuranceMarketingCampaign';
import { PublicTravelInsuranceQuotation } from '../pages/Public/TravelInsuranceQuotationPage/PublicTravelInsuranceQuotation';
import { ROUTES } from './routesList';

// ----------------------------------------------------------------------

export const PublicRoutes = [
  {
    path: ROUTES.public.iframeTravelInsuranceQuotation,
    element: <PublicTravelInsuranceQuotation />,
  },
  {
    path: ROUTES.public.marketingCampaign,
    element: <TravelInsuranceMarketingCampaign />,
  },
];
