import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { HomeInsurance } from '../../models/HomeInsurance';
import { HomeInsuranceQuotation } from '../../models/HomeInsuranceQuotation';
import { HomeInsuranceService } from '../../services/HomeInsurance.service';
import { checkStatus } from '../../utils/api/response';

interface IHomeInsuranceStepper {
  homeInsuranceData: HomeInsurance;
  setHomeInsuranceData: (data: HomeInsurance) => void;
  updateHomeInsuranceData: (data: HomeInsurance) => void;
  clearHomeInsuranceData: () => void;
  setHomeInsuranceQuotation: (data: any) => void;
  homeInsuranceQuotation: HomeInsuranceQuotation[];
  setSelectedQuotation: (data: HomeInsuranceQuotation | null) => void;
  selectedQuotation: HomeInsuranceQuotation | null;
}

interface Props {
  children: ReactNode;
}

const HomeInsuranceContext = createContext<IHomeInsuranceStepper>({} as IHomeInsuranceStepper);

export const HomeInsuranceContextProvider = ({ children }: Props) => {
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

  const [selectedQuotation, setSelectedQuotation] = useState<HomeInsuranceQuotation | null>(null);
  const [homeInsuranceData, setHomeInsuranceData] = useState<HomeInsurance>({} as HomeInsurance);
  const [homeInsuranceQuotation, setHomeInsuranceQuotation] = useState<HomeInsuranceQuotation[]>(
    [] as HomeInsuranceQuotation[]
  );

  const updateHomeInsuranceData = (data: HomeInsurance) => {
    setHomeInsuranceData({ ...homeInsuranceData, ...data });
    setShouldUpdate(true);
  };

  const clearHomeInsuranceData = () => {
    setHomeInsuranceData({} as HomeInsurance);
  };

  const updateApiInformation = async () => {
    try {
      if (homeInsuranceData.id) {
        const response = await HomeInsuranceService.updateHomeInsurance(homeInsuranceData.id, homeInsuranceData);
        if (checkStatus(response)) {
          setHomeInsuranceData({
            ...response?.data.result,
            address: response?.data?.address?.id,
          });
        }
        setShouldUpdate(false);
      }
    } catch (error: any) {
      console.log('Erro ao atualizar seguro residencial', error);
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      updateApiInformation();
    }
  }, [shouldUpdate]);

  return (
    <HomeInsuranceContext.Provider
      value={{
        homeInsuranceData,
        setHomeInsuranceData,
        updateHomeInsuranceData,
        clearHomeInsuranceData,
        setHomeInsuranceQuotation,
        homeInsuranceQuotation,
        setSelectedQuotation,
        selectedQuotation,
      }}
    >
      {children}
    </HomeInsuranceContext.Provider>
  );
};

export const useHomeInsuranceContext = () => useContext(HomeInsuranceContext);
