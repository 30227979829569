import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HelpDialogComponent } from '../../components/basics/HelpDialogComponent/HelpDialogComponent';
import { ReferralLink } from '../../sections/@dashboard/app/ReferralLink';
import { NoReferrals } from '../../sections/@referrals/NoReferrals/NoReferrals';
import { ReferralItem } from '../../sections/@referrals/ReferralItem/ReferralItem';
import { ReferralsService } from '../../services/Referral.service';
import { ContainerRowCentered, PageTitle } from '../../theme/GlobalStyledComponents';
import { checkStatus } from '../../utils/api/response';

export default function ReferralsPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const [referralList, setReferralList] = useState([]);
  const [helpDialog, setHelpDialog] = useState(false);

  const fetchReferrals = async () => {
    try {
      const response = await ReferralsService.getReferrals();
      if (checkStatus(response)) {
        setReferralList(response?.data.result);
      }
    } catch (error: any) {
      console.log('Erro ao buscar indicações', error);
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  const handleClickHelp = () => {
    setHelpDialog(!helpDialog);
  };

  const cardTitle = (
    <ContainerRowCentered>
      <Typography variant='h5'>Minhas Indicações</Typography>
      <IconButton onClick={handleClickHelp}>
        <HelpIcon color='primary' fontSize='small' />
      </IconButton>
    </ContainerRowCentered>
  );

  return (
    <>
      <Helmet>
        <title>Dashboard | Indicações</title>
      </Helmet>

      <Container>
        <PageTitle isMobile={isMobile}>Indicações</PageTitle>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader title={cardTitle} subheaderTypographyProps={{ variant: 'body2' }} />
              <CardContent>
                {referralList?.first_level?.length > 0 ? (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Card elevation={3}>
                        <CardContent>
                          <Typography variant='h6'>Nível 1</Typography>
                          <Divider />
                          <Box mt={2}>
                            {referralList?.first_level?.map((row, index) => (
                              <ReferralItem key={index} row={row} />
                            ))}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={4}>
                        <Grid item xs={6} md={3}>
                          <Typography variant='h6'>Nível 2</Typography>
                          <Divider />
                          <Typography variant='body1' mt={2}>
                            {referralList?.per_level_counts['2']} indicações
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant='h6'>Nível 3</Typography>
                          <Divider />
                          <Typography variant='body1' mt={2}>
                            {referralList?.per_level_counts['3']} indicações
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant='h6'>Nível 4</Typography>
                          <Divider />
                          <Typography variant='body1' mt={2}>
                            {referralList?.per_level_counts['4']} indicações
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography variant='h6'>Nível 5</Typography>
                          <Divider />
                          <Typography variant='body1' mt={2}>
                            {referralList?.per_level_counts['5']} indicações
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <NoReferrals />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
            <ReferralLink />
          </Grid>
        </Grid>
      </Container>

      <HelpDialogComponent
        open={helpDialog}
        cancelText='Fechar'
        onCancel={handleClickHelp}
        onDismiss={handleClickHelp}
        title='Programa de Indicações'
      >
        <Typography variant='h4' color='primary'>
          Bem-vindo ao Programa de Indicações!
        </Typography>

        <Typography variant='body1' gutterBottom>
          Parabéns por fazer parte da nossa comunidade. Queremos que você aproveite ao máximo nosso aplicativo e, ao
          mesmo tempo, ganhe comissões convidando amigos. Veja como funciona:
        </Typography>

        <Typography variant='h6' mt={3}>
          Nível 1: Amigos Diretos
        </Typography>
        <Box ml={2}>
          <Typography variant='body1' gutterBottom>
            No primeiro nível, você pode ver quem você indicou. E quando esses amigos se juntam, você ganha uma comissão
            generosa. Afinal, compartilhar é se importar!
          </Typography>
        </Box>

        <Typography variant='h6' mt={3}>
          Nível 2: Amigos dos Amigos
        </Typography>
        <Box ml={2}>
          <Typography variant='body1' gutterBottom>
            No segundo nível, você pode não saber quem são essas pessoas, mas você ainda ganha uma comissão. Espalhar a
            palavra é o que conta!
          </Typography>
        </Box>

        <Typography variant='h6' mt={3}>
          Nível 3: Amigos dos Amigos dos Amigos
        </Typography>
        <Box ml={2}>
          <Typography variant='body1' gutterBottom>
            Aqui, você só saberá quantas pessoas estão envolvidas, mas não quem são. Mas não se preocupe, sua comissão
            está a caminho!
          </Typography>
        </Box>

        <Typography variant='h6' mt={3}>
          Nível 4: A Grande Rede
        </Typography>
        <Box ml={2}>
          <Typography variant='body1' gutterBottom>
            No quarto nível, você verá apenas um número crescente de pessoas. Mas lembre-se, cada novo membro aumenta
            sua comissão. O poder da rede é incrível!
          </Typography>
        </Box>

        <Typography variant='h6' mt={3}>
          Nível 5: Expandindo Horizontes
        </Typography>
        <Box ml={2}>
          <Typography variant='body1' gutterBottom>
            Finalmente, no quinto nível, você só verá um número. Mas acredite, cada número é um novo mundo de
            oportunidades para ganhar comissões!
          </Typography>
        </Box>

        <Typography variant='body1' gutterBottom color='primary' mt={3}>
          Agora que você entende como funciona, vá lá e convide todos os seus amigos, familiares e até seus vizinhos!
          Quanto mais pessoas você trouxer, mais comissões você receberá. Divirta-se compartilhando e ganhando!
        </Typography>
      </HelpDialogComponent>
    </>
  );
}
