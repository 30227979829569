import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../../components/basics/PageWrapper/PageWrapper';
import { useMediaQueryContext } from '../../../context/MediaQueryContextProvider';
import { ROUTES } from '../../../routes/routesList';
import { DocumentContainer, Paragraph } from './styles';

export default function AboutPage() {
  const { ismobile } = useMediaQueryContext();
  const navigate = useNavigate();

  const handleClose = () => {
    //utiliza-se a barra para caminhos absolutos
    navigate('/' + ROUTES.menuItems.options);
  };

  return (
    <PageWrapper pageTitle='Sobre a 91' onClose={handleClose}>
      <Helmet>
        <title> Dashboard | Sobre a 91 </title>
      </Helmet>

      <DocumentContainer ismobile={ismobile}>
        <Paragraph ismobile={ismobile}>
          Sabemos que a tecnologia pode resolver diversos problemas no nosso dia a dia, e é por esse motivo que a 91
          surgiu. Somos inquietos e inconformados com os principais problemas de solicitação de seguros em nosso país.
          Por isso, somos a rede de seguros que tem o objetivo de oferecer segurança e facilidade, combinadas com um
          sistema exclusivo de descontos.
        </Paragraph>
        <Paragraph ismobile={ismobile}>
          Entregando um serviço diferenciado e de um grande custo benefício. Faça parte desse novo mundo de seguros, a
          rede que conecta a todos com apenas um propósito, agilidade e transparência. Modernizando e desburocratizando
          o mercado de seguros, transformando em um serviço que gera valor, agilidade e transparência.
        </Paragraph>
        <Paragraph ismobile={ismobile}>
          Como funciona:  A 91 é uma plataforma online de vendas de seguro, focada em oferecer segurança e facilidades
          combinadas a um sistema exclusivo de desconto. Entregamos um serviço diferenciado e de grande custo benefício.
          O usuário 91, tem benefícios e descontos exclusivos que conecta todos em um ecossistema inovador e seguro,
          podendo assim, chegar a ganhar um desconto de até 91%.
        </Paragraph>
      </DocumentContainer>
    </PageWrapper>
  );
}
