import { createContext, ReactNode, useContext, useState } from 'react';
import { TravelInsurance } from '../../models/TravelInsurance';
import { TravelInsuranceQuotation } from '../../models/TravelInsuranceQuotation';
import { TravelInsuranceService } from '../../services/TravelInsurance.service';
import { checkStatus } from '../../utils/api/response';

interface ITravelInsuranceStepper {
  travelInsuranceData: TravelInsurance;
  setTravelInsuranceData: (data: TravelInsurance) => void;
  updateTravelInsuranceData: (data: TravelInsurance) => void;
  clearTravelInsuranceData: () => void;
  chosenQuotation: TravelInsuranceQuotation;
  setChosenQuotation: (data: TravelInsuranceQuotation) => void;
  setTravelInsuranceQuotation: (data: any) => void;
  travelInsuranceQuotation: TravelInsuranceQuotation[];
}

interface Props {
  children: ReactNode;
}

const TravelInsuranceContext = createContext<ITravelInsuranceStepper>({} as ITravelInsuranceStepper);

export const TravelInsuranceContextProvider = ({ children }: Props) => {
  const [travelInsuranceQuotation, setTravelInsuranceQuotation] = useState<TravelInsuranceQuotation[]>([]);
  const [chosenQuotation, setChosenQuotation] = useState<TravelInsuranceQuotation>({} as TravelInsuranceQuotation);
  const [travelInsuranceData, setTravelInsuranceData] = useState<TravelInsurance>({} as TravelInsurance);

  const updateTravelInsuranceData = (data: TravelInsurance) => {
    updateApiInformation(data);
  };

  const clearTravelInsuranceData = () => {
    setTravelInsuranceData({} as TravelInsurance);
  };

  const updateApiInformation = async (data: TravelInsurance) => {
    try {
      if (travelInsuranceData.id) {
        const response = await TravelInsuranceService.updateTravelInsurance(travelInsuranceData.id, data);
        if (checkStatus(response)) {
          setTravelInsuranceData(response?.data.result);
        }
      }
    } catch (error: any) {
      console.log('Erro ao atualizar seguro de viagem', error);
    }
  };

  return (
    <TravelInsuranceContext.Provider
      value={{
        travelInsuranceData,
        setTravelInsuranceData,
        updateTravelInsuranceData,
        clearTravelInsuranceData,
        chosenQuotation,
        setChosenQuotation,
        setTravelInsuranceQuotation,
        travelInsuranceQuotation,
      }}
    >
      {children}
    </TravelInsuranceContext.Provider>
  );
};

export const useTravelInsuranceContext = () => useContext(TravelInsuranceContext);
