import {
  Box,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { formatCurrency } from '../../../../utils/methods';

interface Props {
  cashbackValues?: any;
  withdrawList?: any;
}

export const WithdrawHistoryTab = ({ withdrawList }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <>
      {withdrawList.length == 0 && (
        <>
          <Box textAlign='center'>
            <Typography variant='h6'>
              Nenhum extrato ainda. Seus valores de indicação e saques aparecerão aqui.
            </Typography>
          </Box>
          <Box textAlign='center' mt={2}>
            <Typography variant='body1'>
              Convide seus amigos para a 91 Seguros através do seu link de indicação, cashback em todos os seguros que
              eles contratarem conosco!
            </Typography>
          </Box>
        </>
      )}
      {withdrawList?.length > 0 && (
        <>
          <Card elevation={3} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                Histórico de saques
              </Typography>
              {isMobile && (
                <Typography variant='caption' gutterBottom>
                  Arraste para o lado para ver mais informações
                </Typography>
              )}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Chave Pix</TableCell>
                      <TableCell>Valor Solicitado</TableCell>
                      <TableCell>Data de criação</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Detalhes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {withdrawList?.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item?.pix_key || 'Não informado'}</TableCell>
                        <TableCell>{item.value ? formatCurrency(item.value) : 'Não informado'}</TableCell>
                        <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                        <TableCell>
                          <Chip
                            label={item.status_display}
                            color={item.status == 'requested' ? 'info' : item.status == 'done' ? 'success' : 'warning'}
                          />
                        </TableCell>
                        <TableCell>{item?.result_message || 'Aguardando'} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
