import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/routesList';

export const BottomNavigator = () => {
  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname || '/');
  const navigate = useNavigate();

  const handleNavigate = (newValue: string) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        handleNavigate(newValue);
      }}
      showLabels
      sx={{
        position: 'fixed',
        bottom: 10,
        left: 0,
        right: 0,
        zIndex: 1000,
        elevation: 10,
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[300],
        width: '95%',
        marginLeft: '2.5%',
        marginRight: '2.5%',
        borderRadius: 2,
      }}
    >
      <BottomNavigationAction
        label="Início"
        value={ROUTES.menuItems.app}
        icon={<HomeIcon />}
        component={Link}
        to={ROUTES.menuItems.app}
        centerRipple
        style={{
          marginRight: -10,
        }}
      />
      <BottomNavigationAction
        label="Extrato"
        value={ROUTES.menuItems.statement}
        icon={<AttachMoneyIcon />}
        component={Link}
        to={ROUTES.menuItems.statement}
        centerRipple
      />
      <BottomNavigationAction
        label="Novo"
        value={ROUTES.menuItems.newInsurance}
        icon={
          <AddIcon
            sx={{
              color: theme.palette.common.white,
              width: 45,
              height: 45,
              backgroundColor: theme.palette.primary.main,
              borderRadius: 70,
            }}
          />
        }
        component={Link}
        to={ROUTES.menuItems.newInsurance}
        style={{
          bottom: 10,
          marginLeft: -10,
          marginRight: -10,
        }}
        centerRipple
      />
      <BottomNavigationAction
        label="Indicações"
        value={ROUTES.menuItems.referrals}
        icon={<FavoriteIcon />}
        component={Link}
        to={ROUTES.menuItems.referrals}
        centerRipple
      />
      <BottomNavigationAction
        label="Mais"
        value={ROUTES.menuItems.options}
        icon={<MenuIcon />}
        component={Link}
        to={ROUTES.menuItems.options}
        centerRipple
        style={{
          marginLeft: -10,
        }}
      />
    </BottomNavigation>
  );
};
