import { isAxiosError } from 'axios';
import createAxiosInstance from '../middleware/axios.interceptors';

const baseUrl = '/insurance/cashback';
const baseUrlWithdraw = '/insurance/cashback-withdrawal';

export const CashbackService = {
  getCashbackValues: async (page: number = 0) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${baseUrl}/`);
    } catch (err: any) {
      if (isAxiosError(err)) {
        throw err.response;
      }
    }
  },

  withdrawCashback: async (data: any) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrlWithdraw}/`, data);
    } catch (err: any) {
      if (isAxiosError(err)) {
        throw err.response;
      }
    }
  },

  getCashbackWithdraws: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${baseUrlWithdraw}/`);
    } catch (err: any) {
      if (isAxiosError(err)) {
        throw err.response;
      }
    }
  },
};
