import { AirplaneTicket } from '@mui/icons-material';
import FlightIcon from '@mui/icons-material/Flight';
import { Insurance } from '../models/Insurance';
import { ROUTES } from '../routes/routesList';

export const INSURANCE_TYPES: Insurance[] = [
  {
    id: '1',
    name: 'Viagem Internacional',
    icon: <AirplaneTicket />,
    visible: true,
    disabled: false,
    route: ROUTES.forms.newTravelInsurance,
  },
  {
    id: '2',
    name: 'Viagem Nacional',
    icon: <FlightIcon />,
    visible: true,
    disabled: false,
    route: ROUTES.forms.newBrazilTravelInsurance,
  },
];
