import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput } from '../../../components/basics/TextInput/TextInput';
import { useAppContext } from '../../../context/GlobalContextProvider';
import { CashbackService } from '../../../services/Cashback.service';
import { checkStatus } from '../../../utils/api/response';
import { withdrawScheme } from '../../../utils/forms/auth/formValidationSchemas';
import { formatCurrencyToNumber } from '../../../utils/methods';

interface Props {
  onClose: () => void;
  availableWithdrawAmount: number;
}

export const WithdrawModal = ({ onClose, availableWithdrawAmount, ...other }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();

  const [showInstructions, setShowInstructions] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');

  const handleContinueToWithdraw = () => {
    setShowInstructions(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      amount: availableWithdrawAmount,
      pixKey: '',
    },
    resolver: yupResolver(withdrawScheme),
  });

  const onSubmit = async (data: any) => {
    try {
      showGlobalSpinner({
        message: 'Solicitando saque...',
      });

      let submitData = {
        pix_key: data.pixKey,
        amount: formatCurrencyToNumber(data.amount),
      };

      const response = await CashbackService.withdrawCashback(submitData);
      if (response && checkStatus(response)) {
        setStatusMessage('Saque solicitado com sucesso!');
      } else {
        setStatusMessage('Erro ao realizar saque');
      }
    } catch (error: any) {
      setStatusMessage(error.data.amount || error.data.pix_key || error.data.__all__ || 'Erro ao realizar saque');
    } finally {
      hideGlobalSpinner();
    }
  };

  const _returnInstructions = () => (
    <>
      <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <DialogContentText sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant='body1' gutterBottom>
            Você pode retirar os valores disponíveis para saque, informando a sua chave pix.
          </Typography>
          <Typography variant='body1' gutterBottom>
            Importante! Sua chave Pix deve ser do tipo CPF e deve ser igual ao CPF informado no seu cadastro.
          </Typography>
          <Typography variant='body1' gutterBottom>
            Caso tenha dúvidas, acesse o seu perfil e verifique o seu CPF.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color='primary' variant='outlined' fullWidth={isMobile}>
          Cancelar
        </Button>
        <Button onClick={handleContinueToWithdraw} color='primary' autoFocus variant='contained' fullWidth={isMobile}>
          Prosseguir
        </Button>
      </DialogActions>
    </>
  );

  const _returnWithdrawForm = () => (
    <>
      <DialogContent>
        <Box>
          <InputLabel>Digite sua chave PIX</InputLabel>
          <TextInput
            control={control}
            name='pixKey'
            placeholder='Digite sua chave pix'
            mask='999.999.999-99'
            size='small'
            errorMessage={errors.pixKey?.message?.toString()}
          />
        </Box>
        <Box mt={2}>
          <InputLabel>Digite a quantia</InputLabel>
          <TextInput
            control={control}
            name='amount'
            placeholder='Quantia'
            size='small'
            currency
            errorMessage={errors.amount?.message?.toString()}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color='primary' variant='outlined' fullWidth={isMobile}>
          Cancelar
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color='primary' autoFocus variant='contained' fullWidth={isMobile}>
          Confirmar
        </Button>
      </DialogActions>
    </>
  );

  const _returnStatusMessage = () => (
    <>
      <DialogContent>
        <DialogContentText>
          <Typography variant='body1'>{statusMessage}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} color='primary' variant='outlined' fullWidth={isMobile}>
          Fechar
        </Button>
      </DialogActions>
    </>
  );

  console.log('statusMessage', statusMessage);

  return (
    <Dialog open={true} onClose={onClose} {...other} fullScreen={isMobile}>
      <DialogTitle>Retirar Valores</DialogTitle>
      <Box>
        {statusMessage ? (
          _returnStatusMessage()
        ) : (
          <>{showInstructions ? _returnInstructions() : _returnWithdrawForm()}</>
        )}
      </Box>
    </Dialog>
  );
};
