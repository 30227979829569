import { Box, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const ContainerCentered = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ContainerRow = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const ContainerRowCentered = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1rem;
`;

export const VerticalMargin = styled(Box)`
  margin: 1rem 0rem;
`;

export const HorizontalMargin = styled(Box)`
  margin: 0rem 1rem;
`;

export const MarginTop = styled(Box)`
  margin-top: 1rem;
`;

export const MarginBottom = styled(Box)`
  margin-bottom: 1rem;
`;

export const MarginLeft = styled(Box)`
  margin-left: 1rem;
`;

export const MarginRight = styled(Box)`
  margin-right: 1rem;
`;

export const DefaultLabel = styled(Typography).attrs({
  variant: 'h6',
})``;

export const DefaultText = styled(Typography).attrs({
  variant: 'body1',
})``;

interface IPageTitleProps {
  isMobile: boolean;
}

export const PageTitle = styled(Typography).attrs({
  variant: 'h5',
})<IPageTitleProps>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ ismobile }) => (ismobile ? '0.5rem' : '2rem')};
`;
