import { Card } from '@mui/material';
import styled from 'styled-components';

export const PassengerCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  border: solid 0.5px ${({ theme }) => theme.palette.divider};

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;
