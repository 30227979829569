import { Container, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../../../components/logo';
import RegisterForm from '../../../sections/@auth/RegisterForm';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const websiteQuote = searchParams.get('quote');

  const handleNavigateLogin = () => {
    navigate(`/entrar${websiteQuote ? `?quote=${websiteQuote}` : ''}`);
  };
  console.log('websiteQuote', websiteQuote);

  return (
    <>
      <Helmet>
        <title>Cadastre-se | 91 Seguros </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container maxWidth='sm'>
          <StyledContent>
            <Typography variant='h4' gutterBottom>
              {websiteQuote ? 'Para contratar seu Seguro ' : ''} Cadastre-se na 91 Seguros
            </Typography>

            <Typography variant='body2' sx={{ mb: 5, cursor: 'pointer' }}>
              Já possui uma conta? {''}
              <Link variant='subtitle2' onClick={handleNavigateLogin}>
                Faça login
              </Link>
            </Typography>

            <RegisterForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
