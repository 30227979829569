import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Divider,
  Link,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CryptoJS from 'crypto-js';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../../../components/logo';
import { useGoogleAnalyticsContext } from '../../../context/GoogleAnalyticsContextProvider';
import { useUserContext } from '../../../context/UserContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/localstorageKeys';
import { FormState } from '../../../models/FormState';
import { User } from '../../../models/User';
import { ROUTES } from '../../../routes/routesList';
import AppleLoginButton from '../../../sections/@auth/AppleLoginButton';
import GoogleLoginButton from '../../../sections/@auth/GoogleLoginButton';
import { GoogleLoginButtonMobile } from '../../../sections/@auth/GoogleLoginButtonMobile';
import LoginForm from '../../../sections/@auth/LoginForm';
import { AuthService } from '../../../services/Auth.service';
import { checkStatus } from '../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../utils/forms/form-errors';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const websiteQuote = searchParams.get('quote');

  const { setUser } = useUserContext();
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();

  const [message, setMessage] = useState<string>('');
  const [formState, setFormState] = useState<FormState>({
    severity: 'info',
    message: '',
  });

  const ACCESS_THROUGHT_MOBILE_APP = window.ReactNativeWebView !== undefined;
  const PLATFORM = window.Platform;

  const handleNavigateRegistration = () => {
    navigate(ROUTES.public.registration + (websiteQuote ? `?quote=${websiteQuote}` : ''));
  };

  const handleGoogleLoginExpo = () => {
    window.ReactNativeWebView.postMessage('googleLogin');
  };

  const handleAppleLoginExpo = () => {
    window.ReactNativeWebView.postMessage('appleLogin');
  };

  const setUserInLocalStorage = (user: User) => {
    try {
      const secretKey = process.env.REACT_APP_CRYPTO_KEY;
      const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(user), secretKey!).toString();
      localStorage.setItem(LOCAL_STORAGE_KEYS.user, encryptedUserData);
    } catch (error: any) {
      localStorage.clear();
      navigate('/' + ROUTES.public.login, { replace: true });
    }
  };

  const makeGoogleAuthRequest = async (authorization_code: string) => {
    setFormState({
      severity: 'info',
      message: 'Estamos realizando o login...',
    });

    try {
      const response = await AuthService.doGoogleAuth(authorization_code, websiteQuote);
      if (checkStatus(response)) {
        setMessage(response?.data.message || 'Login realizado com sucesso!');
        newGoogleAnalyticsEvent('login', { method: 'google' });

        setUserInLocalStorage(response?.data.user);
        setUser(response?.data.user);
        navigate('/' + ROUTES.menuItems.app, { replace: true });
      }
    } catch (error: any) {
      const errors = setInputErrorsFromApi(() => {}, error?.data.errors);
      if (errors) {
        setFormState({
          severity: 'error',
          message: errors || 'Não foi possível realizar o login, verifique seus dados e tente novamente.',
        });
      }
    }
  };

  const makeAppleAuthRequest = async (credentials: any) => {
    setFormState({
      severity: 'info',
      message: 'Estamos realizando o login...',
    });

    try {
      const response = await AuthService.doAppleAuth(credentials, websiteQuote);
      if (checkStatus(response)) {
        setMessage(response?.data.message || 'Login realizado com sucesso!');
        newGoogleAnalyticsEvent('login', { method: 'apple' });

        setUserInLocalStorage(response?.data.user);
        setUser(response?.data.user);
        navigate('/' + ROUTES.menuItems.app, { replace: true });
      }
    } catch (error: any) {
      setFormState({
        severity: 'error',
        message: error?.data.errors['__all__'] ?? 'Erro ao realizar o login.',
      });
    }
  };

  window.makeGoogleAuthRequest = makeGoogleAuthRequest;
  window.makeAppleAuthRequest = makeAppleAuthRequest;

  return (
    <>
      <Helmet>
        <title> Login | 91 Seguros </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
              Olá, bem vindo de volta!
            </Typography>
            <img src='/assets/illustrations/illustration_login.png' alt='login' />
          </StyledSection>
        )}

        <Container maxWidth='sm'>
          <StyledContent>
            <Typography variant='h4' gutterBottom>
              Login - 91 Seguros
            </Typography>

            <Typography variant='body2' sx={{ mb: 5, cursor: 'pointer' }}>
              Não possui uma conta? {''}
              <Link variant='subtitle2' onClick={handleNavigateRegistration}>
                Registre-se
              </Link>
            </Typography>

            {ACCESS_THROUGHT_MOBILE_APP ? (
              <Stack direction='column' spacing={2}>
                <GoogleLoginButtonMobile onClick={handleGoogleLoginExpo} />
                {PLATFORM.toString().toLowerCase() == 'ios' && <AppleLoginButton onClick={handleAppleLoginExpo} />}
              </Stack>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <GoogleLoginButton handleGoogleLogin={makeGoogleAuthRequest} websiteQuote={websiteQuote} />
              </Box>
            )}

            {formState.message && (
              <Alert severity={formState.severity} variant='outlined' sx={{ mt: 2 }}>
                <AlertTitle>Aguarde</AlertTitle>
                {formState.message}
              </Alert>
            )}

            <Divider sx={{ my: 3 }}>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                OU
              </Typography>
            </Divider>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
