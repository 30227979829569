import { Card } from '@mui/material';
import styled from 'styled-components';
import { hexToRGBA } from '../../../../../../utils/methods';

interface CardContainerProps {
  selected: boolean;
}

export const CardContainer = styled(Card)<CardContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  min-height: 150px;
  background-color: ${({ selected: selected, theme }) =>
    selected ? hexToRGBA(theme.palette.primary.main, 0.1) : theme.palette.common.white} !important;

  border: solid;
  border-width: 1px;
  border-color: ${({ selected: seleced, theme }) =>
    seleced ? hexToRGBA(theme.palette.primary.main, 1) : theme.palette.common.white} !important;

  transform: ${({ selected: seleced }) => (seleced ? 'scale(1.05)' : 'scale(1)')};

  &:hover {
    transform: scale(1.1);
  }
`;
