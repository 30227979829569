import { Box, Card, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const PageHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
`;

export const PageTitleMobile = styled(Typography).attrs({
  variant: 'h6',
})`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-size: 1.25rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-left: 0.5rem;
`;

export const PageBody = styled(Box).attrs({
  overflow: 'auto',
})`
  flex-direction: column;
`;

export const PageFooter = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  width: 100%;
`;
