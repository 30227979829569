import { CardContent, Typography } from '@mui/material';
import { ContainerCentered } from '../../../theme/GlobalStyledComponents';
import { CoverageType } from '../Steps/StepTwoCoverageType';
import { CustomCard } from './styles';

interface Props {
  coverageType: CoverageType;
  onSelect: (key: string) => void;
}

export const InsuranceTypeCard = ({ coverageType, onSelect }: Props) => {
  return (
    <CustomCard
      onClick={() => onSelect(coverageType.key)}
      selected={coverageType?.selected || false}
      sx={{ width: '100%' }}
    >
      <CardContent
        sx={{ backgroundImage: `url(${coverageType?.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <Typography variant='subtitle1' textAlign='center'>
          {coverageType?.value}
        </Typography>
        {coverageType?.icon && <ContainerCentered>{coverageType?.icon}</ContainerCentered>}
      </CardContent>
    </CustomCard>
  );
};
