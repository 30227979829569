import { useMediaQuery, useTheme } from '@mui/material';
import React, { createContext, useContext } from 'react';

interface MediaQueryContextData {
  ismobile: boolean;
}

const MediaQueryContext = createContext<MediaQueryContextData>({
  ismobile: false,
});

export const useMediaQueryContext = () => useContext(MediaQueryContext);

interface MediaQueryProviderProps {
  children: React.ReactNode;
}

export const MediaQueryProvider = ({ children }: MediaQueryProviderProps) => {
  const theme = useTheme();
  const ismobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <MediaQueryContext.Provider value={{ ismobile }}>{children}</MediaQueryContext.Provider>;
};
