import { Box, Theme, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useAppContext } from '../../../context/GlobalContextProvider';
import { useHomeInsuranceContext } from '../../../context/insurances/HomeInsuranceContextProvider';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { HomeInsuranceQuotation } from '../../../models/HomeInsuranceQuotation';
import { HomeInsuranceService } from '../../../services/HomeInsurance.service';
import { ContainerRow } from '../../../theme/GlobalStyledComponents';
import { checkStatus } from '../../../utils/api/response';
import { QuotationCard } from '../QuotationCard/QuotationCard';
import { HeaderWrapper, Subtitle, Title } from './styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: HomeInsurance) => void;
}

export const StepFourInsuranceQuotation = ({ onPrevious, onNext }: Props) => {
  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage, loading } = useAppContext();

  const {
    homeInsuranceData,
    setHomeInsuranceQuotation: setInsuranceQuotation,
    homeInsuranceQuotation: insuranceQuotation,
    setSelectedQuotation,
  } = useHomeInsuranceContext();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleInsuranceHiring = (insuranceQuotation: HomeInsuranceQuotation) => {
    setSelectedQuotation(insuranceQuotation);
    onNext({
      progress_step: 5,
    });
  };

  const getInsuranceQuote = async () => {
    try {
      showGlobalSpinner({
        message: 'Obtendo cotação de seguro residencial...',
      });

      const response = await HomeInsuranceService.getInsuranceQuote(String(homeInsuranceData.id));
      if (checkStatus(response)) {
        setInsuranceQuotation(response?.data.result);
      }
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao buscar cotação de seguro residencial',
        severity: 'error',
      });
      console.log('Erro ao buscar cotação de seguro residencial', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    if (!insuranceQuotation || insuranceQuotation.length === 0) {
      getInsuranceQuote();
    }
  }, []);

  if (loading) return null;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <HeaderWrapper sx={{ mt: 2, paddingX: 1 }}>
        <Title>Seguros Disponíveis</Title>
        <Subtitle>Selecione a opção que mais se encaixa com o seu perfil.</Subtitle>
      </HeaderWrapper>
      <ContainerRow style={{ overflowX: isMobile ? 'scroll' : 'auto' }} gap={4} padding={4}>
        {insuranceQuotation.map((quotation, index) => (
          <Box key={index}>
            <QuotationCard
              onConfirmHiring={handleInsuranceHiring}
              onPrevious={onPrevious}
              insuranceQuotation={quotation}
            />
          </Box>
        ))}
      </ContainerRow>
    </Box>
  );
};
