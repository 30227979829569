import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CardHeader, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Passenger } from '../../../../../../models/Passenger';
import { getFormatedDate } from '../../../../../../utils/methods';
import { PassengerCardContainer } from './styles';

interface Props {
  onEditPassenger: (passenger: Passenger) => void;
  onRemovePassenger: (index: number) => void;
  passenger: Passenger;
  index: number;
  showRemoveButton?: boolean;
  editText?: string;
  editIcon?: JSX.Element;
  editColor?: string;
}

export const PassengerCard = ({
  passenger,
  index,
  onEditPassenger,
  onRemovePassenger,
  showRemoveButton,
  editText,
  editIcon,
  editColor,
}: Props) => {
  return (
    <PassengerCardContainer>
      <CardHeader
        title={
          <Typography variant='h5' color='primary'>
            Passageiro {index + 1}
          </Typography>
        }
        action={
          showRemoveButton && (
            <IconButton
              onClick={() => onRemovePassenger(index)}
              sx={{ p: 1 }}
              aria-label='settings'
              size='small'
              color='primary'
            >
              <CloseIcon />
            </IconButton>
          )
        }
      ></CardHeader>
      <Stack sx={{ p: 2, pt: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='subtitle1'>Nome:</Typography>
            <Typography variant='subtitle1'>
              {passenger.first_name && passenger.last_name ? passenger.first_name + ' ' + passenger.last_name : '---'}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='subtitle1'>Data de Nascimento:</Typography>
            <Typography variant='subtitle1'>
              {passenger?.birthdate ? getFormatedDate(passenger?.birthdate) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Divider />
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          variant='outlined'
          size='small'
          startIcon={editIcon ?? <EditIcon />}
          onClick={() => onEditPassenger(passenger)}
          sx={{ textTransform: 'none', m: 1 }}
          color={editColor ? 'primary' : 'inherit'}
        >
          {editText ?? 'Editar'}
        </Button>
      </Stack>
    </PassengerCardContainer>
  );
};
