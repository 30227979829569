import { BaseTextFieldProps, TextField } from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';

interface Props extends BaseTextFieldProps {
  name: string;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  errorMessage?: string | FieldError | undefined;
  type?: string;
  required?: boolean;
  minRows?: number;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  mask?: string | RegExp | undefined;
  currency?: boolean;
  InputProps?: any;
}

export const TextInput = ({
  name,
  control,
  label,
  placeholder,
  autoFocus,
  defaultValue,
  errorMessage,
  type = 'text',
  minRows,
  variant = 'outlined',
  size = 'small',
  mask,
  helperText,
  currency,
  ...rest
}: Props) => {
  const _renderInput = ({ field, ref }: any) => {
    const inputProps = {
      ...rest,
      ...field,
      fullWidth: true,
      type,
      label,
      autoFocus,
      placeholder,
      multiline: !!minRows,
      minRows: minRows || 1,
      error: !!errorMessage,
      helperText: errorMessage ? errorMessage : helperText,
      size,
      variant,
    };

    if (mask) {
      return (
        <InputMask mask={mask} {...inputProps} ref={ref}>
          {(inputProps: any) => <TextField {...inputProps} ref={ref} />}
        </InputMask>
      );
    }

    if (currency) {
      return (
        <NumericFormat
          defaultValue={defaultValue ?? ''}
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator='.'
          decimalSeparator=','
          prefix='R$ '
          allowNegative={false}
          customInput={TextField}
          InputProps={{ size: 'small', name: inputProps.name, ref: ref }}
          fullWidth
          onChange={(event) => {
            field.onChange(event.target.value);
          }}
        />
      );
    }

    return <TextField {...inputProps} ref={ref} />;
  };

  return <Controller name={name} control={control} render={_renderInput} defaultValue={defaultValue ?? ''} />;
};
