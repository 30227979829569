import createAxiosInstance from '../middleware/axios.interceptors';
import { User } from '../models/User';
import { LoginForm } from '../sections/@auth/LoginForm';

const googleAuthUrl = '/auth/google-auth';
const appleAuthUrl = '/auth/apple-auth';
const loginUrl = '/auth/login';

const signUpUrl = '/auth/sign-up';
const accountConfirmation = '/auth/user-register-activate';
const requestPasswordChange = '/auth/forgot-password';
const changePassword = '/auth/forgot-password';
const notifications = '/auth/notifications';
const userInformation = 'auth/user-information';
const invitation = '/auth/invitation-code';
const changePasswordLoggedInUser = '/auth/change-password';

export const AuthService = {
  doGoogleAuth: async (authorizationCode: string, websiteQuote: string | null, invitationCode?: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(googleAuthUrl, {
        authorization_code: authorizationCode,
        invitation_code: invitationCode,
        website_quote: websiteQuote,
      });
    } catch (err: any) {
      throw err.response;
    }
  },

  doAppleAuth: async (credentials: any, websiteQuote: string | null, invitationCode?: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(appleAuthUrl, {
        credentials: credentials,
        website_quote: websiteQuote,
        // invitation_code: invitationCode,
      });
    } catch (err: any) {
      throw err.response;
    }
  },

  doLogin: async (data: LoginForm) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(loginUrl, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  register: async (data: User) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(signUpUrl, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  accountConfirmation: async (token: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${accountConfirmation}/${token}`);
    } catch (err: any) {
      throw err.response;
    }
  },

  requestPasswordChange: async (email: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(requestPasswordChange, { email });
    } catch (err: any) {
      throw err.response;
    }
  },

  changePasswordValidateToken: async (token: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${changePassword}/${token}`);
    } catch (err: any) {
      throw err.response;
    }
  },

  changePassword: async (token: string, data: { password: string; password_confirmation: string }) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${changePassword}/${token}`, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  changePasswordLoggedInUser: async (data: {
    password: string;
    new_password: string;
    password_confirmation: string;
  }) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(changePasswordLoggedInUser, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  fetchNotifications: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${notifications}`);
    } catch (err: any) {
      throw err.response;
    }
  },

  markAllNotificationsAsRead: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${notifications}/mark-read`);
    } catch (err: any) {
      throw err.response;
    }
  },

  getUserInformation: async () => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(userInformation);
    } catch (err: any) {
      throw err.response;
    }
  },

  invitationCode: async (invitationCode: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${invitation}/${invitationCode}`);
    } catch (err: any) {
      throw err.response;
    }
  },
};
