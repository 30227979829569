import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Button, Container, Stack, Tab, Tooltip } from '@mui/material';
// components
// mock
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/GlobalContextProvider';
import { useGoogleAnalyticsContext } from '../../context/GoogleAnalyticsContextProvider';
import { Withdraw } from '../../models/Withdraw';
import { AvailableCashbackTab } from '../../sections/@statement/Tabs/AvailableCashbackTab/AvailableCashbackTab';
import { BonusLevelsTab } from '../../sections/@statement/Tabs/BonusLevelsTab/BonusLevelsTab';
import { WithdrawHistoryTab } from '../../sections/@statement/Tabs/WithdrawHistoryTab/WithdrawHistoryTab';
import { WithdrawModal } from '../../sections/@statement/WithdrawModal';
import { CashbackService } from '../../services/Cashback.service';
import { PageTitle } from '../../theme/GlobalStyledComponents';
import { checkStatus } from '../../utils/api/response';

// ----------------------------------------------------------------------

export default function StatementPage() {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();
  const [loading, setLoading] = useState(false);

  const [cashbackValues, setCashbackValues] = useState<any>();
  const [cashbackWithdraws, setCashbackWithdraws] = useState<Withdraw[]>([]);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleWithdrawModal = () => {
    setShowWithdrawModal(!showWithdrawModal);
  };

  const fetchCashbackValues = async () => {
    try {
      const response = await CashbackService.getCashbackValues();
      if (checkStatus(response)) {
        setCashbackValues(response?.data.result);
      }
    } catch (error: any) {
      console.log('Erro ao buscar valores de cashback', error);
    }
  };

  const fetchCashbackWithdraws = async () => {
    try {
      const response = await CashbackService.getCashbackWithdraws();
      if (checkStatus(response)) {
        setCashbackWithdraws(response?.data.result);
      }
    } catch (error: any) {
      console.log('Erro ao buscar valores de cashback', error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    showGlobalSpinner({
      message: 'Carregando extrato...',
    });

    await fetchCashbackValues();
    await fetchCashbackWithdraws();

    hideGlobalSpinner();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return null;

  return (
    <>
      <Helmet>
        <title> Dashboard | Extrato </title>
      </Helmet>

      <Container>
        {/* <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <MonetizationOnIcon className='icon-flip' color='success' />
        </Box> */}
        <Stack
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <PageTitle isMobile={ismobile} sx={{ mt: 1 }}>
            Extrato
          </PageTitle>
          <Box>
            <Tooltip
              title={Number(cashbackValues?.cashback?.available) == 0 ? 'Nenhum valor disponível para saque' : ''}
            >
              <Button
                variant='outlined'
                color='primary'
                onClick={handleWithdrawModal}
                disabled={Number(cashbackValues?.cashback?.available) == 0}
              >
                Sacar Valores
              </Button>
            </Tooltip>
          </Box>
        </Stack>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider', justifyContent: 'center' }}>
              <TabList onChange={handleChange} aria-label='lab API tabs example'>
                <Tab label='Valores' value='1' />
                <Tab label='Histórico' value='2' />
                <Tab label='Níveis' value='3' />
              </TabList>
            </Box>
            <TabPanel value='1' sx={{ p: 0, pt: 2 }}>
              <AvailableCashbackTab cashbackValues={cashbackValues} />
            </TabPanel>
            <TabPanel value='2' sx={{ p: 0, pt: 2 }}>
              <WithdrawHistoryTab withdrawList={cashbackWithdraws} />
            </TabPanel>
            <TabPanel value='3' sx={{ p: 0, pt: 2 }}>
              <BonusLevelsTab cashbackValues={cashbackValues} />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>

      {/* MODAL PARA SAQUES */}
      {showWithdrawModal && (
        <WithdrawModal onClose={handleWithdrawModal} availableWithdrawAmount={cashbackValues?.cashback?.available} />
      )}
    </>
  );
}
