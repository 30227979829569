import CheckIcon from '@mui/icons-material/Check';
import FlightIcon from '@mui/icons-material/Flight';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import { Box, Button, Grid, Link, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../../components/logo';
import { ROUTES } from '../../../routes/routesList';

const ToolbarContainer = styled(Toolbar).attrs({
  elevation: 3,
})`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: linear-gradient(to left, rgba(230, 28, 110, 1), rgba(233, 77, 28, 1));
`;

export const TravelInsuranceMarketingCampaign = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const LINK_COVERAGES = 'https://91seguros.com.br/seguro-viagem/coberturas-e-beneficios/';
  const FREQUENT_QUESTIONS = 'https://91seguros.com.br/seguro-viagem/duvidas-frequentes/';
  const BLOG = 'https://91seguros.com.br/blog/';

  const handleNavigateToQuotation = () => {
    navigate(ROUTES.menuItems.app);
  };

  return (
    <>
      <ToolbarContainer>
        <Logo />
        <Stack gap={4} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {!isMobile && (
            <>
              <Link href={LINK_COVERAGES} sx={{ textDecoration: 'none' }}>
                <Typography variant='h6' color='secondary'>
                  Coberturas e Benefícios
                </Typography>
              </Link>
              <Link href={FREQUENT_QUESTIONS} sx={{ textDecoration: 'none' }}>
                <Typography variant='h6' color='secondary'>
                  Dúvidas Frequentes
                </Typography>
              </Link>
              <Link href={BLOG} color='inherit' sx={{ textDecoration: 'none' }}>
                <Typography variant='h6' color='secondary'>
                  Blog
                </Typography>
              </Link>
            </>
          )}
          <Button variant='contained' color='secondary' endIcon={<LoginIcon />} onClick={handleNavigateToQuotation}>
            <Typography variant='h6' sx={{ color: theme.palette.common.white }} textTransform='none'>
              Entrar na 91 Seguros
            </Typography>
          </Button>
        </Stack>
      </ToolbarContainer>

      <PageContainer>
        {/* Landing Page Content */}
        <Box
          textAlign='center'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '75vh',
            alignItems: 'center',
            justifyContent: 'center',
            padding: isMobile ? '1rem 2rem' : '4rem 6rem',
          }}
        >
          <Typography variant='h2' component='h1' gutterBottom sx={{ mb: 6 }} color='white'>
            Seguro Viagem para sua Tranquilidade
          </Typography>
          <Typography variant='h4' gutterBottom sx={{ mb: 6 }} color='white'>
            A proteção que você precisa, onde quer que vá.
          </Typography>
          <Typography variant='body1' sx={{ mb: 6 }} color='white'>
            Oferecemos seguros viagem personalizados para garantir que você aproveite cada momento da sua jornada,{' '}
            <br />
            sabendo que está protegido a cada passo do caminho.
          </Typography>
          <Button variant='contained' endIcon={<CheckIcon color='secondary' />} sx={{ backgroundColor: 'white' }}>
            <Typography variant='h6' color='secondary' textTransform='none'>
              Contratar Seguro Viagem
            </Typography>
          </Button>
        </Box>
      </PageContainer>

      {/* landing page details grid */}
      <Grid
        container
        spacing={isMobile ? 4 : 12}
        sx={{ display: 'flex', flex: 1, justifyContent: 'center', padding: isMobile ? '1rem 2rem' : '4rem 6rem' }}
      >
        <Grid item xs={12} lg={4}>
          <Stack direction='column' alignItems='center'>
            <FlightIcon fontSize='large' style={{ marginBottom: '0.5rem' }} color='secondary' />
            <Typography variant='h5' gutterBottom align='center' color='secondary'>
              Cobertura Global
            </Typography>
          </Stack>
          <Typography variant='body1' gutterBottom textAlign='center' mt={2}>
            Viaje com tranquilidade para qualquer lugar do mundo. A 91 Seguros oferece coberturas abrangentes para
            viagens nacionais e internacionais.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack direction='column' alignItems='center'>
            <PersonIcon fontSize='large' style={{ marginBottom: '0.5rem' }} color='secondary' />
            <Typography variant='h5' gutterBottom align='center' color='secondary'>
              Atendimento Personalizado
            </Typography>
          </Stack>
          <Typography variant='body1' gutterBottom textAlign='center' mt={2}>
            Conte com nosso atendimento dedicado em qualquer momento da sua viagem. Estamos aqui para garantir a sua
            segurança e bem-estar.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack direction='column' alignItems='center'>
            <SecurityIcon fontSize='large' style={{ marginBottom: '0.5rem' }} color='secondary' />
            <Typography variant='h5' gutterBottom align='center' color='secondary'>
              Segurança em Cada Passo
            </Typography>
          </Stack>
          <Typography variant='body1' gutterBottom textAlign='center' mt={2}>
            Com a 91 Seguros, você terá a segurança necessária em cada passo do caminho. Viaje com confiança e aproveite
            suas experiências.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
