import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const Paragraph = styled(Typography).attrs({
  component: 'p',
})`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 2rem;
  line-height: 2;
`;

interface Props {
  ismobile: boolean;
}

export const DocumentContainer = styled(Box).attrs({
  overflow: 'auto',
})<Props>`
  padding: ${({ ismobile }) => (ismobile ? '1rem' : '0 2rem')};
`;

export const ParagraphTitle = styled(Typography).attrs({
  variant: 'h6',
  gutterBottom: true,
})``;
