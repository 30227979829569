import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Theme, useMediaQuery } from '@mui/material';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useAppContext } from '../../../../context/GlobalContextProvider';
import { useUserContext } from '../../../../context/UserContextProvider';
import { useTravelInsuranceContext } from '../../../../context/insurances/TravelInsuranceContextProvider';
import { Passenger } from '../../../../models/Passenger';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import { User } from '../../../../models/User';
import { TravelInsuranceService } from '../../../../services/TravelInsurance.service';
import { UserService } from '../../../../services/User.service';
import { checkStatus } from '../../../../utils/api/response';
import { PassengerCard } from '../PassengersStepAdditionalInfo/components/PassengerCard';
import { PassengersModal } from '../PassengersStepAdditionalInfo/components/PassengersModal/PassengersModal';
import {
  ActionsWrapper,
  Container,
  CustomCard,
  CustomCardContent,
  Footer,
  HeaderWrapper,
  Subtitle,
  Title,
} from '../styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: TravelInsurance) => void;
  currentStep: boolean;
}

const getDefaultValues = (existingPassengers: Passenger[] = [], user: User | null) => {
  if (existingPassengers?.length > 0) {
    return existingPassengers?.map((passenger) => ({
      id: passenger.id,
      first_name: passenger.first_name || '',
      last_name: passenger.last_name || '',
      birthdate: passenger.birthdate || '',
      existsInApi: true,
    }));
  } else {
    return [
      {
        id: uniqueId(),
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        birthdate: user?.client.birthdate || '',
        existsInApi: false,
      },
    ];
  }
};

export const PassengersStepInitialData = ({ onPrevious, onNext, currentStep }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { travelInsuranceData } = useTravelInsuranceContext();

  const { user } = useUserContext();
  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage } = useAppContext();

  const [showPassengersModal, setShowPassengersModal] = useState(false);
  const [passengerEdition, setPassengerEdition] = useState<Passenger>();
  const [passengers, setPassengers] = useState<Passenger[]>(getDefaultValues(travelInsuranceData?.passengers, user));

  const createPassenger = async (passenger: Passenger) => {
    try {
      const response = await TravelInsuranceService.addPassenger(String(travelInsuranceData.id), {
        ...passenger,
        birthdate: passenger.birthdate,
      });
      return response;
    } catch (error: any) {
      console.log('Erro ao adicionar passageiro', error);
      return error;
    }
  };

  const updatePassenger = async (passenger: Passenger) => {
    try {
      const response = await TravelInsuranceService.updatePassenger(
        String(travelInsuranceData.id),
        String(passenger.id),
        {
          ...passenger,
          birthdate: passenger.birthdate,
        }
      );
      return response;
    } catch (error: any) {
      console.log('Erro ao atualizar passageiro', error);
      return error;
    }
  };

  const onSubmit = async () => {
    if (passengers.some((p) => !p.birthdate)) {
      showSnackbarMessage({
        message: 'Preencha a data de nascimento de todos os passageiros',
        severity: 'warning',
      });
      return;
    }

    showGlobalSpinner({
      message: 'Salvando passageiros...',
    });

    const responses = [];
    for (const passenger of passengers) {
      if (`${passenger.first_name} ${passenger.last_name}` === `${user?.first_name} ${user?.last_name}`) {
        try {
          await UserService.userUpdate({ birthdate: passenger.birthdate });
        } catch (error: any) {
          console.log('Erro ao atualizar data de nascimento do usuário', error);
        }
      }

      if (!passenger.existsInApi) {
        responses.push(await createPassenger(passenger));
      } else {
        responses.push(await updatePassenger(passenger));
      }
    }

    hideGlobalSpinner();

    if (responses.find((r) => !checkStatus(r))) {
      showSnackbarMessage({
        message: 'Ocorreu um erro ao salvar os passageiros. Verifique os dados informados.',
        severity: 'error',
      });
    } else {
      const data: TravelInsurance = {
        progress_step: 4,
      };
      onNext(data);
    }
  };

  const handleSubmitFormPassenger = (passenger: Passenger) => {
    if (passenger.id) {
      const newPassengers = [...passengers];
      const index = newPassengers.findIndex((p) => p.id === passenger.id);
      newPassengers[index] = passenger;
      setPassengers(newPassengers);
    } else {
      setPassengers([...passengers, { ...passenger, id: uniqueId() }]);
    }
    setShowPassengersModal(false);
  };

  const handleShowEditPassengerModal = (index: number) => {
    setShowPassengersModal(true);
    setPassengerEdition(passengers[index]);
  };

  const handleNewPassengerModal = () => {
    setPassengerEdition(undefined);
    setShowPassengersModal(true);
  };

  const handleRemovePassenger = async (index: number) => {
    const newPassengers = [...passengers];
    let deleted = newPassengers.splice(index, 1)[0];
    setPassengers(newPassengers);

    if (deleted.existsInApi) {
      try {
        await TravelInsuranceService.deletePassenger(String(travelInsuranceData.id), String(deleted.id));
      } catch (error: any) {
        showSnackbarMessage({
          message: 'Erro ao remover passageiro',
          severity: 'error',
        });
        setPassengers([...newPassengers, deleted]);
      }
    }
  };

  return (
    <>
      <CustomCard ismobile={isMobile} overflow='auto'>
        <CustomCardContent>
          <Container ismobile={isMobile}>
            <HeaderWrapper>
              <Title>Quem viaja?</Title>
              <Subtitle>Informe os dados de todos os passageiros que estarão viajando.</Subtitle>
            </HeaderWrapper>
            <Grid container spacing={2}>
              {passengers.map((passenger, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <PassengerCard
                    passenger={passenger}
                    index={index}
                    onEditPassenger={() => handleShowEditPassengerModal(index)}
                    onRemovePassenger={handleRemovePassenger}
                    showRemoveButton={passengers.length > 1 && index > 0}
                    editColor='primary'
                  />
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100px',
                }}
              >
                <Button
                  variant='contained'
                  onClick={handleNewPassengerModal}
                  size='large'
                  startIcon={<AddIcon />}
                  sx={{ textTransform: 'none' }}
                >
                  Incluir outro passageiro
                </Button>
              </Grid>
            </Grid>
          </Container>
        </CustomCardContent>
      </CustomCard>
      <Footer>
        <ActionsWrapper ismobile={isMobile}>
          <LoadingButton
            fullWidth={isMobile}
            variant='outlined'
            color='primary'
            onClick={onPrevious}
            size='large'
            startIcon={<ArrowBackIosIcon />}
          >
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth={isMobile}
            variant='contained'
            color='primary'
            onClick={onSubmit}
            size='large'
            endIcon={<ArrowForwardIosIcon />}
          >
            Avançar
          </LoadingButton>
        </ActionsWrapper>
      </Footer>
      {showPassengersModal && (
        <PassengersModal
          passenger={passengerEdition}
          onClose={() => setShowPassengersModal(false)}
          onConfirm={handleSubmitFormPassenger}
        />
      )}
    </>
  );
};
