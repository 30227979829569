import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { SelectOption } from '../../../../components/basics/ControlledAutocomplete/ControlledAutocomplete';
import { useAutocompleteContext } from '../../../../context/AutocompleteContextProvider';
import { useGoogleAnalyticsContext } from '../../../../context/GoogleAnalyticsContextProvider';
import { useTravelInsuranceContext } from '../../../../context/insurances/TravelInsuranceContextProvider';
import { Continent, TravelInsurance } from '../../../../models/TravelInsurance';
import { TravelStepOneSchema } from '../../../../utils/forms/auth/formValidationSchemas';
import {
  ActionsWrapper,
  Container,
  CustomCard,
  CustomCardContent,
  Footer,
  FormContainer,
  HeaderWrapper,
  Subtitle,
  Title,
} from '../styles';
import { DestinyCard } from './components/DestinyCard';

const selectOptions = [
  {
    key: 'europe',
    value: 'Europa',
  },
  {
    key: 'north_america',
    value: 'América do Norte',
  },
  {
    key: 'caribbean_mexico',
    value: 'México e Caribe',
  },
  {
    key: 'south_america',
    value: 'América do Sul',
  },
  {
    key: 'africa',
    value: 'África',
  },
  {
    key: 'asia',
    value: 'Ásia',
  },
  {
    key: 'oceania',
    value: 'Oceania',
  },
  {
    key: 'middle_east',
    value: 'Oriente Médio',
  },
];

interface Props {
  onPrevious: () => void;
  onNext: (data: TravelInsurance) => void;
  currentStep: boolean;
}

interface FormFields {
  destiny: any;
  countries: any;
}

export const DestinyStep = ({ onPrevious, onNext, currentStep }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { autocomplete } = useAutocompleteContext();
  const { travelInsuranceData } = useTravelInsuranceContext();
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();

  const getDefaultValues = () => {
    let destiny: Continent | undefined = autocomplete?.travel_destiny.find(
      (item: any) => item.key === travelInsuranceData?.destiny
    );

    return {
      destiny: destiny,
    };
  };

  const { control, handleSubmit, reset } = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(TravelStepOneSchema),
    defaultValues: getDefaultValues(),
  });

  const destinyWatch = useWatch({
    control,
    name: 'destiny',
  });

  const handleSelectedDestiny = (key: string) => {
    let destiny: Continent | undefined = autocomplete?.travel_destiny.find((item: SelectOption) => item.key === key);
    reset({
      destiny: destiny,
    });
  };

  const onSubmit = (data: TravelInsurance) => {
    let formData: TravelInsurance = {};
    formData.progress_step = 1;
    formData.destiny = data?.destiny?.key;
    let travelInsuranceId = travelInsuranceData?.id;
    onNext({ ...formData, id: travelInsuranceId });
  };

  return (
    <>
      {travelInsuranceData.destiny === 'within_my_country' ? (
        <>
          <CustomCard ismobile={isMobile}>
            <CustomCardContent>
              <Container ismobile={isMobile}>
                <FormContainer ismobile={isMobile}>
                  <Typography variant='h6' color='primary' sx={{ textAlign: 'center', width: '100%' }}>
                    Viagem dentro do Brasil
                  </Typography>
                  <Grid container spacing={3} margin='1rem'>
                    <Typography variant='subtitle1'>Importante:</Typography>
                    <Typography variant='body2'>
                      O seguro viagem nacional é válido dentro do Brasil, a partir de 100km do seu domicílio.
                    </Typography>
                  </Grid>
                </FormContainer>
              </Container>
            </CustomCardContent>
          </CustomCard>
          <Footer>
            <ActionsWrapper ismobile={isMobile}>
              <LoadingButton
                fullWidth={isMobile}
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
                size='large'
                endIcon={<ArrowForwardIosIcon />}
              >
                Próximo
              </LoadingButton>
            </ActionsWrapper>
          </Footer>
        </>
      ) : (
        <>
          <form>
            <CustomCard ismobile={isMobile}>
              <CustomCardContent>
                <Container ismobile={isMobile}>
                  <HeaderWrapper>
                    <Title>Destino da viagem</Title>
                    <Subtitle>Selecione o destino da sua viagem.</Subtitle>
                  </HeaderWrapper>
                  <FormContainer ismobile={isMobile}>
                    <Typography variant='h6' color='primary' sx={{ textAlign: 'center', width: '100%' }}>
                      Origem: Brasil
                    </Typography>
                    <Grid container spacing={3} marginTop='1rem'>
                      {selectOptions.map((item: SelectOption, index: number) => (
                        <DestinyCard
                          key={index}
                          destiny={item}
                          onSelected={handleSelectedDestiny}
                          selected={item.key == destinyWatch?.key}
                        />
                      ))}
                    </Grid>
                  </FormContainer>
                </Container>
              </CustomCardContent>
            </CustomCard>
          </form>
          <Footer>
            <ActionsWrapper ismobile={isMobile}>
              <LoadingButton
                fullWidth={isMobile}
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
                size='large'
                endIcon={<ArrowForwardIosIcon />}
              >
                Próximo
              </LoadingButton>
            </ActionsWrapper>
          </Footer>
        </>
      )}
    </>
  );
};
