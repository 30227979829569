import { PriceCheck, Sell } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../../context/GlobalContextProvider';
import { useGoogleAnalyticsContext } from '../../../../context/GoogleAnalyticsContextProvider';
import { useUserContext } from '../../../../context/UserContextProvider';
import { useTravelInsuranceContext } from '../../../../context/insurances/TravelInsuranceContextProvider';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import { TravelInsuranceService } from '../../../../services/TravelInsurance.service';
import { ContainerColumn } from '../../../../theme/GlobalStyledComponents';
import { checkStatus } from '../../../../utils/api/response';
import { formatCurrency, hexToRGBA } from '../../../../utils/methods';
import { ActionsWrapper, Footer } from '../styles';
import { QuotationCard } from './components/QuotationCard/QuotationCard';

interface Props {
  onPrevious: () => void;
  onNext: (data?: TravelInsurance) => void;
  currentStep: boolean;
}

interface TabLabelProps {
  title: string;
  quote: string;
  index: string;
}

export const InsuranceQuotationStep = ({ onPrevious, onNext, currentStep }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();

  const { user } = useUserContext();
  const { showGlobalSpinner, hideGlobalSpinner, loading, showSnackbarMessage } = useAppContext();

  const QUOTATION_ORIGIN = user?.platform == 'web' ? 'web' : 'app';

  const [activeTab, setActiveTab] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const { travelInsuranceData, travelInsuranceQuotation, setTravelInsuranceQuotation, setChosenQuotation } =
    useTravelInsuranceContext();

  const handleInsuranceHiring = (index: number) => {
    setChosenQuotation(travelInsuranceQuotation[index]);

    newGoogleAnalyticsEvent('add_to_cart', {
      currency: 'BRL',
      value: travelInsuranceQuotation[index]?.total_price,
      item_id: travelInsuranceQuotation[index]?.product_code,
      item_name: travelInsuranceQuotation[index]?.product_name,
    });

    let quotation = travelInsuranceQuotation[index];
    onNext({
      product_code: quotation.product_code,
      progress_step: 7,
    });
  };

  const getInsuranceQuotes = async () => {
    let response = null;

    try {
      showGlobalSpinner({
        message: 'Obtendo cotações de seguro viagem...',
      });

      for (let i = 0; i < 10; i++) {
        response = await TravelInsuranceService.getInsuranceQuote(String(travelInsuranceData.id), QUOTATION_ORIGIN);

        if (checkStatus(response)) {
          setTravelInsuranceQuotation(response?.data.result?.slice(0, 3));

          newGoogleAnalyticsEvent('view_item_list', {
            item_list_name: 'Travel Insurance Quotation',
            item_list_id: 'Travel Insurance Id' + String(travelInsuranceData.id),
            item_list_category: 'Travel Insurance',
            items: response?.data.result?.map((item) => ({
              item_id: item.product_code,
              item_name: item.product_name,
              price: item.total_price,
            })),
          });

          break;
        }
      }

      if (!response || !checkStatus(response)) {
        showSnackbarMessage({
          message: 'Erro ao buscar cotações de seguro viagem',
          severity: 'error',
        });
      }
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao buscar cotações de seguro viagem',
        severity: 'error',
      });
      console.log('Erro ao buscar cotações de seguro viagem', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  const TabLabel = ({ title, quote, index }: TabLabelProps) => {
    return (
      <Box>
        <Typography
          variant='subtitle1'
          gutterBottom
          color={{
            color: index == activeTab ? theme.palette.common.white : theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='h6'
          style={{ color: index == activeTab ? theme.palette.common.white : theme.palette.success.main }}
        >
          {formatCurrency(quote)}
        </Typography>
      </Box>
    );
  };

  const returnTabPanelColor = (index: string) => {
    if (index == activeTab) return hexToRGBA(theme.palette.primary.main, 0.9);
    return theme.palette.common.white;
  };

  useEffect(() => {
    getInsuranceQuotes();
  }, []);

  if (loading || travelInsuranceQuotation?.length == 0) return null;

  return (
    <>
      <ContainerColumn sx={{ px: isMobile ? 0 : '1rem' }}>
        <TabContext value={activeTab}>
          <TabList
            onChange={handleChange}
            aria-label='lab API tabs example'
            sx={{
              flex: 1,
              position: 'sticky',
              mt: isMobile ? 0 : 2,
              zIndex: 999,
              width: isMobile ? '100%' : '60%',
            }}
            centered
            TabIndicatorProps={{ style: { display: 'none' } }}
            component={Paper}
          >
            <Tab
              label={
                <TabLabel
                  title='Básico'
                  quote={travelInsuranceQuotation?.[0].credit_card[0]?.installment_price}
                  index='0'
                />
              }
              value='0'
              icon={
                <Sell
                  fontSize='medium'
                  sx={{ color: '0' === activeTab ? theme.palette.common.white : theme.palette.text.primary }}
                />
              }
              sx={{
                flex: 1,
                backgroundColor: returnTabPanelColor('0'),
                borderRadius: 1,
                transform: activeTab === '0' ? 'scale(1)' : 'scale(0.9)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />

            <Tab
              label={
                <TabLabel
                  title={travelInsuranceQuotation?.length > 2 ? 'Recomendado' : 'Completo'}
                  quote={travelInsuranceQuotation?.[1].credit_card[0]?.installment_price}
                  index='1'
                />
              }
              value='1'
              icon={
                <PriceCheck
                  fontSize='medium'
                  sx={{ color: '1' === activeTab ? theme.palette.common.white : theme.palette.text.primary }}
                />
              }
              sx={{
                flex: 1,
                backgroundColor: returnTabPanelColor('1'),
                borderRadius: 1,
                transform: activeTab === '1' ? 'scale(1)' : 'scale(0.9)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />

            {travelInsuranceQuotation?.length > 2 && (
              <Tab
                label={
                  <TabLabel
                    title='Completo'
                    quote={travelInsuranceQuotation?.[2].credit_card[0]?.installment_price}
                    index='2'
                  />
                }
                value='2'
                icon={
                  <DoneAllIcon
                    fontSize='medium'
                    sx={{ color: '2' === activeTab ? theme.palette.common.white : theme.palette.text.primary }}
                  />
                }
                sx={{
                  flex: 1,
                  backgroundColor: returnTabPanelColor('2'),
                  borderRadius: 1,
                  transform: activeTab === '2' ? 'scale(1)' : 'scale(0.9)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
            )}
          </TabList>
        </TabContext>
        <TabContext value={activeTab}>
          <TabPanel value='0' sx={{ p: 0, flex: 1 }} key={0}>
            <QuotationCard travelInsuranceQuotation={travelInsuranceQuotation?.[0]} />
          </TabPanel>
          <TabPanel value='1' sx={{ p: 0, flex: 1 }} key={1}>
            <QuotationCard travelInsuranceQuotation={travelInsuranceQuotation?.[1]} />
          </TabPanel>
          {travelInsuranceQuotation?.length > 2 && (
            <TabPanel value='2' sx={{ p: 0, flex: 1 }} key={2}>
              <QuotationCard travelInsuranceQuotation={travelInsuranceQuotation?.[2]} />
            </TabPanel>
          )}
        </TabContext>
      </ContainerColumn>
      <Footer>
        <ActionsWrapper ismobile={isMobile}>
          <LoadingButton
            fullWidth={isMobile}
            variant='outlined'
            color='primary'
            onClick={onPrevious}
            size='large'
            startIcon={<ArrowBackIosIcon />}
          >
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth={isMobile}
            color='primary'
            variant='contained'
            size='large'
            onClick={() => handleInsuranceHiring(Number(activeTab))}
            endIcon={<CheckIcon />}
          >
            <Typography>Contratar</Typography>
          </LoadingButton>
        </ActionsWrapper>
      </Footer>
    </>
  );
};
