import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Box, Theme, useMediaQuery } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import DatePicker from '../../../../components/basics/DatePicker/DatePicker';
import { useTravelInsuranceContext } from '../../../../context/insurances/TravelInsuranceContextProvider';
import { TravelInsurance } from '../../../../models/TravelInsurance';
import {
  ActionsWrapper,
  Container,
  CustomCard,
  Footer,
  FormContainer,
  HeaderWrapper,
  Subtitle,
  Title,
} from '../styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: TravelInsurance) => void;
  currentStep: boolean;
}

export const DatesStep = ({ onPrevious, onNext, currentStep }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { travelInsuranceData } = useTravelInsuranceContext();

  const newStartDate = dayjs(new Date());
  const newEndDate = dayjs(new Date()).add(2, 'day');

  const defaultStartDate =
    travelInsuranceData.start_date && dayjs(travelInsuranceData.start_date).isAfter(dayjs(new Date()))
      ? dayjs(travelInsuranceData.start_date)
      : newStartDate;
  const defaultEndDate = travelInsuranceData.end_date ? dayjs(travelInsuranceData.end_date) : newEndDate;

  const [hasSelectedStartDate, setHasSelectedStartDate] = useState(false);
  const [hasSelectedEndDate, setHasSelectedEndDate] = useState(false);

  const [startDate, setStartDate] = useState<Dayjs>(defaultStartDate);

  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const [endDate, setEndDate] = useState<Dayjs>(defaultEndDate);

  const onSubmit = () => {
    if (!startDate || !endDate) {
      return;
    }

    if (startDate.isAfter(endDate)) {
      return;
    }

    let formData = {
      progress_step: 3,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
    };

    onNext(formData);
  };

  const handleDateAccept = (name: string | null) => {
    if (name === 'initial_date') {
      setHasSelectedStartDate(true);
    } else {
      setHasSelectedEndDate(true);
    }
  };

  useEffect(() => {
    if (currentStep) {
      if (!hasSelectedStartDate) {
        setShowStartDatePicker(true);
        return;
      } else {
        setShowStartDatePicker(false);
      }

      if (!hasSelectedEndDate) {
        setShowEndDatePicker(true);
        return;
      } else {
        setShowEndDatePicker(false);
      }
    }
  }, [currentStep, hasSelectedStartDate, hasSelectedEndDate]);

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        setEndDate(startDate.add(2, 'day'));
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <CustomCard ismobile={isMobile}>
        <Container ismobile={isMobile}>
          <HeaderWrapper>
            <Title>Programação da viagem</Title>
            <Subtitle>Selecione a data de início e fim da sua viagem.</Subtitle>
          </HeaderWrapper>
          <FormContainer ismobile={isMobile}>
            <DatePicker
              label='Data Inicial'
              name='initial_date'
              onChange={(newDate) => {
                handleDateAccept('initial_date');
                setStartDate(newDate);
              }}
              minDate={new Date()}
              defaultValue={startDate}
              onAccept={handleDateAccept}
              onClose={() => handleDateAccept('initial_date')}
              openDatePicker={showStartDatePicker}
            />
            <Box style={{ marginTop: 20, width: '100%' }}>
              <DatePicker
                label='Data Final'
                name='final_date'
                onChange={(newDate) => {
                  handleDateAccept('final_date');
                  setEndDate(newDate);
                }}
                minDate={startDate ? startDate.add(2, 'day') : new Date()}
                defaultValue={endDate ?? startDate.add(2, 'day')}
                onAccept={handleDateAccept}
                onClose={() => handleDateAccept('final_date')}
                openDatePicker={showEndDatePicker}
              />
            </Box>
          </FormContainer>
        </Container>
        {/* </CustomCardContent> */}
      </CustomCard>
      <Footer>
        <ActionsWrapper ismobile={isMobile}>
          <LoadingButton
            variant='outlined'
            color='primary'
            onClick={onPrevious}
            size='large'
            fullWidth={isMobile}
            startIcon={<ArrowBackIosIcon />}
          >
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth={isMobile}
            variant='contained'
            color='primary'
            onClick={onSubmit}
            size='large'
            endIcon={<ArrowForwardIosIcon />}
          >
            Avançar
          </LoadingButton>
        </ActionsWrapper>
      </Footer>
    </>
  );
};
