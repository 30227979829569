import { Box } from '@mui/material';
import { useTabContext } from '../context/UserTabsContext';
import { TabAddress } from './TabAddress/TabAddress';
import { TabProfile } from './TabProfile/TabProfile';

export const TabsComponent = () => {
  const { tab } = useTabContext();

  return (
    <Box sx={{ mt: 2 }}>
      {tab === '1' && <TabProfile />}
      {tab === '2' && <TabAddress />}
    </Box>
  );
};
