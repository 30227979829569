import React, { createContext, useContext } from 'react';

interface GoogleAnalyticsContextData {
  newGoogleAnalyticsEvent: (category: string, params: {}) => void;
}

const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextData>({
  newGoogleAnalyticsEvent: () => {},
});

interface MediaQueryProviderProps {
  children: React.ReactNode;
}

export const GoogleAnalyticsContextProvider = ({ children }: MediaQueryProviderProps) => {
  const newGoogleAnalyticsEvent = (category: string, params: {}) => {
    console.log('Google Analytics Event', category, params);

    // @ts-ignore
    if (window.gtag) {
      console.log('encontrou a tag na window');
      // @ts-ignore
      window.gtag('event', category, params);
    }
  };

  return (
    <GoogleAnalyticsContext.Provider
      value={{
        newGoogleAnalyticsEvent,
      }}
    >
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export const useGoogleAnalyticsContext = () => useContext(GoogleAnalyticsContext);
