import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { useAppContext } from '../../../context/GlobalContextProvider';
import { useHomeInsuranceContext } from '../../../context/insurances/HomeInsuranceContextProvider';
import { HomeInsurance } from '../../../models/HomeInsurance';
import { HomeInsuranceService } from '../../../services/HomeInsurance.service';
import { CreditCardData, CreditCardForm } from '../HomeInsurancePaymentForm/HomeInsurancePaymentForm';
import { ActionsWrapper, Container, CustomCard, CustomCardContent, FormContainer, HeaderWrapper } from './styles';

interface Props {
  onPrevious: () => void;
  onNext: (data: HomeInsurance) => void;
}

export const StepFivePayment = ({ onPrevious, onNext }: Props) => {
  const creditCardFormRef = useRef(null);
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage, loading } = useAppContext();
  const { selectedQuotation, homeInsuranceData } = useHomeInsuranceContext();

  const onSubmit = () => {
    creditCardFormRef?.current?.submitForm();
  };

  const handleCreditCardData = async (data: CreditCardData) => {
    let submitData = {
      product_code: selectedQuotation?.cotacao.codigoCotacao,
      number_parcels: data.selectedInstallment,
      credit_card_billing_day: data.billingDate,
      credit_card_holder_name: data.cardData.name,
      credit_card_holder_cpf: data.cardData.cpf,
      credit_card_number: data.cardData.cardNumber,
      credit_card_year: data.cardData.expirationDate.split('/')[1],
      credit_card_month: data.cardData.expirationDate.split('/')[0],
    };

    try {
      showGlobalSpinner({
        message: 'Realizando pagamento...',
      });

      const response = await HomeInsuranceService.insuranceHire(homeInsuranceData.id, submitData);
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao realizar pagamento',
        severity: 'error',
      });
      console.log('Erro ao realizar pagamento', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  return (
    <CustomCard ismobile={ismobile}>
      <CustomCardContent>
        <Container ismobile={ismobile}>
          <HeaderWrapper>
            <Typography variant='h5' textAlign='center'>
              Pagamento
            </Typography>
            <Typography variant='body2' textAlign='center'>
              Informe os dados do cartão de crédito
            </Typography>
          </HeaderWrapper>

          <FormContainer ismobile={ismobile}>
            {/* Payment Form */}
            <CreditCardForm ref={creditCardFormRef} submitForm={handleCreditCardData} />
          </FormContainer>

          <ActionsWrapper ismobile={ismobile}>
            <LoadingButton
              variant='outlined'
              color='primary'
              onClick={onPrevious}
              disabled={loading}
              size='large'
              fullWidth={ismobile}
              startIcon={<ArrowBackIosIcon />}
            >
              Voltar
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={onSubmit}
              disabled={loading}
              loading={loading}
              size='large'
              fullWidth={ismobile}
              endIcon={<ArrowForwardIosIcon />}
            >
              Pagar
            </LoadingButton>
          </ActionsWrapper>
        </Container>
      </CustomCardContent>
    </CustomCard>
  );
};
