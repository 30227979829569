import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AutocompleteTypes } from '../models/Autocomplete';
import { ROUTES } from '../routes/routesList';
import { AutocompleteService } from '../services/Autocomplete.service';
import { checkStatus } from '../utils/api/response';
import { useUserContext } from './UserContextProvider';

interface IAutocompleteContext {
  autocomplete: AutocompleteTypes | null;
  fetchAutocompleteOptions: () => void;
}

interface Props {
  children: ReactNode;
}

const AutocompleteContext = createContext<IAutocompleteContext>({} as IAutocompleteContext);

export const AutocompleteContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [autocomplete, setAutocomplete] = useState<AutocompleteTypes | null>(null);

  const { user } = useUserContext();

  const fetchAutocompleteOptions = async () => {
    try {
      const response = await AutocompleteService.getAutocompleteOptions();
      if (checkStatus(response)) {
        setAutocomplete(response.data.types);
      }
    } catch (error: any) {
      if (error.status === 401) {
        navigate(ROUTES.public.login);
      }
    }
  };

  useEffect(() => {
    if (user) {
      fetchAutocompleteOptions();
    }
  }, [user]);

  return (
    <AutocompleteContext.Provider value={{ autocomplete, fetchAutocompleteOptions }}>
      {children}
    </AutocompleteContext.Provider>
  );
};

export const useAutocompleteContext = () => useContext(AutocompleteContext);
