import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogComponent } from '../../components/basics/DialogComponent/DialogComponent';
import { PageWrapper } from '../../components/basics/PageWrapper/PageWrapper';
import { useAppContext } from '../../context/GlobalContextProvider';
import { HomeInsuranceContextProvider } from '../../context/insurances/HomeInsuranceContextProvider';
import { HomeInsurance } from '../../models/HomeInsurance';
import { ROUTES } from '../../routes/routesList';
import { HomeInsuranceStepper } from '../../sections/@homeInsurance/Stepper/HomeInsuranceStepper';
import { HomeInsuranceService } from '../../services/HomeInsurance.service';
import { checkStatus } from '../../utils/api/response';

export const NewHomeInsurancePage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { showGlobalSpinner, hideGlobalSpinner, showSnackbarMessage, loading } = useAppContext();

  const [showHomeInsuranceModal, setShowHomeInsuranceModal] = useState<boolean>(false);
  const [currentHomeInsurance, setCurrentHomeInsurance] = useState<HomeInsurance>();

  const handleClose = () => {
    navigate('/' + ROUTES.menuItems.newInsurance);
  };

  const handleNewHomeInsurance = async () => {
    setShowHomeInsuranceModal(false);

    //Criar um novo seguro de viagem
    try {
      showGlobalSpinner({
        message: 'Criando seguro residencial...',
      });
      const response = await HomeInsuranceService.createHomeInsurance();
      if (checkStatus(response)) {
        setCurrentHomeInsurance(response?.data?.result);
        //setar na URL o id do seguro de viagem
        window.history.replaceState(null, '', `${ROUTES.forms.newHomeInsurance}/${response?.data?.result.id}`);
      }
    } catch (error: any) {
      showSnackbarMessage({
        message: 'Erro ao criar seguro residencial',
        severity: 'error',
      });
    } finally {
      hideGlobalSpinner();
    }
  };

  const handleContinueHomeInsurance = () => {
    setShowHomeInsuranceModal(false);
    if (currentHomeInsurance) {
      setCurrentHomeInsurance({
        ...currentHomeInsurance,
      });
      //setar na URL o id do seguro de viagem
      window.history.replaceState(null, '', `${ROUTES.forms.newHomeInsurance}/${currentHomeInsurance.id}`);
    }
  };

  const fetchHomeInsuranceById = async () => {
    // ENDPOINT PENDENTE DE DESENVOLVIMENTO
    //HomeInsuranceService.getHomeInsurancesById();

    //Alternativamente farei um filtro pelo ID do seguro de viagem
    try {
      showGlobalSpinner({
        message: 'Aguarde um momento...',
      });

      const response = await HomeInsuranceService.getHomeInsurances();
      if (response?.data?.result.length > 0) {
        setCurrentHomeInsurance(response?.data?.result?.find((homeInsurance: any) => homeInsurance.id === params?.id));
      }
    } catch (error: any) {
      console.log('Erro ao buscar seguro residencial por ID', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  const fetchHomeInsurances = async () => {
    try {
      showGlobalSpinner({
        message: 'Aguarde um momento...',
      });
      const response = await HomeInsuranceService.getHomeInsurances();

      if (response?.data?.result.length > 0) {
        setShowHomeInsuranceModal(true);
        setCurrentHomeInsurance(response?.data?.result?.at(-1));
        //setar na URL o id do seguro de viagem
      } else {
        await handleNewHomeInsurance();
      }
    } catch (error: any) {
      console.log('Erro ao buscar seguros residenciais', error);
    } finally {
      hideGlobalSpinner();
    }
  };

  useEffect(() => {
    //se vier um id fazer o fetch do seguro viagem pelo ID
    if (params?.id) {
      fetchHomeInsuranceById();
    } else {
      //se não vier um id, buscar todos os seguros viagem do usuário e verificar se existe algum em andamento.
      fetchHomeInsurances();
    }
  }, [params]);

  return (
    <>
      <HomeInsuranceContextProvider>
        {!showHomeInsuranceModal && currentHomeInsurance?.id && !loading && (
          <PageWrapper pageTitle='Seguro Residencial' onClose={handleClose}>
            <HomeInsuranceStepper currentHomeInsurance={currentHomeInsurance} />
          </PageWrapper>
        )}
        <DialogComponent
          open={showHomeInsuranceModal}
          title='Seguro Residencial'
          description='Você possui um seguro residencial em preenchimento. Deseja continuar ou criar um novo?'
          confirmText='Continuar'
          cancelText='Novo'
          onDismiss={() => {}}
          onCancel={handleNewHomeInsurance}
          onConfirm={handleContinueHomeInsurance}
        />
      </HomeInsuranceContextProvider>
    </>
  );
};
