import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
// components
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { DialogComponent } from '../../components/basics/DialogComponent/DialogComponent';
import { LoadingIndicator } from '../../components/basics/LoadingIndicator/LoadingIndicator';
import { PasswordStrengthComponent } from '../../components/basics/PasswordStrengthComponent/PasswordStrengthComponent';
import { TextInput } from '../../components/basics/TextInput/TextInput';
import { useGoogleAnalyticsContext } from '../../context/GoogleAnalyticsContextProvider';
import { User } from '../../models/User';
import { ROUTES } from '../../routes/routesList';
import GoogleLoginButton from '../../sections/@auth/GoogleLoginButton';
import { AuthService } from '../../services/Auth.service';
import { checkStatus } from '../../utils/api/response';
import { RegistrationSchema } from '../../utils/forms/auth/formValidationSchemas';
import { strengthColor, strengthIndicator } from '../../utils/forms/auth/password-strength';
import { setInputErrorsFromApi } from '../../utils/forms/form-errors';

export interface PasswordStrength {
  level: number;
  label: string;
  color: string;
}

const parseQueryString = (queryString: string) => {
  const params = {};
  if (queryString) {
    queryString
      .substring(1)
      .split('&')
      .forEach((param) => {
        const keyValue = param.split('=');
        const key = decodeURIComponent(keyValue[0]);
        const value = keyValue.length > 1 ? decodeURIComponent(keyValue[1]) : '';
        params[key] = value;
      });
  }
  return params;
};

const queryString = window.location.search.substring(1);
const params = parseQueryString(queryString);

export default function RegisterForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const invitationCode = parseQueryString(location.search)['convite'];
  const [searchParams] = useSearchParams();
  const websiteQuote = searchParams.get('quote');
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();

  const [level, setLevel] = useState<PasswordStrength | null>(null);

  const [accountCreatedMessage, setAccountCreatedMessage] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(RegistrationSchema),
  });

  const passwordWatch = useWatch({
    control,
    name: 'password',
  });

  const handleNavigateToLogin = () => {
    navigate(ROUTES.public.login, { replace: true });
  };

  const onSubmit = async (data: User) => {
    setLoading(true);
    const registrationData = { ...data, ...{ invitation_code: invitationCode, website_quote: websiteQuote } };

    try {
      const response = await AuthService.register(registrationData);
      if (checkStatus(response)) {
        newGoogleAnalyticsEvent('sign_up', { method: 'email' });
        setAccountCreatedMessage(response.data.message);
      }
    } catch (error: any) {
      setInputErrorsFromApi(setError, error?.data.errors);
    }

    setLoading(false);
  };

  const changePassword = (value: any) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    if (passwordWatch) {
      changePassword(passwordWatch);
    }
  }, [passwordWatch]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
        <GoogleLoginButton invitationCode={invitationCode} websiteQuote={websiteQuote} />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack columnGap={3} rowGap={3} sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextInput control={control} name='first_name' label='Nome' type='text' required fullWidth size='medium' />
            <TextInput
              control={control}
              name='last_name'
              label='Sobrenome'
              type='text'
              required
              fullWidth
              size='medium'
            />
          </Stack>
          <TextInput
            control={control}
            name='email'
            label='E-mail'
            type='email'
            required
            fullWidth
            autoComplete='email'
            size='medium'
            errorMessage={errors.email?.message?.toString()}
          />
          <TextInput
            control={control}
            name='password'
            label='Senha'
            type='password'
            required
            fullWidth
            size='medium'
            autoComplete='new-password'
            errorMessage={errors.password?.message?.toString()}
            onBlur={() => setLevel(null)}
          />
          {level && <PasswordStrengthComponent passwordStreght={level} />}
          <TextInput
            control={control}
            name='password_confirmation'
            label='Confirmação de Senha'
            type='password'
            required
            size='medium'
            fullWidth
            autoComplete='new-password'
            errorMessage={errors.password_confirmation?.message?.toString()}
          />
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={loading}
            loadingIndicator={loading && <LoadingIndicator />}
          >
            Cadastrar
          </LoadingButton>
        </Stack>
      </form>
      {accountCreatedMessage && (
        <DialogComponent
          open={!!accountCreatedMessage}
          title='Conta criada com sucesso!'
          description={accountCreatedMessage}
          onDismiss={handleNavigateToLogin}
          confirmText='Ir para o Login'
          onConfirm={handleNavigateToLogin}
        />
      )}
    </>
  );
}
