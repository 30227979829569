import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const QuotationCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 4rem 1rem;
  flex: 1;
  width: 100%;
`;

export const LabelText = styled(Typography)`
  font-weight: 500;
`;
