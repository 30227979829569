import { ArrowForwardIos } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SelectOption } from '../../../../../components/basics/ControlledAutocomplete/ControlledAutocomplete';
import { useLogServiceHook } from '../../../../../hooks/useLogServiceHook';
interface DestinyComponentProps {
  selectOptions: SelectOption[];
  destinySelected: string;
  setDestinySelected: (key: string) => void;
  setCurrentStep: (step: number) => void;
}

export const DestinyComponent = ({
  selectOptions,
  destinySelected,
  setDestinySelected,
  setCurrentStep,
}: DestinyComponentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logUser } = useLogServiceHook();

  const handleSelectedDestiny = async (key: string) => {
    logUser({
      url: 'cotacao-seguro-viagem',
      log_data: { component: 'DestinyComponent', destiny: key },
    });
    setDestinySelected(key);
  };

  return (
    <Grid container flex={1} sx={{ p: '1.5rem' }}>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h3' color='text' sx={{ textAlign: 'center', width: '100%' }} gutterBottom>
          Escolha o destino da sua viagem
        </Typography>
        <Typography variant={isMobile ? 'subtitle2' : 'h6'} color='text' gutterBottom textAlign='center' mt={2}>
          Selecione para qual região você deseja viajar
        </Typography>
        <Divider sx={{ width: '100%', my: '1rem' }} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Grid container gap={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          {selectOptions.map((item, index) => (
            <Grid item sm={5} md={3} lg={1.2} sx={{ minWidth: isMobile ? '40%' : 'auto' }} key={index}>
              <DestinyCard
                destiny={item}
                onSelected={(key) => handleSelectedDestiny(key)}
                selected={item.key === destinySelected}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'flex-end' : 'flex-start',
          alignItems: 'center',
          mt: { xs: 2 },
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={() => setCurrentStep(1)}
          size={isMobile ? 'medium' : 'large'}
          endIcon={<ArrowForwardIos />}
          disabled={destinySelected === ''}
          sx={{ marginBottom: '1rem' }}
          fullWidth={isMobile ? true : false}
        >
          Próximo
        </Button>
      </Grid>
    </Grid>
  );
};

interface DestinyCardProps {
  destiny: SelectOption;
  onSelected: (key: string) => void;
  selected: boolean;
}

const ICONS = {
  north_america: '/assets/icons/north-america-logo.png',
  south_america: '/assets/icons/south-america-logo.png',
  caribbean_mexico: '/assets/icons/mexico-caribean-logo.png',
  europe: '/assets/icons/europe-logo.png',
  africa: '/assets/icons/africa-logo.png',
  asia: '/assets/icons/asia-logo.png',
  oceania: '/assets/icons/oceania-logo.png',
  middle_east: '/assets/icons/middle-east-logo.png',
};

const DestinyCard = ({ destiny, selected, onSelected }: DestinyCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        cursor: 'pointer',
        transition: '0.5s ease-in-out',
        minWidth: '90%',

        minHeight: '100px',
        backgroundColor: selected ? 'rgba(230, 28, 110, 0.1) !important' : 'transparent',

        border: 'solid',
        borderWidth: '2px',
        borderColor: selected ? 'rgba(230, 28, 110, 0.5) !important' : 'transparent',
        borderRadius: '6px',

        transform: selected ? 'scale(1.2)' : 'scale(1)',

        '&:hover': {
          transform: selected ? 'scale(1.1)' : 'scale(1.1)',
        },
      }}
      className={`destiny-card ${destiny.key}`}
    >
      {selected && (
        <Box
          sx={{
            position: 'absolute',
            top: '15%',
            right: '10%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
          </Box>
        </Box>
      )}
      <ButtonBase
        onClick={() => onSelected(destiny.key)}
        style={{
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Box mb={1}>
            <img
              src={ICONS[destiny.key]}
              alt={`${destiny.value} icon`}
              style={{
                maxHeight: '40px',
                minHeight: '40px',
                margin: 'auto',
                display: 'block',
              }}
            />
          </Box>
          <Typography variant={selected ? 'body1' : 'body2'} color='common.black' flexWrap='wrap'>
            {destiny.value}
          </Typography>
        </CardContent>
      </ButtonBase>
    </Box>
  );
};
