import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { formatCurrency } from '../../../../utils/methods';

interface Props {
  cashbackValues?: any;
}

export const AvailableCashbackTab = ({ cashbackValues }: Props) => {
  return (
    <>
      {cashbackValues?.cashback?.levels && (
        <Card elevation={3}>
          <CardHeader title='Cashback' />
          <CardContent>
            <Box sx={{ textAlign: 'center' }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant='body1' sx={{ color: 'text.secondary' }}>
                    Total em bonificações disponível
                  </Typography>
                  <Typography variant='h4'>{formatCurrency(cashbackValues?.cashback?.available)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='body1' sx={{ color: 'text.secondary' }}>
                    Total em bonificações projetado
                  </Typography>
                  <Typography variant='h4'>{formatCurrency(cashbackValues?.cashback?.blocked)}</Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};
