import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Grid, InputLabel, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import DatePicker from '../../../../../components/basics/DatePicker/DatePicker';
import { useLogServiceHook } from '../../../../../hooks/useLogServiceHook';

interface DatesComponentProps {
  getValues: any;
  errors: any;
  setCurrentStep: (step: number) => void;
  setValue: any;
}

export const DatesComponent = ({ getValues, errors, setCurrentStep, setValue }: DatesComponentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logUser } = useLogServiceHook();

  const newStartDate = dayjs(new Date()).add(1, 'day');
  const newEndDate = dayjs(new Date()).add(3, 'day');

  const [startDate, setStartDate] = useState<Dayjs>(newStartDate);
  const [endDate, setEndDate] = useState<Dayjs>(newEndDate);

  const [hasSelectedStartDate, setHasSelectedStartDate] = useState(false);
  const [hasSelectedEndDate, setHasSelectedEndDate] = useState(false);

  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const [endDateError, setEndDateError] = useState<string>('');

  const isDatesValid = () => {
    if (!startDate || !endDate) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!startDate || !endDate) {
      return;
    }

    if (startDate.isAfter(endDate)) {
      setEndDateError('A data final deve ser maior que a data inicial');
      return;
    } else if (endDate.diff(startDate, 'days') < 2) {
      setEndDateError('A viagem deve ter no mínimo 2 dias de duração');
      return;
    } else {
      setEndDateError('');
    }

    setValue('start_date', startDate);
    setValue('end_date', endDate);

    logUser({
      url: 'cotacao-seguro-viagem',
      log_data: { component: 'DatesComponent', startDate, endDate },
    });

    setCurrentStep(2);
  };

  const handleDateAccept = (name: string | null) => {
    if (name === 'initial_date') {
      setHasSelectedStartDate(true);
      setEndDateError('');
      setEndDate(startDate.add(2, 'day'));
    } else {
      setEndDateError('');
      setHasSelectedEndDate(true);
    }
  };

  useEffect(() => {
    if (!hasSelectedStartDate) {
      setShowStartDatePicker(true);
      return;
    } else {
      setShowStartDatePicker(false);
    }

    if (!hasSelectedEndDate) {
      setShowEndDatePicker(true);
      return;
    } else {
      setShowEndDatePicker(false);
    }
  }, [hasSelectedStartDate, hasSelectedEndDate]);

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        setEndDate(startDate.add(2, 'day'));
      }
    }
  }, [startDate, endDate]);

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', p: '1.5rem' }}>
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h3' color='text' textAlign='center'>
          Data da Viagem
        </Typography>
        <Typography variant='h6' color='text' mt={2}>
          Selecione a data de início e fim da sua viagem
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Data de Início</InputLabel>
        <DatePicker
          label='Data de Início'
          name='initial_date'
          onChange={(newDate) => {
            handleDateAccept('initial_date');
            setStartDate(newDate);
          }}
          minDate={new Date()}
          defaultValue={startDate}
          onAccept={handleDateAccept}
          onClose={() => handleDateAccept('initial_date')}
          openDatePicker={showStartDatePicker}
          hideTextFieldLabel
        />
        <Box style={{ marginTop: 20, width: '100%' }}>
          <InputLabel>Data de Fim</InputLabel>
          <DatePicker
            label='Data de Fim'
            name='final_date'
            onChange={(newDate) => {
              handleDateAccept('final_date');
              setEndDate(newDate);
            }}
            minDate={startDate ? startDate.add(2, 'day') : new Date()}
            defaultValue={endDate}
            onAccept={handleDateAccept}
            onClose={() => handleDateAccept('final_date')}
            openDatePicker={showEndDatePicker}
            hideTextFieldLabel
          />
        </Box>
        <Typography variant='caption' color='error' style={{ marginTop: 5 }}>
          {endDateError}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'flex-end' : 'flex-start',
        }}
      >
        <Stack columnGap={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setCurrentStep(0)}
            size={isMobile ? 'medium' : 'large'}
            startIcon={<ArrowBackIos />}
            fullWidth={isMobile}
          >
            Voltar
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={() => onSubmit()}
            size={isMobile ? 'medium' : 'large'}
            endIcon={<ArrowForwardIos />}
            disabled={!isDatesValid()}
            fullWidth={isMobile}
          >
            Próximo
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
