import { Button, Typography } from '@mui/material';

interface Props {
  onClick: () => void;
}

export const GoogleLoginButtonMobile = ({ onClick }: Props) => {
  return (
    <Button
      onClick={onClick}
      style={{
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <img
        src='/assets/icons/googlex48.png'
        alt='Google Icon'
        style={{
          marginRight: '10px',
          width: '20px',
          height: '20px',
        }}
      />
      <Typography variant='body1' textTransform='none'>
        Fazer login com o Google
      </Typography>
    </Button>
  );
};
