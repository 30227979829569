export function setInputErrorsFromApi<T>(setError, errorsObject = {}) {
  let formError: string;

  if (typeof errorsObject === 'string') {
    formError = errorsObject;
  } else {
    Object.keys(errorsObject).forEach((key) => {
      const keyOf = key as FieldName<T>;
      if (keyOf === '__all__') {
        formError = errorsObject[key][0];
      } else {
        setError(keyOf, { message: errorsObject[key][0], type: 'manual' }, { shouldFocus: true });
      }
    });
  }
  if (formError) return formError;
}
