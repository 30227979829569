import LoginIcon from '@mui/icons-material/Login';
import { Box, Button, Link, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid'; // Importar a função uuidv4
import Logo from '../../../components/logo';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/localstorageKeys';
import { ROUTES } from '../../../routes/routesList';
import { TravelInsuranceQuotationIframe } from './QuotationSteps/QuotationCards';

const IframeWrapperContainer = styled(Box)`
  height: calc(100vh - 80px); // Altura da tela menos a altura da toolbar (80px
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ToolbarContainer = styled(Toolbar)`
  background-color: #0e0f1f;
  display: flex;
  justify-content: space-between; /* Alinhar o logo à esquerda e o botão à direita */
  padding: 20px;
  height: 80px;
`;

export const PublicTravelInsuranceQuotation = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const LOGO = 'https://91seguros.com.br/wp-content/uploads/2024/03/cropped-91-icon-p-laranja-png-e1710934798102.png';

  const LINK_COVERAGES = 'https://91seguros.com.br/seguro-viagem/coberturas-e-beneficios/';
  const FREQUENT_QUESTIONS = 'https://91seguros.com.br/faq/';
  const BLOG = 'https://91seguros.com.br/nosso-blog/';

  const [temporaryUser, setTemporaryUser] = useState<string>('');

  const handleNavigateToQuotation = () => {
    navigate('/' + ROUTES.menuItems.app);
  };

  useEffect(() => {
    // Buscar o usuário temporário do local storage, se não existir, criar um usuário temporário para logar as suas ações na cotação
    let user = localStorage.getItem(LOCAL_STORAGE_KEYS.temporaryUser);
    if (user) {
      console.log('usuário existente', user);
      setTemporaryUser(JSON.parse(user));
    } else {
      const newTemporaryUser = uuidv4();
      console.log('usuário novo', newTemporaryUser);
      localStorage.setItem(LOCAL_STORAGE_KEYS.temporaryUser, JSON.stringify(newTemporaryUser));
      setTemporaryUser(newTemporaryUser);
    }
  }, []);

  return (
    <>
      <ToolbarContainer>
        {/* <img src={LOGO} title='91_logo' alt='91_logo' /> */}
        <Logo />
        <Stack gap={4} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {!isMobile && (
            <>
              <Link
                href={LINK_COVERAGES}
                sx={{
                  textDecoration: 'none',
                  color: theme.palette.common.white,
                  ':hover': {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                <Typography variant='h6'>Coberturas e Benefícios</Typography>
              </Link>
              <Link
                href={FREQUENT_QUESTIONS}
                sx={{
                  textDecoration: 'none',
                  color: theme.palette.common.white,
                  ':hover': {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                <Typography variant='h6'>Dúvidas Frequentes</Typography>
              </Link>
              <Link
                href={BLOG}
                color='inherit'
                sx={{
                  textDecoration: 'none',
                  color: theme.palette.common.white,
                  ':hover': {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                <Typography variant='h6'>Blog</Typography>
              </Link>
            </>
          )}
          <Button
            variant='contained'
            endIcon={<LoginIcon />}
            onClick={handleNavigateToQuotation}
            sx={{
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              ':hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,
              },
            }}
          >
            <Typography variant='h6' textTransform='none'>
              Acesso
            </Typography>
          </Button>
        </Stack>
      </ToolbarContainer>
      <IframeWrapperContainer>
        <TravelInsuranceQuotationIframe />
      </IframeWrapperContainer>
    </>
  );
};
