import { Container, Theme, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DocumentsDialogComponent } from '../../components/DocumentsDialogComponent/DocumentsDialogComponent';
import { useUserContext } from '../../context/UserContextProvider';
import { NewInsurancesList } from '../../sections/@newInsurances/NewInsurancesList/NewInsurancesList';

import { PageTitle } from '../../theme/GlobalStyledComponents';

export default function NewInsurancesPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { user } = useUserContext();
  const [showCpfDialog, setShowCpfDialog] = useState(false);

  const handleConfirmCpfDialog = () => {
    setShowCpfDialog(false);
  };

  useEffect(() => {
    if (user && !user?.client.cpf) {
      setShowCpfDialog(true);
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Novo Seguro</title>
      </Helmet>
      <Container>
        <PageTitle isMobile={isMobile}>Contratar novo seguro</PageTitle>
        <Typography variant='body2' gutterBottom sx={{ mb: 4 }}>
          Selecione o tipo de seguro que deseja contratar.
        </Typography>
        <NewInsurancesList />
      </Container>
      <DocumentsDialogComponent
        description='Precisamos saber um pouco mais sobre você.'
        confirmText='Confirmar'
        open={showCpfDialog}
        onConfirm={handleConfirmCpfDialog}
      />
    </>
  );
}
