import { LOCAL_STORAGE_KEYS } from '../localstorage/localstorageKeys';
import { LogData, LogService } from '../services/Log.service';

export const useLogServiceHook = () => {
  const getTemporaryUser = () => {
    const temporaryUser = localStorage.getItem(LOCAL_STORAGE_KEYS.temporaryUser);
    if (temporaryUser) {
      return JSON.parse(temporaryUser);
    }
    return null;
  };

  const logUser = async (data: Partial<LogData>) => {
    try {
      let user = getTemporaryUser();

      if (user) {
        let submitData = {
          ...data,
          user_identifier: user,
        };

        const response = await LogService.log(submitData);
        console.log('Response After Log', response?.data);
      }
    } catch (error: any) {
      console.log('Error in useLogServiceHook.log', error);
    }
  };

  return {
    logUser,
  };
};
