import PropTypes from 'prop-types';
// @mui
import { AppBar, Divider, IconButton, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import { useNavigate } from 'react-router-dom';
import Logo from '../../../components/logo';
import { useUserContext } from '../../../context/UserContextProvider';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 84;

const HEADER_DESKTOP = 74;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useUserContext();

  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <StyledRoot>
      <StyledToolbar>
        {!ismobile && (
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <Iconify icon='eva:menu-2-fill' />
          </IconButton>
        )}
        <Stack flex={1} flexDirection='row' justifyContent='space-between'>
          {ismobile ? (
            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Logo />
              <Typography variant='h4' color={theme.palette.text.primary} sx={{ ml: 2 }}>
                Olá {user?.first_name}
              </Typography>
            </Stack>
          ) : (
            <Stack />
          )}
          <Stack direction='row-reverse' alignItems='center' spacing={3}>
            <AccountPopover />
            <NotificationsPopover />
          </Stack>
        </Stack>
      </StyledToolbar>
      <Divider />
    </StyledRoot>
  );
}
