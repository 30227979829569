import { Box, CardContent, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

interface IContainerProps {
  ismobile?: boolean;
  width?: string;
}

export const Container = styled(Box).attrs({
  gap: 1,
})<IContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: ${({ ismobile }) => (ismobile ? 'flex-start' : 'center')};
  width: ${({ ismobile }) => (ismobile ? '100%' : '80%')};
  max-width: 100%;
`;

export const FormContainer = styled(Box).attrs({
  gap: 4,
})<IContainerProps>`
  display: flex;
  flex: 1;
  width: ${({ width, ismobile }) => (ismobile ? '100%' : width ? width : '80%')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ ismobile }) => (ismobile ? '0rem' : '2rem')};
  margin-bottom: ${({ ismobile }) => (ismobile ? '0rem' : '2rem')};
`;

export const ActionsWrapper = styled(Box).attrs({
  gap: 2,
})<IContainerProps>`
  display: flex;
  flex-direction: row;
  width: ${({ ismobile }) => (ismobile ? '100%' : '80%')};
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const HeaderWrapper = styled(Stack).attrs({
  spacing: 2,
})`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const CustomCard = styled(Box)<IContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${({ ismobile: ismobile }) => ismobile && '0 1rem 0 1rem'};
`;

export const CustomCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SignatureWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
  gutterBottom: true,
  textAlign: 'center',
})``;

export const Subtitle = styled(Typography).attrs({
  variant: 'body2',
  gutterBottom: true,
  textAlign: 'center',
})``;
