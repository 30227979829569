import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal, Theme, useMediaQuery, useTheme } from '@mui/material';
import { PageTitle } from '../../../theme/GlobalStyledComponents';
import { Container, PageBody, PageHeader, PageTitleMobile } from './styles';

interface Props {
  children: React.ReactNode;
  modalOnly?: boolean;
  pageTitle: string;
  onClose: () => void;
}

export const PageWrapper = ({ pageTitle, modalOnly, children, onClose }: Props) => {
  const theme = useTheme();
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {ismobile || modalOnly ? (
        <Modal
          open={true}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: theme.palette.background.default,
              },
            },
          }}
        >
          <Container>
            <PageHeader>
              <PageTitleMobile>{pageTitle}</PageTitleMobile>
              <IconButton onClick={handleClose} color='primary'>
                <CloseIcon />
              </IconButton>
            </PageHeader>
            <PageBody>{children}</PageBody>
          </Container>
        </Modal>
      ) : (
        <Container>
          <PageTitle sx={{ ml: '2rem' }}>{pageTitle}</PageTitle>
          {children}
        </Container>
      )}
    </>
  );
};
