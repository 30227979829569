import { Check, Close } from '@mui/icons-material';
import { Button, DialogActions, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { capitalize } from 'lodash';
import { useState } from 'react';

interface Props {
  label?: string;
  name: string;
  onChange: (date: Dayjs) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  defaultValue?: Dayjs;
  textFieldValue?: string;
  hideTextFieldLabel?: boolean;
  maxDate?: Date | Dayjs | undefined;
  minDate?: Date | Dayjs | undefined;
  onError?: (name: string) => void;
  onAccept?: (name: string) => void;
  onClose?: () => void;
  openDatePicker?: boolean;
}

export default function DatePicker({
  label,
  name,
  onChange,
  variant,
  hideTextFieldLabel,
  defaultValue,
  maxDate,
  minDate,
  onError,
  onAccept,
  onClose,
  openDatePicker,
}: Props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Dayjs | null>(
    dayjs(defaultValue ? dayjs(defaultValue, 'DD/MM/YYYY') : new Date()).locale('pt-br')
  );

  const MyActionBar = ({ onAccept, onCancel, onClear, onSetToday }: PickersActionBarProps) => {
    return (
      <DialogActions
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          margin: 1,
        }}
      >
        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button onClick={onCancel} variant='outlined' startIcon={<Close />}>
            Cancelar
          </Button>
          <Button onClick={onAccept} variant='contained' startIcon={<Check />}>
            Confirmar
          </Button>
        </Stack>
      </DialogActions>
    );
  };

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <MobileDatePicker
        label={
          <Typography variant='h5' sx={{ color: 'primary.main', textTransform: 'initial' }}>
            {capitalize(label)}
          </Typography>
        }
        open={openDatePicker || open}
        onOpen={() => setOpen(true)}
        value={value}
        onClose={() => {
          setOpen(false);
          onClose && onClose();
        }}
        onChange={(newValue) => {
          setOpen(false);
          setValue(newValue);
          newValue && onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} variant={variant || 'outlined'} label={hideTextFieldLabel ? '' : label} name={name} />
        )}
        maxDate={maxDate}
        minDate={minDate}
        onError={() => onError && onError(name)}
        onAccept={() => onAccept && onAccept(name)}
        components={{
          ActionBar: MyActionBar,
        }}
        toolbarFormat='DD/MM/YYYY'
      />
    </Stack>
  );
}
