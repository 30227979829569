import { Theme, useMediaQuery } from '@mui/material';
import { default as React } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../../components/basics/PageWrapper/PageWrapper';
import { ROUTES } from '../../../routes/routesList';
import { DocumentContainer, Paragraph, ParagraphTitle } from './styles';

export default function FrequenltyAskedPage() {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/' + ROUTES.menuItems.options);
  };

  return (
    <PageWrapper pageTitle='Perguntas Frequentes' onClose={handleClose}>
      <Helmet>
        <title> Dashboard | Perguntas Frequentes </title>
      </Helmet>

      <DocumentContainer ismobile={ismobile}>
        <ParagraphTitle>O que o seguro de viagem cobre?</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Além das despesas médicas e legais, o seguro de viagem ou assistência em viagem pode cobrir perda, extravio ou
          roubo de bagagem, overbooking de passagens aéreas ou quartos de hotel, repatriação médica de emergência e
          algumas particularidades específicas de cada companhia de seguro de viagem.
        </Paragraph>
        <ParagraphTitle>Qual é o tipo mais importante de seguro viagem? </ParagraphTitle>
        <Paragraph>
          É o tipo mais importante de cobertura e aquele que você deve prestar mais atenção ao escolher o seguro.
          Dependendo do tipo, as despesas são pagas pela seguradora ou você é reembolsado para despesas médicas. O valor
          que o seguro cobre geralmente é de acordo com a quantia que você paga. Leia atentamente as cláusulas do
          contrato para saber o que está coberto e o que não está. Algumas condições médicas como problemas de coração
          ou doenças graves devem ser avisadas antes de contratar o seguro viagem. E claro, pessoas que praticarão
          esportes ou atividades radicais devem verificar se essas atividades estão cobertas.
        </Paragraph>
        <ParagraphTitle> É necessário imprimir o seguro viagem?</ParagraphTitle>
        <Paragraph>
          Se a sua dúvida é "devo imprimir o voucher do seguro viagem", a recomendação é de que sim. Por mais que ele
          seja oferecido digitalmente, levá-lo impresso é sempre uma opção mais segura, especialmente para passar em
          pontos de imigração.{' '}
        </Paragraph>
        <ParagraphTitle>O que deve conter no seguro viagem? </ParagraphTitle>
        <Paragraph>
          <ul style={{ marginLeft: '40px' }}>
            <li>
              Despesas médico-hospitalares (DMH) ou odontológicas ocasionadas de acidente pessoal ou doença súbita
            </li>
            <li>Regresso sanitário, também chamado de repatriação</li>
            <li>Traslado médico</li>
            <li>Traslado do corpo</li>
            <li>Morte em viagem</li>
            <li>Invalidez permanente total ou parcial por acidente durante viagem</li>
          </ul>{' '}
        </Paragraph>
        <ParagraphTitle>Quanto tempo antes da viagem preciso fazer o seguro viagem? </ParagraphTitle>
        <Paragraph>
          A primeira dica para quem está interessado em um seguro viagem é fechar contrato com antecedência e,
          preferencialmente, assim que adquirir o bilhete aéreo, ou, então, até três dias antes da viagem. Assim, você
          já ficará protegido e sem preocupações.{' '}
        </Paragraph>
        <ParagraphTitle>Como escolher o seguro viagem? </ParagraphTitle>
        <Paragraph>
          <b>Como comprovar seguro viagem?</b> Para comprovar é muito simples. Após a compra, você receberá a apólice do
          seguro viagem no e-mail informado, pode imprimir ou deixar acessível na caixa de e-mail. Ao viajar, caso o
          agente da imigração solicite a apresentação do seguro viagem, basta apresentar a apólice.{' '}
        </Paragraph>

        <ParagraphTitle>Quais as vantagens do seguro viagem internacional? </ParagraphTitle>
        <Paragraph>
          As vantagens de viajar para o exterior com um seguro viagem internacional são inúmeras. Considere que você
          poderá contar com cobertura médica em caso de acidentes ou doenças, além de assistência 24h para eventos, como
          atraso ou cancelamento de voo ou, ainda, extravio de bagagens.{' '}
        </Paragraph>

        <ParagraphTitle>O que precisa ter no seguro viagem para Europa? </ParagraphTitle>
        <Paragraph>
          O seguro viagem Europa necessariamente precisa ter uma cobertura mínima de 30 mil euros em despesas médicas
          hospitalares. Esse valor é exigido pela imigração como cobertura mínima.{' '}
        </Paragraph>

        <ParagraphTitle>Como saber se o seguro viagem é válido? </ParagraphTitle>
        <Paragraph>
          O seguro é válido por um período pré- determinado, geralmente do primeiro ao último dia de viagem, e é
          informado pelo viajante no momento da contratação. As seguradoras mantêm centrais de atendimento disponíveis
          24h por dia, 7 dias por semana, e inúmeras formas de contatá-la (telefone, e-mail, whatsapp, aplicativo).{' '}
        </Paragraph>

        <ParagraphTitle>Pode ter 2 seguros viagem? </ParagraphTitle>
        <Paragraph>
          Sim. Você pode contratar quantos seguros quiser e cada seguradora efetivará a indenização de acordo com o
          valor contratado. Porém, as seguradoras costumam solicitar informações referentes a seguros existentes no
          momento da proposta e você deve informar corretamente.{' '}
        </Paragraph>

        <ParagraphTitle>Como funciona o seguro de viagem? </ParagraphTitle>
        <Paragraph>
          O seguro viagem prevê que o viajante pague todas as despesas médicas do próprio bolso e depois solicite o
          reembolso à seguradora (dentro dos limites de valores estabelecidos em contrato).{' '}
        </Paragraph>

        <ParagraphTitle>Quanto tempo dura o seguro viagem? </ParagraphTitle>
        <Paragraph>
          A maioria das seguradoras oferece o seguro viagem anual com coberturas sem limite de número de viagens, que
          funcionam 365 dias ao ano. Porém, cada viagem deve ter de 30 a 60 dias máximos de duração, a depender do
          modelo de plano contratado.{' '}
        </Paragraph>

        <ParagraphTitle>Qual é a diferença entre seguro de viagem e assistência em viagem?</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O seguro de viagem é feito através do reembolso do dinheiro que o segurado tem que gastar em despesas médicas,
          despesas legais, fianças e tudo mais pelo qual ele/ela está segurado(a). A assistência em viagem, por outro
          lado, cuida das despesas direta e imediatamente sem a necessidade de o cliente gastar dinheiro de seu próprio
          bolso. A função do seguro de viagem e da assistência em viagem é simples: proteger você em caso de qualquer
          contingência durante uma viagem.
        </Paragraph>
        <ParagraphTitle>O que é bônus 91 e como funciona o critério de bonificação?</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          O bônus 91 funciona quando você contrata o plano e indica para seus amigos. Ao decorrer das indicações, você
          vai acumulando pontos, podendo chegar a um desconto de até 91%.
        </Paragraph>
        <ParagraphTitle>Quais documentos preciso pra fazer a cotação?</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          Para realizar uma cotação é rápido. Você faz tudo pelo app 91, preenchendo o nosso formulário, e podendo
          realizar a contratação através do app.
        </Paragraph>
        <ParagraphTitle>Quem é o segurado?</ParagraphTitle>
        <Paragraph ismobile={ismobile}>
          É a pessoa física que optar pelo seguro oferecido pela seguradora e que tenha aderido à apólice (contrato) do
          seguro mediante pagamento das parcelas (prêmio) e que possua no mínimo 18 (dezoito) anos de idade.
        </Paragraph>
      </DocumentContainer>
    </PageWrapper>
  );
}
