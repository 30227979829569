import HelpIcon from '@mui/icons-material/Help';
import { Card, CardContent, CardHeader, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { ContainerRowCentered } from '../../../../theme/GlobalStyledComponents';
import { HelpDialog } from '../../HelpDialog/HelpDialog';
import { CashbackCard } from './LevelCard/LevelCard';

interface Props {
  cashbackValues?: any;
}

export const BonusLevelsTab = ({ cashbackValues }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down('sm'));

  const [cashbackHelpDialog, setCashbackHelpDialog] = useState(false);

  const handleClickCashback = () => {
    setCashbackHelpDialog(!cashbackHelpDialog);
  };

  const summaryCardTitle = (
    <ContainerRowCentered>
      <Typography variant='h5'>Níveis de Bonificação</Typography>
      <IconButton onClick={handleClickCashback}>
        <HelpIcon color='primary' fontSize='small' />
      </IconButton>
    </ContainerRowCentered>
  );

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          title={summaryCardTitle}
          subheader='Você possui 5 níveis de bonificação, podendo sacar os valores que constam como "Disponíveis"'
          subheaderTypographyProps={{ variant: 'body2' }}
        />
        <CardContent>
          <Grid container direction='row' spacing={isMobile ? 0 : 4}>
            {Object.keys(cashbackValues?.cashback?.levels)?.map((level, index: number) => (
              <Grid item xs={12} md={6} lg={4} xl={2.4} key={index}>
                <CashbackCard
                  key={level}
                  level={level}
                  blocked={cashbackValues?.cashback?.levels[level].blocked}
                  available={cashbackValues?.cashback?.levels[level].available}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      <HelpDialog
        cashbackValues={cashbackValues}
        handleClickCashback={handleClickCashback}
        cashbackHelpDialog={cashbackHelpDialog}
      />
    </>
  );
};
