import ThemeProvider from './theme';
// components
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AutocompleteContextProvider } from './context/AutocompleteContextProvider';
import { AppProvider } from './context/GlobalContextProvider';
import { GoogleAnalyticsContextProvider } from './context/GoogleAnalyticsContextProvider';
import { MediaQueryProvider } from './context/MediaQueryContextProvider';
import { UserContextProvider } from './context/UserContextProvider';
import AppRoutes from './routes/index.routes';

export default function App() {
  return (
    <GoogleAnalyticsContextProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MediaQueryProvider>
            <ScrollToTop />
            <StyledChart />
            <UserContextProvider>
              <AutocompleteContextProvider>
                <AppProvider>
                  <AppRoutes />
                </AppProvider>
              </AutocompleteContextProvider>
            </UserContextProvider>
          </MediaQueryProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleAnalyticsContextProvider>
  );
}
