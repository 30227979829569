import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useState } from 'react';
import { useGoogleAnalyticsContext } from '../../../context/GoogleAnalyticsContextProvider';
import { useUserContext } from '../../../context/UserContextProvider';

export const ReferralLink = () => {
  const [copyLinkButtonLabel, setCopyLinkButtonLabel] = useState('Copiar Link');
  const { user } = useUserContext();
  const { newGoogleAnalyticsEvent } = useGoogleAnalyticsContext();

  const message = `Estou te indicando para a 91 Seguros, a corretora 100% digital onde você pode economizar até 91% em seus seguros! ${user?.client.link_hash}`;

  const handleCopyButtonClick = () => {
    newGoogleAnalyticsEvent('share', {
      method: 'copy_link',
    });

    navigator.clipboard.writeText(message);
    setCopyLinkButtonLabel('Link copiado!');
    setTimeout(() => {
      setCopyLinkButtonLabel('Copiar Link');
    }, 5000);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <Box sx={{ paddingTop: 2, textAlign: 'center' }}>
            <Box sx={{ mb: 0.5 }}>{<LinkIcon sx={{ fontSize: '60px' }} color='primary' />}</Box>
            <Box>
              <Typography variant='h6'>Seu link de indicação</Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {user?.client.link_hash}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly', my: 3 }}>
                <Button
                  variant='outlined'
                  color='success'
                  className='fireworks'
                  sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', py: 3 }}
                  href={`https://wa.me/?text=${encodeURIComponent(message)}`}
                  target='_blank'
                  onClick={() => {
                    newGoogleAnalyticsEvent('share', {
                      method: 'whatsapp',
                    });
                  }}
                >
                  <WhatsAppIcon />
                  Whatsapp
                </Button>

                <Button
                  variant='outlined'
                  className='fireworks'
                  color='info'
                  sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', py: 3 }}
                  onClick={handleCopyButtonClick}
                >
                  <ContentCopyIcon />
                  {copyLinkButtonLabel}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
