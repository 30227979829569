import createAxiosInstance from '../middleware/axios.interceptors';

const helpMessageUrl = '/insurance/help-message/';

export const HelpService = {
  sendHelpMessage: async (message: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(helpMessageUrl, {
        message,
      });
    } catch (err: any) {
      console.log('Error in Help.sendHelpMessage', err);
    }
  },
};
