import { ArrowBackIos, Check, PriceCheck, Sell } from '@mui/icons-material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Paper, Stack, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLogServiceHook } from '../../../../../hooks/useLogServiceHook';
import { TravelInsuranceQuotation } from '../../../../../models/TravelInsuranceQuotation';
import { ROUTES } from '../../../../../routes/routesList';
import { QuotationCard } from '../../../../../sections/@travelInsurance/Steps/InsuranceQuotationStep/components/QuotationCard/QuotationCard';
import { formatCurrency, hexToRGBA } from '../../../../../utils/methods';

interface QuotationComponentProps {
  travelInsuranceQuotations: TravelInsuranceQuotation[];
  setCurrentStep: (step: number) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const QuotationComponent = ({
  travelInsuranceQuotations,
  setCurrentStep,
  activeTab,
  setActiveTab,
}: QuotationComponentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = new URLSearchParams(window.location.search).get('quote');
  const navigate = useNavigate();

  const { logUser } = useLogServiceHook();

  const handleRedirectToRegister = async () => {
    let activeTabNames = ['Econômico', 'Custo/Benefício', 'Completo'];
    let selectedQuotation = activeTabNames[Number(activeTab)];

    await logUser({
      url: 'cotacao-seguro-viagem/quotation_component',
      log_data: { register: true, selectedQuotation },
    });

    if (!searchParams) {
      navigate(ROUTES.public.registration);
    } else {
      navigate(`${ROUTES.public.registration}?quote=${searchParams}`);
    }
  };

  const TabLabel = ({ title, quote, index }) => {
    return (
      <Box>
        <Typography
          variant='subtitle1'
          gutterBottom
          color={{
            color: index == activeTab ? theme.palette.common.white : theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant='h6'
          style={{ color: index == activeTab ? theme.palette.common.white : theme.palette.success.main }}
        >
          {formatCurrency(quote)}
        </Typography>
      </Box>
    );
  };

  const returnTabPanelColor = (index: string) => {
    if (index == activeTab) return hexToRGBA(theme.palette.primary.main, 0.9);
    return theme.palette.common.white;
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '1.5rem' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'sticky',
            top: '1rem',
            zIndex: 999,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TabContext value={activeTab}>
            <TabList
              onChange={
                ((event, newValue) => {
                  setActiveTab(newValue);
                }) as any
              }
              aria-label='lab API tabs example'
              sx={{
                flex: 1,
                position: 'sticky',
                mt: 2,
                zIndex: 999,
                width: isMobile ? '100%' : '60%',
              }}
              centered
              TabIndicatorProps={{ style: { display: 'none' } }}
              component={Paper}
            >
              <Tab
                label={
                  <TabLabel
                    title='Básico'
                    quote={travelInsuranceQuotations?.[0].credit_card[0]?.installment_price}
                    index='0'
                  />
                }
                value='0'
                icon={
                  <Sell
                    fontSize='medium'
                    sx={{ color: '0' === activeTab ? theme.palette.common.white : theme.palette.text.primary }}
                  />
                }
                sx={{
                  flex: 1,
                  backgroundColor: returnTabPanelColor('0'),
                  borderRadius: 1,
                  transform: activeTab === '0' ? 'scale(1)' : 'scale(0.9)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />

              <Tab
                label={
                  <TabLabel
                    title={travelInsuranceQuotations?.length > 2 ? 'Recomendado' : 'Completo'}
                    quote={travelInsuranceQuotations?.[1].credit_card[0]?.installment_price}
                    index='1'
                  />
                }
                value='1'
                icon={
                  <PriceCheck
                    fontSize='medium'
                    sx={{ color: '1' === activeTab ? theme.palette.common.white : theme.palette.text.primary }}
                  />
                }
                sx={{
                  flex: 1,
                  backgroundColor: returnTabPanelColor('1'),
                  borderRadius: 1,
                  transform: activeTab === '1' ? 'scale(1)' : 'scale(0.9)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />

              {travelInsuranceQuotations?.length > 2 && (
                <Tab
                  label={
                    <TabLabel
                      title='Completo'
                      quote={travelInsuranceQuotations?.[2].credit_card[0]?.installment_price}
                      index='2'
                    />
                  }
                  value='2'
                  icon={
                    <DoneAllIcon
                      fontSize='medium'
                      sx={{ color: '2' === activeTab ? theme.palette.common.white : theme.palette.text.primary }}
                    />
                  }
                  sx={{
                    flex: 1,
                    backgroundColor: returnTabPanelColor('2'),
                    borderRadius: 1,
                    transform: activeTab === '2' ? 'scale(1)' : 'scale(0.9)',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
              )}
            </TabList>
          </TabContext>
        </Box>
        <TabContext value={activeTab}>
          {travelInsuranceQuotations?.map((item: any, index: number) => (
            <TabPanel value={String(index)} sx={{ p: 0 }}>
              <QuotationCard travelInsuranceQuotation={item} />
            </TabPanel>
          ))}
        </TabContext>
        <Stack
          columnGap={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'space-evenly' : 'center',
            pb: 2,
            px: 2,
          }}
        >
          <LoadingButton
            variant='outlined'
            color='primary'
            onClick={() => setCurrentStep(2)}
            size={isMobile ? 'medium' : 'large'}
            startIcon={<ArrowBackIos />}
            fullWidth={isMobile ? true : false}
          >
            Voltar
          </LoadingButton>
          <LoadingButton
            color='primary'
            variant='contained'
            size={isMobile ? 'medium' : 'large'}
            onClick={() => handleRedirectToRegister()}
            endIcon={<Check />}
            fullWidth={isMobile ? true : false}
          >
            <Typography>Contratar</Typography>
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
