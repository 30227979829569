import { useState } from 'react';
// @mui
import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// mocks_
import { useNavigate } from 'react-router-dom';
import { DialogComponent } from '../../../components/basics/DialogComponent/DialogComponent';
import { useUserContext } from '../../../context/UserContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/localstorageKeys';
import { ROUTES } from '../../../routes/routesList';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Perfil',
    icon: 'eva:person-fill',
    url: ROUTES.accountMenu.profile,
  },
  // {
  //   label: 'Configurações',
  //   icon: 'eva:settings-2-fill',
  //   url: ROUTES.accountMenu.settings,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState<boolean>(false);
  const [displayLogoutDialog, setDisplayLogoutDialog] = useState(false);
  const navigate = useNavigate();

  const { user, setUser } = useUserContext();

  const userProfilePicture = user?.profile_picture;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = () => {
    setDisplayLogoutDialog(true);
    setOpen(false);
  };

  const handleNavigate = (url: string) => {
    navigate(url);
    setOpen(false);
  };

  const handleLogoutConfirmation = () => {
    setDisplayLogoutDialog(false);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.user);
    setUser(null);
    navigate(ROUTES.public.login);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={userProfilePicture} alt={user?.first_name} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user?.first_name + ' ' + user?.last_name}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleNavigate(option.url)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </Popover>
      <DialogComponent
        open={displayLogoutDialog}
        title='Sair'
        description='Você está saindo do sistema. Deseja continuar?'
        confirmText='Sair'
        cancelText='Cancelar'
        onConfirm={handleLogoutConfirmation}
        onCancel={() => setDisplayLogoutDialog(false)}
      />
    </>
  );
}
