import { Check, InfoRounded } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { TravelInsuranceQuotation } from '../../../../../../models/TravelInsuranceQuotation';
import { ContainerCentered, DefaultLabel, DefaultText } from '../../../../../../theme/GlobalStyledComponents';
import { formatCurrency } from '../../../../../../utils/methods';
import { QuotationCardContainer } from './styles';

interface Props {
  travelInsuranceQuotation: TravelInsuranceQuotation;
}

export const QuotationCard = ({ travelInsuranceQuotation }: Props) => {
  const MININUM_RESULTS = 6;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [showDetails, setShowDetails] = useState(MININUM_RESULTS);

  const returnInstallments = () => {
    let lastInstallmentOption = travelInsuranceQuotation.credit_card?.[travelInsuranceQuotation.credit_card.length - 1];

    return (
      <Typography variant='subtitle1'>{`ou em ${lastInstallmentOption?.installments}x de R$ ${formatCurrency(
        String(lastInstallmentOption?.installment_price)
      )} ${lastInstallmentOption?.has_insterest ? 'com' : 'sem'}  juros`}</Typography>
    );
  };

  const handleShowMore = () => {
    const showMore = showDetails < travelInsuranceQuotation.coverages.length;

    if (showMore) {
      setShowDetails(travelInsuranceQuotation?.coverages.length);
    } else {
      setShowDetails(MININUM_RESULTS);
    }
  };

  return (
    <>
      {travelInsuranceQuotation?.product_code && (
        <>
          <QuotationCardContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <ContainerCentered sx={{ flexDirection: 'column' }}>
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <Typography variant='subtitle1' mr={2}>
                          Operado Por:{' '}
                        </Typography>
                        <img src='/assets/insurances/assist-card-logo.png' alt='logo' width='70px' />
                      </Box>
                      <Typography variant='h6'>Em 1x de </Typography>
                      <Typography variant='h5' component='p' color={theme.palette.success.main}>
                        {formatCurrency(travelInsuranceQuotation.credit_card[0].installment_price)}
                      </Typography>
                      <Typography variant='subtitle1' textAlign='center'>
                        sem juros no cartão de crédito
                      </Typography>
                      {travelInsuranceQuotation.credit_card.length > 0 && returnInstallments()}
                    </ContainerCentered>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <DefaultLabel>Saída</DefaultLabel>
                    <DefaultText>{travelInsuranceQuotation.start_date}</DefaultText>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <DefaultLabel>Retorno</DefaultLabel>
                    <DefaultText>{travelInsuranceQuotation.end_date}</DefaultText>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <DefaultLabel textAlign='center' mb={2}>
                      Abaixo as coberturas inclusas no seguro
                    </DefaultLabel>
                    <Divider />
                    <Grid container spacing={4} pt={4}>
                      {travelInsuranceQuotation?.coverages?.slice(0, showDetails).map((coverage, index) => (
                        <>
                          {coverage.content ? (
                            <>
                              {coverage.title === '**' || coverage.title === '(*) importante!' ? (
                                <Grid item xs={12} key={index}>
                                  <Box>
                                    <Box display='flex'>
                                      <InfoRounded color='warning' />
                                      <DefaultText sx={{ fontWeight: '600', ml: '10px' }}>IMPORTANTE</DefaultText>
                                    </Box>
                                    <DefaultText sx={{ marginLeft: '50px' }}>{`    - ${coverage.content}`}</DefaultText>
                                  </Box>
                                </Grid>
                              ) : (
                                <Grid item xs={12} md={6} key={index}>
                                  <Box>
                                    <Box display='flex' gap={1}>
                                      <Check color='success' />
                                      <DefaultText sx={{ fontWeight: '600' }}>{coverage.title}</DefaultText>
                                    </Box>
                                    <DefaultText sx={{ marginLeft: '50px' }}>{`    - ${coverage.content}`}</DefaultText>
                                  </Box>
                                </Grid>
                              )}
                            </>
                          ) : (
                            <Grid item xs={12} key={index}>
                              <Box>
                                <Box display='flex' gap={1}>
                                  <Typography variant='h6' color='primary' sx={{ textAlign: 'center', width: '100%' }}>
                                    {coverage.title}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </>
                      ))}
                      <Grid item sm={12} width='100%'>
                        <Button variant='text' fullWidth onClick={handleShowMore}>
                          Mostrar {showDetails < travelInsuranceQuotation?.coverages?.length ? 'mais' : 'menos'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </QuotationCardContainer>
        </>
      )}
    </>
  );
};
