import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useAppContext } from '../../context/GlobalContextProvider';
import { ApiResultAddress, NewAddress } from '../../models/Address';
import { AddressService } from '../../services/ZipcodeService.service';
import { checkStatus } from '../../utils/api/response';
import { addressSchema } from '../../utils/forms/auth/formValidationSchemas';
import { validateZipcode } from '../../utils/methods';
import { DialogComponent } from '../basics/DialogComponent/DialogComponent';
import { TextInput } from '../basics/TextInput/TextInput';

interface Props {
  addressEdition?: ApiResultAddress | undefined;
  title?: string;
  subtitle?: string;
  onSubmit: (address: NewAddress | null) => Promise<void>;
  onSave?: () => void;
  onCancel?: () => void;
}

export const AddressComponent = forwardRef(
  ({ addressEdition, title, subtitle, onSubmit, onCancel, onSave }: Props, ref) => {
    const { showGlobalSpinner, hideGlobalSpinner } = useAppContext();

    const [validZipcode, setValidZipcode] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>('');
    console.log(addressEdition?.zipcode);
    const {
      control,
      reset,
      setError,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm<NewAddress>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(addressSchema),
      defaultValues: addressEdition,
    });

    const zipcodeWatch = useWatch({
      control,
      name: 'zipcode',
    });

    const handleCreateAddress = async (data: NewAddress) => {
      let dataToSubmit: NewAddress = {
        id: addressEdition?.id,
        street: data.street,
        zipcode: data.zipcode,
        neighborhood: data.neighborhood,
        city: data?.city,
        state: data?.state,
      };
      onSubmit(dataToSubmit);
    };

    useImperativeHandle(ref, () => ({
      onSubmit: handleSubmit(handleCreateAddress),
    }));

    const fetchAddressByCep = async (zipcode: string) => {
      showGlobalSpinner({
        message: 'Aguarde um momento...',
      });

      try {
        const response = await AddressService.getAddress(zipcode);
        if (checkStatus(response)) {
          const result = response?.data.result as ApiResultAddress;
          reset({
            zipcode: result.zipcode,
            street: result.street,
            city: result.city,
            state: result.state,
            neighborhood: result.neighborhood,
          });
          setValidZipcode(true);
        }
      } catch (error: any) {
        console.log('Erro ao buscar endereço', error);
        setFormError('CEP não encontrado');
      } finally {
        hideGlobalSpinner();
      }
    };

    useEffect(() => {
      setValidZipcode(false);
      if (validateZipcode(String(zipcodeWatch))) {
        if (String(addressEdition?.zipcode) != String(zipcodeWatch)) {
          fetchAddressByCep(String(zipcodeWatch));
        }
      }
    }, [zipcodeWatch]);

    return (
      <form onSubmit={handleSubmit(handleCreateAddress)}>
        <CardContent>
          {title ||
            (subtitle && (
              <CardHeader
                title={title}
                subheader={subtitle}
                subheaderTypographyProps={{
                  variant: 'body2',
                }}
              />
            ))}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextInput
                name='zipcode'
                label='CEP'
                mask='99999-999'
                control={control}
                variant='standard'
                errorMessage={errors?.zipcode?.message}
                type='tel'
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                control={control}
                label='Estado'
                name='state'
                variant='standard'
                errorMessage={errors?.state?.message}
                disabled={!validZipcode}
                type='text'
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                control={control}
                label='Cidade'
                name='city'
                variant='standard'
                errorMessage={errors?.city?.message}
                disabled={!validZipcode}
                type='text'
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                control={control}
                label='Bairro'
                name='neighborhood'
                variant='standard'
                errorMessage={errors?.neighborhood?.message}
                disabled={!validZipcode}
                type='text'
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label='Rua'
                control={control}
                name='street'
                variant='standard'
                errorMessage={errors?.street?.message}
                disabled={!validZipcode}
                type='text'
                required
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }} gap={2}>
              <Stack direction='row' spacing={2}>
                {onCancel && (
                  <Button variant='outlined' onClick={onCancel}>
                    Cancelar
                  </Button>
                )}
                {onSave && (
                  <Button variant='contained' type='submit'>
                    Salvar
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
        <DialogComponent
          open={!!formError}
          title='CEP inválido'
          onConfirm={() => setFormError('')}
          onDismiss={() => setFormError('')}
        >
          <Alert severity='error'>O CEP informado não foi encontrado, por favor verifique e tente novamente.</Alert>
        </DialogComponent>
      </form>
    );
  }
);
