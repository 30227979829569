import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Importe o ícone de ajuda do Material-UI
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Stack } from '@mui/system';

interface Props {
  open: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  confirmIcon?: React.ReactNode;
  cancelIcon?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  onDismiss: () => void;
  children?: React.ReactNode;
}

export const HelpDialogComponent = ({
  open,
  title,
  description,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  confirmIcon = <CheckIcon />,
  cancelIcon = <CloseIcon />,
  onDismiss,
  onCancel,
  onConfirm,
  children,
}: Props) => {
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));

  return (
    <form>
      <Dialog open={open} onClose={onDismiss} fullScreen={ismobile}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
            <DialogContent>
              <Box>
                {/* Ícone de ajuda centralizado que gira */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <HelpOutlineIcon color='primary' style={{ fontSize: 48 }} />
                  <DialogTitle>{title}</DialogTitle>
                </div>
              </Box>
              {description && <Typography variant='body1'>{description}</Typography>}
              <Divider />
              <Box mt={2}>{children}</Box>
              <Stack direction='row' spacing={2} justifyContent='flex-end' mt={3}>
                {onCancel && (
                  <Button
                    startIcon={cancelIcon}
                    fullWidth={!!onConfirm || ismobile}
                    variant={onConfirm ? 'outlined' : 'contained'}
                    onClick={onCancel}
                    sx={{ textTransform: 'none' }}
                  >
                    {cancelText}
                  </Button>
                )}
                {onConfirm && (
                  <Button
                    type='submit'
                    startIcon={confirmIcon}
                    fullWidth={!!onCancel || ismobile}
                    variant='contained'
                    onClick={onConfirm}
                    sx={{ textTransform: 'none' }}
                  >
                    {confirmText}
                  </Button>
                )}
              </Stack>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
};
