import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Button, CardContent, Grid, Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { HomeInsuranceQuotation } from '../../../models/HomeInsuranceQuotation';
import { ContainerCentered, ContainerRow } from '../../../theme/GlobalStyledComponents';
import { formatCurrency } from '../../../utils/methods';
import { QuotationCardContainer } from './styles';

interface Props {
  insuranceQuotation: HomeInsuranceQuotation;
  onConfirmHiring: (insuranceQuotation: HomeInsuranceQuotation) => void;
  onPrevious: () => void;
}

export const QuotationCard = ({ insuranceQuotation, onConfirmHiring, onPrevious }: Props) => {
  const theme = useTheme();

  const MININUM_RESULTS = 3;
  const [showDetails, setShowDetails] = useState(MININUM_RESULTS);

  const handleShowMore = (length: number) => {
    const showMore = showDetails < length;

    if (showMore) {
      setShowDetails(length);
    } else {
      setShowDetails(MININUM_RESULTS);
    }
  };

  const handleConfirm = () => {
    onConfirmHiring(insuranceQuotation);
  };

  return (
    <QuotationCardContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardContent>
            <ContainerCentered sx={{ flexDirection: 'column' }}>
              <img src='/assets/insurances/tokio-marine-logo.png' alt='logo' width='200px' />
              <Typography variant='h5'>Em até 12x de </Typography>
              {insuranceQuotation.cotacao.itensSegurado.map((item) =>
                item.parcelas.map((parcela, index) => {
                  if (parcela.quantidade == 12 && parcela.tipo == 'C') {
                    return (
                      <Typography variant='h6' component='p' key={index} color={theme.palette.success.main}>
                        {formatCurrency(parcela.valorParcela)}
                      </Typography>
                    );
                  }
                })
              )}
              <Typography variant='h6' textAlign='center'>
                sem juros no cartão de crédito
              </Typography>
            </ContainerCentered>
          </CardContent>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' component='p' color={theme.palette.success.main} textAlign='center' gutterBottom>
            Abaixo as coberturas inclusas no seguro
          </Typography>
          <Grid container>
            {insuranceQuotation?.cotacao.itensSegurado?.map((coverage, index) => {
              return (
                <Grid item key={index} width='100%'>
                  {coverage.coberturas.slice(0, showDetails).map((item, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <ContainerRow gap={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                          <ContainerRow>
                            <CheckBoxIcon color='success' />
                            <Typography variant='body1' component='p' ml={2}>
                              {item.descricao}
                            </Typography>
                          </ContainerRow>
                          <Typography variant='body1' component='p'>
                            {formatCurrency(item.valorImportanciaSegurada)}
                          </Typography>
                        </ContainerRow>
                      </Grid>
                    );
                  })}
                  <Button
                    variant='text'
                    fullWidth
                    onClick={() => handleShowMore(coverage.coberturas.length)}
                    sx={{ mt: 2 }}
                  >
                    Mostrar {showDetails < coverage.coberturas.length ? 'mais' : 'menos'}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack gap={2}>
            <Button fullWidth variant='contained' size='large' onClick={handleConfirm}>
              <Typography>Contratar Seguro</Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </QuotationCardContainer>
  );
};
