import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { useTabContext } from '../context/UserTabsContext';
import { TabsWrapper } from './styles';

export const UserTabs = () => {
  const { tab, setTab } = useTabContext();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <TabsWrapper>
      <Tabs value={tab} onChange={handleChange} aria-label='lab API tabs example' centered>
        <Tab label='Perfil' value='1' icon={<AccountBoxIcon />} iconPosition='start' />
        {/* <Tab label='Endereços' value='2' icon={<HomeIcon />} iconPosition='start' /> */}
      </Tabs>
    </TabsWrapper>
  );
};
