import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput } from '../../../components/basics/TextInput/TextInput';
import { FormState } from '../../../models/FormState';
import { AuthService } from '../../../services/Auth.service';
import { checkStatus } from '../../../utils/api/response';
import { setInputErrorsFromApi } from '../../../utils/forms/form-errors';

interface Props {
  email: string | undefined;
  onClose: () => void;
}

export const RecoverPasswordModal = ({ email, onClose }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(true);
  const [formState, setFormState] = useState<FormState>();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: email || '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await AuthService.requestPasswordChange(data.email);
      if (checkStatus(response)) {
        setFormState({
          severity: 'success',
          message: response?.data.message,
        });
        setTimeout(() => {
          setOpen(false);
          onClose();
        }, 3000);
      }
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error?.data.errors);
      setFormState({
        severity: 'error',
        message: formError || 'Ocorreu um erro inesperado ao enviar o e-mail.',
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} closeAfterTransition fullScreen={isMobile}>
      <DialogTitle>
        <Typography variant='h5'>{'Vamos lhe ajudar :)'}</Typography>
        <Typography variant='body2'>Informe o e-mail da sua conta e enviaremos um link de recuperação</Typography>
      </DialogTitle>
      <DialogContent>
        {formState?.message && (
          <Alert severity={formState.severity} variant='outlined'>
            {formState.message}
          </Alert>
        )}
        <TextInput
          sx={{ mt: 2 }}
          control={control}
          name='email'
          label='E-mail'
          size='medium'
          errorMessage={errors.email?.message}
          type='email'
          required
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button fullWidth variant='outlined' size='large' onClick={handleClose}>
          Cancelar
        </Button>
        <Button type='submit' fullWidth variant='contained' size='large' onClick={handleSubmit(onSubmit)}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
