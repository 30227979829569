import dayjs from 'dayjs';

export const hexToRGBA = (hex: string, alpha: number) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
};

export const getFormatedDate = (date?: Date | string, format?: string) => {
  if (date) {
    return dayjs(date).format(format || 'DD/MM/YYYY');
  }
  return dayjs(new Date()).format(format || 'DD/MM/YYYY');
};

export const formatDateToApi = (date: Date | string) => {
  if (date) {
    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  return dayjs(new Date()).format('YYYY-MM-DD');
};

export const parseDate = (date: string) => {
  return dayjs(date, 'DD/MM/YYYY').toDate();
};

export const getCurrentDate = () => {
  // get only the date, not consider the time (set to midnight)
  return dayjs(dayjs(new Date()).format('YYYY-MM-DD')).toDate();
};

export const isDateAfter = (date1: Date, date2: Date) => {
  return dayjs(date1).isAfter(date2);
};

export const isDateEqualOrAfter = (date1: Date, date2: Date) => {
  return dayjs(date1).isSame(date2) || dayjs(date1).isAfter(date2);
};

export const formatCurrencyToNumber = (value: number | string) => {
  let numericValue = String(value).replace('R$', '').replaceAll('.', '').replace(',', '.').trim();
  return Number(numericValue);
};

export const formatCurrency = (value: number | string) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  const numericValue = parseFloat(value as string);
  if (isNaN(numericValue)) {
    return '';
  }

  return numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatCurrencyWithoutPrefix = (value: number | string) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  const numericValue = parseFloat(value as string);
  if (isNaN(numericValue)) {
    return '';
  }

  return numericValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const validateZipcode = (zipCode: string) => {
  const zipCodeRegex = /^\d{5}-\d{3}$/;

  if (zipCodeRegex.test(zipCode)) {
    return true;
  }
  return false;
};

export const validateCpf = (cpf: string) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }

  var digitoVerificador = cpf.substring(9);
  var soma = 0;

  for (var i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }

  var resto = soma % 11;
  var primeiroDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(0)) !== primeiroDigito) {
    return false;
  }

  soma = 0;

  for (var i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }

  resto = soma % 11;
  var segundoDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(1)) !== segundoDigito) {
    return false;
  }

  return true;
};

export const isValidDate = (value: string, validateIfBeforeToday: boolean = false) => {
  if (!value) return false; // Empty value is handled by "required"

  const dateParts = value.split('/');
  if (dateParts.length !== 3) return false; // Deve ter formato "dd/mm/yyyy"

  const day = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]);
  const year = parseInt(dateParts[2]);

  if (day < 1 || day > 31 || month < 1 || month > 12 || year > 3000) {
    return false; // Validação de dia, mês e ano
  }

  if (validateIfBeforeToday) {
    const currentDate = new Date();
    const inputDate = new Date(year, month - 1, day); // O mês deve ser subtraído em 1 pois os meses em JavaScript são indexados a partir de 0
    if (inputDate < currentDate) {
      return false; // Data é anterior a hoje
    }
  }

  return true; // Data válida
};

export const validatePassengerBirthday = (date: string) => {
  let cleanDate = date.replaceAll('_', '').replaceAll('/', '').replaceAll(' ', '');

  let day = parseInt(cleanDate.substring(0, 2));
  let month = parseInt(cleanDate.substring(2, 4));
  let year = parseInt(cleanDate.substring(4, 8));

  console.log(day, month, year);

  if (day < 1 || day > 31) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  if (year < 1900 || year > new Date().getFullYear()) {
    return false;
  }

  return true;
};
