import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardHeader, Grid, Select, Slider } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CreditCardTextInput } from '../../../components/basics/CreditCardTextInput/CreditCardTextInput';
import { TextInput } from '../../../components/basics/TextInput/TextInput';
import { useHomeInsuranceContext } from '../../../context/insurances/HomeInsuranceContextProvider';
import { formatCurrency, validateCpf } from '../../../utils/methods';
import { CustomCardContent } from '../Steps/styles';

interface FormFields {
  cardNumber: string;
  name: string;
  expirationDate: string;
  cvv: string;
  cpf: string;
}

export interface CreditCardData {
  cardData: FormFields;
  selectedInstallment: number | null;
  billingDate: number | null;
}

interface Props {
  ref: any;
  submitForm: (data: CreditCardData) => void;
}

const schema = yup.object().shape({
  cardNumber: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  expirationDate: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .test('valid-cpf', 'CPF inválido', (value) => validateCpf(value)),
});

export const CreditCardForm = forwardRef(({ submitForm }: Props, ref: any) => {
  const { selectedQuotation } = useHomeInsuranceContext();
  const [selectedInstallment, setSelectedInstallment] = useState<number | null>(null);
  const [billingDate, setBillingDate] = useState<number | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: FormFields) => {
    submitForm({
      cardData: data,
      selectedInstallment,
      billingDate,
    });
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ padding: 2 }}>
          <CardHeader title='Dados do Cartão' titleTypographyProps={{ textAlign: 'center' }} />
          <CustomCardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextInput
                  control={control}
                  name='name'
                  label='Nome do titular'
                  placeholder='Nome do titular'
                  required
                  errorMessage={errors.name?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  control={control}
                  name='cpf'
                  label='CPF'
                  placeholder='000.000.000-00'
                  required
                  mask='999.999.999-99'
                  type='tel'
                  errorMessage={errors.cpf?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CreditCardTextInput
                  control={control}
                  name='cardNumber'
                  label='Número do cartão'
                  placeholder='0000-0000-0000-0000'
                  required
                  mask='9999 9999 9999 9999'
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  type='tel'
                  errorMessage={errors.cardNumber?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  control={control}
                  name='expirationDate'
                  label='Data de expiração'
                  placeholder='MM/AA'
                  required
                  mask='99/99'
                  type='tel'
                  errorMessage={errors.expirationDate?.message}
                />
              </Grid>
            </Grid>
            <CardHeader title='Parcelamento' titleTypographyProps={{ textAlign: 'center' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Select
                  title='Número de parcelas'
                  fullWidth
                  native
                  inputProps={{
                    name: 'installments',
                    id: 'installments',
                    'aria-label': 'Número de parcelas',
                    'aria-required': true,
                  }}
                  // value={selectedInstallment}
                  onChange={(event) => setSelectedInstallment(Number(event.target.value))}
                >
                  {selectedQuotation?.cotacao.itensSegurado?.[0].parcelas
                    .filter((installment) => installment.tipo == 'C')
                    .map((installment, index) => (
                      <option key={index} value={installment.quantidade}>
                        {installment.quantidade}x de {formatCurrency(installment.valorParcela)}
                      </option>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CardHeader
                  title='Dia da Cobrança'
                  titleTypographyProps={{ textAlign: 'center' }}
                  subheader='Escolha o melhor dia do mês para cobrança'
                  subheaderTypographyProps={{ textAlign: 'center', variant: 'caption' }}
                />
                <Slider
                  defaultValue={15}
                  aria-label='Melhor dia do mês para cobrança'
                  valueLabelDisplay='on'
                  step={1}
                  min={1}
                  max={31}
                  sx={{ mt: 4 }}
                  onChange={(event, value) => setBillingDate(value as number)}
                />
              </Grid>
            </Grid>
          </CustomCardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
