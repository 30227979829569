import { Card } from '@mui/material';
import styled from 'styled-components';

export const ItemCard = styled(Card)`
  flex: 1;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;
