import CryptoJS from 'crypto-js';
import { H } from 'highlight.run';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../localstorage/localstorageKeys';
import { User } from '../models/User';
import { ROUTES } from '../routes/routesList';
import { AuthService } from '../services/Auth.service';
import { UserService } from '../services/User.service';
import { checkStatus } from '../utils/api/response';

export interface UserDocsFormFields {
  first_name?: string;
  last_name?: string;
  cpf: string;
  rg?: string;
  dataExpedicao?: string;
  orgaoExpedidor?: string;
}
interface IUserContext {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUserApi: (newUserInfo: User) => void;
  updateUserDocuments: (documents: UserDocsFormFields) => void;
  refreshUserLocalStorage: () => void;
  updateUserImage: (image: string) => void;
}

interface Props {
  children: ReactNode;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserContextProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [pushToken, setPushToken] = useState<string | null>(null);

  const PLATFORM = window.Platform || 'web';

  const decryptUserData = () => {
    const user = localStorage.getItem(LOCAL_STORAGE_KEYS.user);
    if (user) {
      const secretKey = process.env.REACT_APP_CRYPTO_KEY;
      const decryptedUserData = JSON.parse(CryptoJS.AES.decrypt(user, secretKey!).toString(CryptoJS.enc.Utf8));
      return {
        ...decryptedUserData,
        platform: PLATFORM,
      };
    } else {
      throw new Error('User not found');
    }
  };

  const encryptUserData = (user: User) => {
    const secretKey = process.env.REACT_APP_CRYPTO_KEY;
    const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(user), secretKey!).toString();
    return encryptedUserData;
  };

  const checkUserLoggedIn = () => {
    try {
      const userData = decryptUserData();
      console.log('User logged in', userData);
      setUser(userData);
      H.identify(userData.email, {
        id: userData.id,
      });
    } catch (error: any) {
      console.log('User not logged in');
      localStorage.clear();
      navigate(ROUTES.public.login);
    }
  };

  const refreshUserLocalStorage = async () => {
    let currentUserInformation = decryptUserData();

    if (currentUserInformation) {
      try {
        const response = await AuthService.getUserInformation();
        if (checkStatus(response)) {
          let dataToSave = { ...currentUserInformation, ...response?.data.result };
          localStorage.setItem(LOCAL_STORAGE_KEYS.user, encryptUserData(dataToSave));
          setUser(dataToSave);
        }
      } catch (error: any) {
        console.error('Error in refreshUserLocalStorage', error);
      }
    }
  };

  const updateUserApi = async (newUserInfo: User) => {
    const dataToUpdate = {
      ...user,
      ...newUserInfo,
    };

    try {
      const response = await UserService.userUpdate(dataToUpdate);
      if (checkStatus(response)) {
        refreshUserLocalStorage();
      }
    } catch (error: any) {
      console.error('Error in updateUserApi', error);
    }
  };

  const updateUserImage = async (image: string) => {
    try {
      const response = await UserService.userUpdate({ profile_picture: image });

      if (checkStatus(response)) {
        refreshUserLocalStorage();
      }
    } catch (error: any) {
      console.error('Error in updateUserImage', error);
    }
  };

  const updateUserDocuments = async (documents: any) => {
    try {
      const response = await UserService.userUpdate({
        cpf: documents.cpf,
        rg: documents.rg,
        rg_dispatch_date: documents.dispatchDate,
        rg_org: documents.rgOrg,
        birthdate: documents.birthdate,
      });

      if (checkStatus(response)) {
        refreshUserLocalStorage();
      }
    } catch (error: any) {
      console.error('Error in updateUserDocuments', error);
    }
  };

  const isPublicUrl = () => {
    return Object.values(ROUTES.public).filter((path) => matchPath(path, location.pathname)).length > 0;
  };

  useEffect(() => {
    if (!isPublicUrl()) {
      checkUserLoggedIn();
    }
  }, [navigate]);

  const handlePushNotificationToken = () => {
    function setPushNotificationToken(token: string) {
      if (token) {
        setPushToken(token);
      }
    }

    window.setPushNotificationToken = setPushNotificationToken;

    if (window.pushNotificationToken) {
      setPushNotificationToken(window.pushNotificationToken);
    }
  };

  useEffect(() => {
    const sendTokenToApi = async () => {
      try {
        if (user?.id && pushToken) {
          await UserService.userUpdate({ push_notification_token: pushToken });
        }
      } catch (error: any) {
        console.error('Error in sendTokenToApi', error);
      }
    };

    sendTokenToApi();
  }, [user, pushToken]);

  useEffect(() => {
    handlePushNotificationToken();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUser, updateUserApi, updateUserImage, updateUserDocuments, refreshUserLocalStorage }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
