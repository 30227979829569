import { Card } from '@mui/material';
import styled from 'styled-components';

export const QuotationCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  flex: 1;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  min-width: 280px;
`;

export const DefaultCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;
