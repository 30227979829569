import { Box, CardContent, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

interface IContainerProps {
  ismobile: boolean;
  width?: string;
}

export const Container = styled(Box).attrs({
  gap: 1,
})<IContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem 3.5rem 0rem;
  width: ${({ ismobile }) => (ismobile ? '100%' : '80%')};
`;

export const FormContainer = styled(Box).attrs({
  gap: 2,
})<IContainerProps>`
  display: flex;
  flex-grow: 1;
  width: ${({ width, ismobile }) => (ismobile ? '100%' : width ? width : '80%')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ ismobile }) => (ismobile ? '0rem' : '1rem')};
  margin-bottom: ${({ ismobile }) => (ismobile ? '1rem' : '1rem')};
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ActionsWrapper = styled(Stack).attrs({
  direction: 'row',
  spacing: 2,
})<IContainerProps>`
  position: ${({ ismobile }) => (ismobile ? 'fixed' : 'relative')};
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 1rem;
  min-height: 50px;
  width: ${({ ismobile }) => (ismobile ? '100%' : '80%')};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: 999;
`;

export const HeaderWrapper = styled(Stack).attrs({
  spacing: 1,
})`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const CustomCard = styled(Stack)<IContainerProps>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 60vh;
  margin: ${({ ismobile }) => ismobile && '0 1rem 0 1rem'};
`;

export const CustomCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
  gutterBottom: true,
  textAlign: 'center',
})``;

export const Subtitle = styled(Typography).attrs({
  variant: 'body2',
  gutterBottom: true,
  textAlign: 'center',
})``;
