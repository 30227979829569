import { Grid } from '@mui/material';
import { INSURANCE_TYPES } from '../../../constants/InsuranceTypes';
import { useUserContext } from '../../../context/UserContextProvider';
import { Insurance } from '../../../models/Insurance';
import { InsuranceCard } from '../InsuranceCard/InsuranceCard';

export const NewInsurancesList = () => {
  const { user } = useUserContext();

  return (
    <Grid container spacing={3}>
      {INSURANCE_TYPES.map((insurance: Insurance) => (
        <InsuranceCard key={insurance.id} insurance={insurance} disabled={!user?.client?.cpf} />
      ))}
    </Grid>
  );
};
