import createAxiosInstance from '../middleware/axios.interceptors';
import { TravelInsurance } from '../models/TravelInsurance';
import { TravelInsurancePublicQuotation } from '../models/TravelInsuranceQuotation';

const baseUrl = '/insurance/travel-insurance';
const publicQuotation = '/public/travel-insurance/quote';

export const TravelInsuranceService = {
  getTravelInsurances: async (page: number = 0) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.get(`${baseUrl}/`);
    } catch (err: any) {
      throw err.response;
    }
  },

  createTravelInsurance: async (destiny: string | null, quotationOrigin: 'web' | 'app') => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/`, { destiny, quote_origin: quotationOrigin });
    } catch (err: any) {
      throw err.response;
    }
  },

  updateTravelInsurance: async (travelInsuranceId: string, data: TravelInsurance) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(`${baseUrl}/${travelInsuranceId}`, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  addPassenger: async (travelInsuranceId: string, data: any) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/${travelInsuranceId}/passenger`, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  updatePassenger: async (travelInsuranceId: string, passengerId: string, data: any) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.patch(`${baseUrl}/${travelInsuranceId}/passenger/${passengerId}`, data);
    } catch (err: any) {
      throw err.response;
    }
  },

  deletePassenger: async (travelInsuranceId: string, passengerId: string) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.delete(`${baseUrl}/${travelInsuranceId}/passenger`, {
        data: { passenger_id: passengerId },
      });
    } catch (err: any) {
      throw err.response;
    }
  },

  getInsuranceQuote: async (travelInsuranceId: string, quoteOrigin: 'web' | 'app') => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/${travelInsuranceId}/get-quotes`);
    } catch (err: any) {
      throw err.response;
    }
  },

  getPublicInsuranceQuote: async (travelInsuranceData: TravelInsurancePublicQuotation) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(publicQuotation, travelInsuranceData);
    } catch (err: any) {
      throw err.response;
    }
  },

  hireInsurance: async (
    travelInsuranceId: string,
    numberParcels: string,
    creditCardHolerName: string,
    creditCardHolderCpf: string,
    creditCardNumber: string,
    creditCardYear: string,
    creditCardMonth: string,
    creditCardCVV: string
  ) => {
    try {
      const axiosInstance = createAxiosInstance();
      return await axiosInstance.post(`${baseUrl}/${travelInsuranceId}/hire`, {
        number_parcels: numberParcels,
        credit_card_holder_name: creditCardHolerName,
        credit_card_holder_cpf: creditCardHolderCpf,
        credit_card_number: creditCardNumber,
        credit_card_year: creditCardYear,
        credit_card_month: creditCardMonth,
        credit_card_cvv: creditCardCVV,
      });
    } catch (err: any) {
      throw err.response;
    }
  },
};
